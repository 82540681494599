// Separators
.not-separate {
  margin-bottom: 0 !important;
}

.small-separate {
  margin-bottom: $separate-base - 8px !important;
}

.half-small-separate {
  margin-bottom: $separate-base - 16px !important;
}

.separate-base {
  margin-bottom: $separate-base !important;
}

.separate-medium {
  margin-bottom: $separate-base + 16px !important;
}

.separate-double {
  margin-bottom: $separate-base * 2 !important;
}

// Grid system
.container {
  width: 100%;
  max-width: $grid-container-max-size;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;

  @media screen {
    @media (min-width: $screen-sm) {
      padding-right: ($grid-gutter-width / 2);
      padding-left: ($grid-gutter-width / 2);
    }

    @media (min-width: $screen-lg) {
      padding-right: 48px;
      padding-left: 48px;
    }
  }
}


.container-calendar {
  width: 100%;
  max-width: $grid-container-max-size;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;

  @media screen {
    @media (min-width: $screen-sm) {
      padding-right: ($grid-gutter-width / 2);
      padding-left: ($grid-gutter-width / 2);
    }

    @media (min-width: $screen-lg) {
      padding-right: 48px;
      padding-left: 48px;
    }
  }

  @media (min-width: 1401px) and (max-width: 1600px) {
    max-width: 780px;
  }

  @media (min-width: 1601px) and (max-width: 1840px) {
    max-width: 930px;
  }

  @media (min-width: 1601px) and (max-width: 1840px) {
    max-width: 780px;
  }

  @media (min-width: 1921px) and(max-width: 2100px){
    max-width: 1100px;
  }

  @media (min-width: 2101px){
    max-width: 1300px;
  }

  @media (min-width: 1261px) and  (max-width: 1361px) {
    max-width: 590px;

  }

}



.clearfix {
  &:before,
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.container-full {
  width: 100%;
  box-sizing: border-box;
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;

  @media screen {
    @media (min-width: $screen-sm) {
      padding-right: ($grid-gutter-width / 2);
      padding-left: ($grid-gutter-width / 2);
    }
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.border-radius {
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 4px 6px -2px rgba(130, 136, 148, 0.32);
  border-radius: 10px;
  margin-bottom: 24px;
}

.row {
  display: flex;
  box-sizing: border-box;
  margin-right: -$grid-gutter-width;
  margin-left: -$grid-gutter-width;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  @media screen {
    @media (min-width: $screen-sm) {
      margin-right: -($grid-gutter-width / 2);
      margin-left: -($grid-gutter-width / 2);
    }
  }

  justify-content: flex-start;
  flex-wrap: wrap;

  &.row-center {
    justify-content: center;
  }

  &.acenter {
    align-items: center;
  }

  &.row-loading {
    opacity: 0.7;
    pointer-events: none;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.no-margin {
  margin: 0;
}

.mt-1 {
  margin-top: 1rem;
}

.m-1 {
  margin: 5px;
}

.ml-1 {
  margin-left: 1rem;
}

.mb-1 {
  margin-bottom: 1rem;
}

.sm-1,
.sm-2,
.sm-3,
.sm-4,
.sm-5,
.sm-6,
.sm-7,
.sm-8,
.sm-9,
.sm-10,
.sm-11,
.sm-12,
.xs-1,
.xs-2,
.xs-3,
.xs-4,
.xs-5,
.xs-6,
.xs-7,
.xs-8,
.xs-9,
.xs-10,
.xs-11,
.xs-12,
.md-1,
.md-2,
.md-3,
.md-4,
.md-5,
.md-6,
.md-7,
.md-8,
.md-9,
.md-10,
.md-11,
.md-12,
.lg-1,
.lg-2,
.lg-3,
.lg-4,
.lg-5,
.lg-6,
.lg-7,
.lg-8,
.lg-9,
.lg-10,
.lg-11,
.lg-12 {
  box-sizing: border-box;
  max-width: 100%;
  padding-right: $grid-gutter-width;
  padding-left: $grid-gutter-width;

  @media screen {
    @media (min-width: $screen-sm) {
      padding-right: ($grid-gutter-width / 2);
      padding-left: ($grid-gutter-width / 2);
    }
  }

  flex: 0 0 100%;
}

@media screen {
  @media (max-width: ($screen-sm - 1)) {
    .visible-xs {
      display: block !important;
    }
    .xs-1 {
      max-width: 8.333333%;

      flex: 0 0 8.333333%;
    }

    .xs-2 {
      max-width: 16.666667%;

      flex: 0 0 16.666667%;
    }
    .xs-3 {
      max-width: 25%;

      flex: 0 0 25%;
    }
    .xs-4 {
      max-width: 33.333333%;

      flex: 0 0 33.333333%;
    }
    .xs-5 {
      max-width: 41.666667%;

      flex: 0 0 41.666667%;
    }
    .xs-6 {
      max-width: 50%;

      flex: 0 0 50%;
    }
    .xs-7 {
      max-width: 58.333333%;

      flex: 0 0 58.333333%;
    }
    .xs-8 {
      max-width: 66.666667%;

      flex: 0 0 66.666667%;
    }
    .xs-9 {
      max-width: 75%;

      flex: 0 0 75%;
    }
    .xs-10 {
      max-width: 83.333333%;

      flex: 0 0 83.333333%;
    }
    .xs-11 {
      max-width: 91.666667%;

      flex: 0 0 91.666667%;
    }
    .xs-12 {
      max-width: 100%;

      flex: 0 0 100%;
    }
  }
  @media (min-width: $screen-sm) {
    .visible-sm {
      display: block !important;
    }
    .sm-1 {
      max-width: 8.333333%;

      flex: 0 0 8.333333%;
    }

    .sm-2 {
      max-width: 16.666667%;

      flex: 0 0 16.666667%;
    }
    .sm-3 {
      max-width: 25%;

      flex: 0 0 25%;
    }
    .sm-4 {
      max-width: 33.333333%;

      flex: 0 0 33.333333%;
    }
    .sm-5 {
      max-width: 41.666667%;

      flex: 0 0 41.666667%;
    }
    .sm-6 {
      max-width: 50%;

      flex: 0 0 50%;
    }
    .sm-7 {
      max-width: 58.333333%;

      flex: 0 0 58.333333%;
    }
    .sm-8 {
      max-width: 66.666667%;

      flex: 0 0 66.666667%;
    }
    .sm-9 {
      max-width: 75%;

      flex: 0 0 75%;
    }
    .sm-10 {
      max-width: 83.333333%;

      flex: 0 0 83.333333%;
    }
    .sm-11 {
      max-width: 91.666667%;

      flex: 0 0 91.666667%;
    }
    .sm-12 {
      max-width: 100%;

      flex: 0 0 100%;
    }

    .sm-dyn {
      max-width: 100%;
      flex: 1 1 auto;
    }
  }
  @media (min-width: $screen-md) {
    .visible-md {
      display: block !important;
    }
    .md-1 {
      max-width: 8.333333%;

      flex: 0 0 8.333333%;
    }

    .md-2 {
      max-width: 16.666667%;

      flex: 0 0 16.666667%;
    }
    .md-3 {
      max-width: 25%;

      flex: 0 0 25%;
    }
    .md-4 {
      max-width: 33.333333%;

      flex: 0 0 33.333333%;
    }
    .md-5 {
      max-width: 41.666667%;

      flex: 0 0 41.666667%;
    }
    .md-6 {
      max-width: 50%;

      flex: 0 0 50%;
    }
    .md-7 {
      max-width: 58.333333%;

      flex: 0 0 58.333333%;
    }
    .md-8 {
      max-width: 66.666667%;

      flex: 0 0 66.666667%;
    }
    .md-9 {
      max-width: 75%;

      flex: 0 0 75%;
    }
    .md-10 {
      max-width: 82.333333%;

      flex: 0 0 83.333333%;
    }
    .md-11 {
      max-width: 91.666667%;

      flex: 0 0 91.666667%;
    }
    .md-12 {
      max-width: 100%;

      flex: 0 0 100%;
    }
  }
  @media (min-width: $screen-lg) {
    .visible-lg {
      display: block !important;
    }
    .lg-1 {
      max-width: 8.333333%;

      flex: 0 0 8.333333%;
    }

    .lg-2 {
      max-width: 16.666667%;

      flex: 0 0 16.666667%;
    }

    .lg-2-5 {
      max-width: 20%;

      flex: 0 0 20%;
    }

    .lg-3 {
      max-width: 25%;

      flex: 0 0 25%;
    }
    .lg-4 {
      max-width: 33.333333%;

      flex: 0 0 33.333333%;
    }
    .lg-5 {
      max-width: 41.666667%;

      flex: 0 0 41.666667%;
    }
    .lg-6 {
      max-width: 50%;

      flex: 0 0 50%;
    }
    .lg-7 {
      max-width: 58.333333%;

      flex: 0 0 58.333333%;
    }
    .lg-8 {
      max-width: 66.666667%;

      flex: 0 0 66.666667%;
    }
    .lg-9 {
      max-width: 75%;

      flex: 0 0 75%;
    }
    .lg-10 {
      max-width: 82.333333%;

      flex: 0 0 83.333333%;
    }
    .lg-11 {
      max-width: 91.666667%;

      flex: 0 0 91.666667%;
    }
    .lg-12 {
      max-width: 100%;

      flex: 0 0 100%;
    }
  }
}

.mb-1 {
  margin-bottom: 1rem;
}
