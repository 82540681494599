.Toastify {
  .Toastify__toast-container {
    .Toastify__toast {
      box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
        0 8px 10px -4px rgba(130, 136, 148, 0.32);
      border-radius: 12px;
      opacity: 1;
      outline: none !important;

      min-height: auto;

      padding: 10px;

      .Toastify__toast-body {
        margin: 0;

        font-size: $font-size-sm-md;
        line-height: 18px;

        font-family: $font-text;
      }

      &.Toastify__toast--error {
        background-color: $color-danger;
      }
    }

    &.Toastify__toast-container--bottom-center {
      width: auto;
    }

    @media screen {
      @media (min-width: $screen-sm) {
        .Toastify__toast {
          width: 585px;

          padding: 30px 24px;
        }

        &.Toastify__toast-container--bottom-center {
          margin-left: -292px;
        }
      }
    }
  }
}
