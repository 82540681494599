.card {
  box-sizing: border-box;

  &.card-announce-about-mgm {
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 0px 0px rgba(130, 136, 148, 0.16),
      0px 1px 2px rgba(130, 136, 148, 0.48);
    color: $color-purple;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: $font-display;
    margin-bottom: 32px;
    padding: 24px;
    position: relative;
    overflow: hidden;
    min-height: 200px;

    a {
      font-family: $font-display;
      font-size: 13px;
      font-weight: 600;
      color: #572580 !important;
      text-transform: uppercase;
    }

    h3 {
      font-size: 20px;
      font-weight: normal;
      line-height: 24px;
      max-width: 350px;
      padding-right: 46px;
      z-index: 1;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    button {
      color: inherit;
      display: block;
      font-family: inherit;
      font-size: 13px;
      font-weight: 500;
      text-transform: uppercase;
      line-height: 18px;
      margin-right: auto;
      padding: 0;
    }

    img {
      position: absolute;
      bottom: 0;
      right: 0;
      max-width: 155px;
      &.desktop {
        display: none;
      }
      &.isdesktop {
        display: none;
      }
    }

    @media screen {
      @media (min-width: 576px) {
        h3 {
          max-width: 400px;

          br {
            display: none;
          }
        }
      }

      @media (min-width: $screen-sm) {
        min-height: 205px;
        padding: 27px 32px 32px;

        h3 {
          font-size: 24px;
          line-height: 32px;
          max-width: 460px;
        }

        img {
          &.mobile {
            display: none;
          }

          &.desktop {
            display: block;
          }
        }
        &.card-annouce-desktop {
          padding: 25px;
          grid-column: 1/3;
          img {
            margin: 0;
            margin-left: auto;
            margin-right: 53px;
            max-width: 242px;
            position: relative;
            &.mobile {
              display: none;
            }
            &.desktop {
              display: none;
            }
            &.isdesktop {
              display: block;
            }
          }

          h3 {
            line-height: 28px;
            font-size: 22px;
            padding-right: 0px;
          }
        }
      }

      @media (min-width: $screen-md) {
        img {
          margin-right: 40px;
        }
      }
    }
  }

  &.card-account-labi-credits {
    border-radius: 24px;
    background-color: #ffffff;
    box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
      0px 1px 2px -1px rgba(130, 136, 148, 0.48);
    display: flex;
    align-items: center;
    padding: 24px;
    width: 100%;
    max-width: 438px;

    &.-purple-light {
      background-color: $color-purple-light;

      .img-card-account-labi-credits {
        background-color: #fff;
      }

      .content-card-account-labi-credits {
        h4 {
          color: $color-gray-text;
          font-size: 16px;
          font-family: $font-display;
          line-height: 24px;
          margin-bottom: 0;
        }
      }
    }

    .-destach-price {
      font-size: 24px !important;
      font-weight: 500 !important;
    }

    .img-card-account-labi-credits {
      border-radius: 100%;
      background-color: $color-ltgray;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 64px;
      width: 64px;
    }

    .content-card-account-labi-credits {
      margin-left: 24px;

      h4 {
        color: $color-lgray-text;
        font-size: 16px;
        font-family: $font-display;
        line-height: 24px;
        margin-bottom: 0;
      }

      span {
        color: $color-purple;
        font-size: 14px;
        font-family: $font-display;
        font-weight: 600;
      }

      .date {
        font-family: $font-display;
        font-weight: 600;
        font-size: 14px;
      }
    }

    @media screen {
      @media (min-width: $screen-sm) {
        .content-card-account-labi-credits {
          margin-left: 20px;
        }
      }
    }
  }

  &.card-cta-empty {
    background: white;
    border-radius: 24px;
    box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
      0px 8px 10px -4px rgba(130, 136, 148, 0.28);
    text-align: center;
    margin: 0 auto;
    max-width: 400px;
    padding: 32px 24px;

    h3 {
      color: $color-purple;
      font-family: $font-display;
      font-weight: normal;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    p {
      color: $color-lgray-text;
      font-family: $font-text;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      margin: 0 auto 24px;
      max-width: 380px;
    }

    button {
      margin-bottom: 24px;
      min-height: 48px;
      width: 100%;
      max-width: 280px;
    }

    img {
      width: 100%;
      max-width: 200px;
    }

    @media screen {
      @media (min-width: $screen-sm) {
        h3 {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 32px;
        }

        img {
          max-width: 280px;
        }

        button {
          margin-bottom: 32px;
        }
      }
    }
  }

  &.card-selection {
    background: #ffffff;
    box-shadow: 0px 0px 0px rgba(130, 136, 148, 0.16),
      0px 4px 6px rgba(130, 136, 148, 0.32);
    border-radius: 24px;
    overflow: hidden;
    margin-bottom: 16px;
    max-width: 484px;
    transition: box-shadow 300ms ease-in-out;

    &.focus {
      box-shadow: -2px -2px 18px rgba(130, 136, 148, 0.14),
        2px 2px 18px rgba(130, 136, 148, 0.23);
    }

    .card-header {
      background-color: rgba(171, 146, 191, 0.3);
      color: #333333;
      font-family: $font-display;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      text-align: left;
      padding: 24px 22px 18px;
    }

    .card-body {
      .patients-list {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
  }

  &.card-info-multiples-patients {
    position: relative;
    margin: 60px auto 0;
    max-width: 345px;

    .badge-card-info-multiples-patients {
      position: absolute;
      background: $color-purple;
      border-radius: 50px;
      display: flex;
      overflow: hidden;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 134px;
      height: 23px;
      right: 30px;
      top: 3px;

      .text-badge-card-info-multiples-patients {
        color: white;
        font-family: $font-display;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
      }
    }

    img {
      float: left;
      max-width: 171px;
      max-height: 116px;
      margin-right: 15px;
      margin-left: -10px;
    }

    .content {
      padding: 21px;
      background: #805ca0;
      border-radius: 24px;
      margin-top: 15px;

      p {
        color: #ffffff;
        font-weight: 300;
        font-size: 12px;
        overflow: hidden;
        line-height: 16px;
        margin: 0;
        height: 48px;
      }
    }
  }

  &.card-checkout-explanation {
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06),
      0px 0px 1px rgba(0, 0, 0, 0.04);
    border-radius: 24px;
    padding: 32px 26px 0;
    margin-bottom: 55px;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    position: relative;

    .card-step {
      background: #572580;
      border-radius: 100%;
      color: #ffffff;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $font-display;
      font-weight: 500;
      font-size: 18px;
      position: absolute;
      top: -16px;
      left: -16px;
    }

    .qr-content-container {
      display: flex;
      align-items: flex-start;

      & > * {
        width: 50%;
      }

      .qr-code-wrapper {
        background: #f5f5f5;
        border-radius: 50px;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 23px;

        img {
          height: auto;
          width: 54px;
        }
      }

      .card-content {
        flex: 1;

        h2 {
          color: $color-purple;
          font-size: 20px;
          line-height: 24px;
        }
      }
    }

    p {
      font-size: 13px;
      line-height: 21px;
      color: #999999;
      margin-bottom: 0;

      a {
        display: block;
        color: inherit;
        font-weight: bold;
        line-break: anywhere;
      }
    }

    .payment-methods {
      border-top: 1px solid #ebebeb;
      padding-top: 24px;
      margin-top: 32px;

      h4,
      .h4 {
        font-family: $font-text;
        font-weight: 600;
        font-size: 12px;
        line-height: 19px;
        color: #666666;
        text-align: left;
        margin-bottom: 8px;
      }

      p {
        font-size: 11px;
        line-height: 17px;
      }

      .card-brands-wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 24px;

        img {
          margin-right: 2px;
          margin-bottom: 2px;
          width: 30px;

          &.large {
            width: 48px;
          }
        }
      }

      .card-brands-wrapper-center {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        margin-bottom: 24px;
      }
    }
  }

  &.card-vaccine-recommendation {
    text-align: center;
    display: flex;
    margin: 64px auto 0;
    background-color: $color-ltyellow;
    max-width: 982px;
    width: 100%;
    border-radius: 24px;
    font-family: $font-display;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 29px 18px 24px;

    p {
      color: #572580;
      font-size: 16px;
      line-height: 18px;
      margin-bottom: 16px;

      strong {
        display: block;
        margin-bottom: 16px;
      }
    }

    button {
      font-size: 14px;
      padding: 7px 18px;
    }

    @media (min-width: 768px) {
      padding: 29px;
      flex-direction: row;
      text-align: left;

      p {
        margin-bottom: 0;
        flex: 1;

        strong {
          display: initial;
          margin: 0;
        }
      }
    }
  }

  &.card-profile {
    cursor: pointer;
    transition: all 300ms ease-in-out;

    p {
      display: none;
      margin: 0;
      margin-left: 15px;
      font-family: $font-display;
      font-size: 18px;
      line-height: 23px;
      color: #ffffff;

      strong {
        font-weight: 600;
      }
    }

    &:hover {
      &:not(.selected) {
        transform: scale(1.03);

        .card-image-wrapper {
          opacity: 0.75;
        }
      }
    }

    &.selected {
      .card-image-wrapper {
        border: 2px solid #d8d8d8;
        opacity: 1;
      }
    }

    &.-expanded {
      margin-bottom: 15px;
      display: flex;
      align-items: center;

      p {
        display: block;
      }

      .card-image-wrapper {
        width: 48px;
        height: 48px;
      }
    }

    .card-image-wrapper {
      background-color: $color-ltyellow;
      border: 2px solid $color-purple;
      width: 35px;
      height: 35px;
      border-radius: 100%;
      overflow: hidden;
      display: flex;
      align-items: flex-end;
      opacity: 0.6;
      transition: opacity 0.3s ease-in-out;

      span {
        display: none;
      }

      p {
        display: none !important;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        margin-bottom: -6px;
      }
    }
  }

  &.card-better-options {
    background: #ffffff;
    border: 1px solid #d8d8d8;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(130, 136, 148, 0.16);
    border-radius: 14px;
    margin-left: 20px;
    padding: 24px 32px;
    display: flex;
    align-items: center;
    max-width: 375px;

    .step-number {
      background-color: $color-purple;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-display;
      font-size: 22px;
      line-height: 32px;
      color: #ffffff;
      width: 42px;
      height: 42px;
      border-radius: 100%;
      margin-left: -52px;
    }

    .card-content {
      margin-left: 10px;
      flex: 1;

      h4,
      .h4 {
        font-family: $font-display;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
        color: $color-purple;
        margin-bottom: 8px;
      }

      p {
        font-family: $font-text;
        font-size: 14px;
        line-height: 22px;
        color: #666666;
        margin: 0;
        padding: 0;
      }
    }
  }

  &.container-card-partner-medley {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(
      0deg,
      rgba(87, 37, 128, 1) 0%,
      rgba(46, 172, 174, 1) 100%
    );
    border-radius: 25px;
    padding: 14px;
    margin-top: 10px;

    .card-partner-medle-text {
      color: white;
      font-family: $font-display;
      font-weight: 400;
      font-size: 24px;
      line-height: 30px;
      margin-top: 10px;
      padding: 10px;

      .text-yellow {
        color: #f8ed55;
        font-weight: 700;
      }
    }

    .img-banner-medley {
      width: 241px;
    }
  }

  @media screen and (min-width: $screen-sm) {
    &.container-card-partner-medley {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 246px !important;
      margin-top: 20px;
      justify-content: center;

      .img-banner-medley {
        width: 310px;
      }

      .img-logo-medley {
        margin-top: 130px;
        width: 136px;
        position: absolute;
        right: 0;
      }
    }
  }

  @media screen and (min-width: 850px) {
    &.container-card-partner-medley {
      .img-banner-medley {
        margin-left: 30px;
      }
    }
  }

  @media screen and (min-width: $screen-md) {
    &.container-card-partner-medley {
      .card-partner-medle-text {
        margin-left: 40px;
        width: 328px;
        white-space: nowrap;
      }

      .img-logo-medley {
        margin-left: 70px;
        margin-top: 90px;
        right: -10px;
        width: 205px;
      }
    }
  }

  &.card-security-comfort {
    border-radius: 24px;
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 8px 10px -4px rgba(130, 136, 148, 0.28);
    margin: 7.5px;
    padding: 32px 24px 40px;
    width: 100%;
    max-width: 242px;

    img {
      height: auto;
      margin-bottom: 22.99px;
      width: 64px;
    }

    h3 {
      color: $color-purple;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0;
      max-width: 173px;
    }
  }

  &.unity-card-hero {
    position: relative;
    border-radius: 16px;
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 4px 6px -2px rgba(130, 136, 148, 0.32);
    margin-top: 24px;
    padding: 24px 15px !important;
    text-align: left;
    border-left: 12px solid $color-pink-light;
    max-width: 784px;
    width: 100%;
    box-sizing: border-box;
    transition: transform 300ms ease-in-out;

    &.-hover-zoom {
      &:not(.-unavailable) {
        &:hover {
          // transform: scale(1.03);
        }
      }
    }

    &.-new-card {
      position: relative;

      &::before {
        box-sizing: border-box;
        border: 1px solid $color-purple;
        border-radius: 22.5px;
        background-color: $color-switch-yellow;
        color: $color-purple;
        content: attr(data-content);
        display: block;
        text-transform: uppercase;
        font-family: $font-display;
        font-size: 10px;
        font-weight: 600;
        position: absolute;
        top: -13px;
        right: 15px;
        padding: 4.5px 15.86px;
      }
    }

    figure {
      display: flex;
      align-items: center;
      margin: 0;

      img {
        height: auto;
        width: 80px;
      }

      figcaption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 34px;

        h3 {
          color: $color-purple;
          font-size: 24px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 20px;
          margin-bottom: 8px;
        }

        h4,
        .unity-card-caption {
          color: $color-black;
          font-family: $font-display;
          font-size: 14px;
          letter-spacing: 0;
          line-height: 18px;
          margin-bottom: 0;
        }
      }
    }

    .label-tooltip {
      background-color: #faf38d;
      border: 1px solid #572580;
      border-radius: 22.5px;
      box-sizing: border-box;
      padding: 4.5px 10px;
      color: #572580;
      font-family: $font-display;
      font-size: 8px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 8px;
      text-align: center;
      position: absolute;
      bottom: -11px;
      left: 15px;

      & > .tooltip-wrapper {
        display: inline-block;

        i {
          font-size: 10px;
        }
      }
    }

    &.-unavailable {
      border-color: #ebebeb;
      position: relative;
      cursor: not-allowed;

      &::before {
        background-color: #ab92bf;
        border-radius: 17px;
        content: "Em breve";
        display: block;
        text-transform: uppercase;
        color: #ffffff;
        font-family: $font-display;
        font-size: 10px;
        text-align: center;
        position: absolute;
        top: -10px;
        right: 25px;
        padding: 5px 10px;
      }

      .card-title {
        opacity: 0.5;
        color: #666666;
        min-height: 44px;
      }

      .card-infos {
        .-hidden-action {
          opacity: 0;
          pointer-events: none;
        }

        .infos-price {
          color: #666666;
          opacity: 0.5;
        }

        .infos-discount {
          opacity: 0.5;
        }
      }
    }

    @media screen and (min-width: 1032px) {
      &:not(:first-child) {
        margin-left: 7.5px;

        @media screen and (min-width: 1032px) {
          .card-title {
            max-width: 276px;
          }
        }
      }

      &:not(:last-child) {
        margin-right: 7.5px;
      }
    }

    .card-title {
      color: $color-purple;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: -0.5px;
      line-height: 22px;
      max-width: 215px;
    }

    .card-infos {
      margin: 15px 0 0 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .infos-price {
        color: $color-purple;
        font-family: $font-display;
        font-size: 18px;
        font-weight: 500;
      }

      .infos-discount {
        color: #999999;
        font-family: $font-display;
        font-size: 12px;

        strong {
          font-weight: 600;
        }
      }

      .infos-action {
        height: 45px;
        width: 45px;
        border-radius: 30px;
        background-color: $color-purple;
        transition: opacity 200ms ease-in-out;
        padding: 0;

        &:hover {
          opacity: 0.8;
        }

        & > a {
          display: flex;
          height: 100%;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }

  &.card-date {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 4px 6px -2px rgba(130, 136, 148, 0.32);
    margin: 0 auto;
    padding: 29px 26px 28px;
    position: relative;
    overflow: hidden;
    text-align: center;
    width: 100%;
    max-width: 149px;
    height: 64px;

    &.card-date-confirm {
      max-width: 180px !important;
    }

    &::before {
      background-color: $color-pink-light;
      content: "";
      display: block;
      height: 14px;
      font-size: 10px;
      font-family: $font-display;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 0.05em;
      color: #fff;
    }

    .day {
      color: $color-lgray-text;
      font-family: $font-text;
      font-size: 12px;
      text-transform: uppercase;
      line-height: 12px;
      margin-bottom: 5px;
    }

    .date {
      color: $color-purple;
      font-family: $font-text;
      font-size: 16px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin-bottom: 0;
      // text-align: center;
    }

    .information {
      text-align: center;
      font-size: 11px;
      line-height: 16px;
      margin: 3px 10px 0 10px;
      color: $color-lgray-text;
    }

    &.canceled {
      background-color: #fff;
      box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
        0 4px 6px -2px rgba(130, 136, 148, 0.32);

      &::before {
        background-color: $color-gray-line;
        content: "Cancelado";
        color: #777;
      }

      .day {
        color: $color-gray-line;
      }

      .date {
        color: $color-gray-line;
      }

      .information {
        font-size: 12px;
        color: $color-lgray-text;
      }
    }
  }

  &.card-dates {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 4px 6px -2px rgba(130, 136, 148, 0.32);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 64px;
    margin: 6.5px;
    overflow: hidden;
    width: 149px;


    @media (max-width: 1260px) { //Ok
      width: 130px;
    }

    @media (min-width: 1261px) and (max-width: 1400px) { // OK
      width: 149px;
    }

    @media (min-width: 1921px) and (max-width: 2400px) { // OK
      width: 170px;
    }

    @media (min-width: 1601px) and (max-width: 1920px) { //Ok
      width: 140px;
    }

    @media (min-width: 1401px) and (max-width: 1600px) {
      width: 120px;
    }


    @media (min-width: 2101px)  { //Ok
      width: 200px;
    }
    

    &._selected {
      background-color: $color-ltgray;
      pointer-events: none;
      position: relative;

      &::before {
        background-color: $color-pink-light;
        content: "";
        display: block;
        height: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .day {
        color: $color-pink-light;
      }

      .date {
        color: $color-purple;
        font-weight: 600;
      }
      .information {
        font-size: 12px;
        color: $color-lgray-text;
      }
    }

    .day,
    .date {
      &::selection {
        background-color: transparent;
        color: inherit;
      }
    }

    .day {
      font-size: 12px;
      color: $color-lgray-text;
      text-transform: uppercase;
    }

    .date {
      font-size: 14px;
      color: $color-gray-text;
      text-align: center;
    }

    .information {
      text-align: center;
      font-size: 11px;
      line-height: 16px;
      margin: 3px 10px 0 10px;
      color: $color-lgray-text;
    }
  }


  
  &.card-dates-sm {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 4px 6px -2px rgba(130, 136, 148, 0.32);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 64px;
    margin: 6.5px;
    overflow: hidden;
    width: 100%;
    max-width: 100px;

    &._selected {
      background-color: $color-ltgray;
      pointer-events: none;
      position: relative;

      &::before {
        background-color: $color-pink-light;
        content: "";
        display: block;
        height: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .day {
        color: $color-pink-light;
      }

      .date {
        color: $color-purple;
        font-weight: 600;
      }
      .information {
        font-size: 12px;
        color: #999999;
      }
    }

    .day,
    .date {
      &::selection {
        background-color: transparent;
        color: inherit;
      }
    }

    .day {
      font-size: 12px;
      color: #999999;
      text-transform: uppercase;
    }

    .date {
      font-size: 14px;
      color: $color-bold-gray;
      text-align: center;
    }

    .information {
      text-align: center;
      font-size: 11px;
      line-height: 16px;
      margin: 3px 10px 0 10px;
      color: #999999;
    }
  }


  &.card-hours {
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 4px 6px -2px rgba(130, 136, 148, 0.32);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 64px;
    margin: 6.5px;
    overflow: hidden;
   
    width: 310px;

   
    @media (max-width: 1260px) { 
      width: 150px;
    }

    @media (min-width: 1261px) and (max-width: 1400px) { 
      width: 200px;
    }

    @media (min-width: 1921px) and (max-width: 2400px) { 
      width: 170px;
    }

    @media (min-width: 1401px) and (max-width: 1920px) { 
      width: 300px;
    }
    
    @media (min-width: 1921px){ //Ok
      width: 360px;
    }

    @media (min-width: 1261px) and  (max-width: 1361px) {
      width: 149px;
    }

    

    &._selected {
      background-color: $color-ltgray;
      pointer-events: none;
      position: relative;

      &::before {
        background-color: $color-pink-light;
        content: "";
        display: block;
        height: 8px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }

      .day {
        color: $color-pink-light;
      }

      .date {
        color: $color-purple;
        font-weight: 600;
      }
      .information {
        font-size: 12px;
        color: $color-lgray-text;
      }
    }

    .day,
    .date {
      &::selection {
        background-color: transparent;
        color: inherit;
      }
    }

    .day {
      font-size: 12px;
      color: $color-lgray-text;
      text-transform: uppercase;
    }

    .date {
      font-size: 14px;
      color: $color-gray-text;
      text-align: center;
    }

    .information {
      text-align: center;
      font-size: 11px;
      line-height: 16px;
      margin: 3px 10px 0 10px;
      color: $color-lgray-text;
    }
  }


  &.card-types-collect {
    display: flex;
    align-items: center;
    z-index: 9999;

    &:not(:first-child) {
      margin-top: 24px;
    }

    .step-number {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      color: #fff;
      min-height: 56px;
      min-width: 56px;
      background-color: $color-purple;
      font-family: $font-display;
      font-size: 22.4px;
      font-weight: 500;
      letter-spacing: 0;
      line-height: 28px;
      z-index: 99;
    }

    .card-content {
      border-radius: 16px;
      background-color: #ffffff;
      box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
        0 4px 6px -2px rgba(130, 136, 148, 0.32);
      width: 100%;
      max-width: 284px;
      padding: 24px 24px 24px 52px;
      box-sizing: border-box;
      margin-left: -26.5px;

      h4,
      .h4 {
        color: $color-purple;
        font-family: $font-display;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 20px;
        margin-bottom: 5px;
      }

      span {
        color: $color-gray-text;
        font-family: $font-display;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        margin: 0 0 23.74px;
      }

      .button-action {
        color: $color-purple;
        font-family: $font-display;
        font-size: 12px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 18px;
        text-transform: uppercase;
      }
    }
  }

  &.card-categories-vaccines {
    background: $color-ltyellow;
    width: 155px;
    height: 160px;
    border-radius: 15px;
    font-family: $font-display;
    font-size: 16px;
    color: #572580;
    overflow: hidden;
    margin: 8px;
    box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
      0px 8px 10px -4px rgba(130, 136, 148, 0.28);
    transition: all 300ms ease;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 3px 2px 5px rgba(130, 136, 148, 0.16),
        0px 6px 8px -2px rgba(130, 136, 148, 0.28);
      transform: scale(1.03);
    }

    dl {
      margin: 0;
      padding: 0;

      dt {
        font-size: 14px;
        padding: 15px;

        strong {
          display: block;
          font-size: 16px;
          font-weight: 600;
        }
      }

      dd {
        margin: 0 auto;
        padding: 0;

        img {
          &.inverse-image {
            transform: scaleX(-1);
          }
        }
      }
    }

    &:nth-child(1) {
      img {
        width: 150px;
      }
    }
    &:nth-child(2) {
      img {
        width: 200px;
      }
    }
    &:nth-child(3) {
      img {
        width: 450px;
        margin-left: -214px;
        margin-top: -15px;
      }
    }
    &:nth-child(4) {
      img {
        width: 450px;
        margin-left: -110px;
        margin-top: -21px;
      }
    }
    &:nth-child(5) {
      img {
        width: 142px;
        margin-left: 28px;
        margin-top: -20px;
      }
    }
    &:nth-child(6) {
      img {
        width: 200px;
        margin-left: -15px;
        margin-top: -12px;
      }
    }
    &:nth-child(7) {
      img {
        width: 510px;
        margin-left: -94px;
        margin-top: -20px;
      }
    }
    &:nth-child(8) {
      img {
        width: 360px;
        margin-left: -115px;
        margin-top: -10px;
      }
    }
  }

  &.card-partner-univers {
    background-color: #ffffff;
    border-radius: 24px;
    box-shadow: 0px 0px 0px rgba(130, 136, 148, 0.16),
      0px 1px 2px rgba(130, 136, 148, 0.48);
    color: $color-purple;
    font-family: $font-display;
    margin-bottom: 32px;
    padding: 24px;
    position: relative;
    overflow: hidden;
    min-height: 200px;
    display: flex;
    flex-direction: column;

    h3 {
      line-height: 24px;
      font-size: 20px;
      margin-bottom: 30px;

      @media (min-width: $screen-sm) {
        line-height: 32px;
        font-size: 22px;
        margin-bottom: 10px;
      }
    }

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      span {
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 500;
        line-height: 10px;
        color: #b1b1b1;
        border: 1px solid #b1b1b1;
        border-radius: 33px;
        padding: 4px 16px 3px 16px;
        margin-bottom: 8px;
      }

      img {
        height: 20px;
        margin-right: 16px;
      }

      p {
        margin: 16px 0;
        font-size: 14px;
        line-height: 22px;
        max-width: 300px;
      }
    }

    .card-image {
      position: absolute;
      bottom: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;

      img {
        width: 60%;
        max-width: 248px;
      }
    }

    .card-action {
      margin-top: 51px;
      display: flex;
      align-items: flex-end;
      flex: 1;
      position: relative;
      z-index: 2;

      button,
      a {
        font-family: $font-display;
        font-size: 13px;
        font-weight: 400;
        text-transform: uppercase;
        color: #572580;
      }
    }
  }

  @media screen {
    @media (min-width: 516px) {
      &.card-partner-univers {
        .card-content {
          align-items: flex-start;
          text-align: left;
        }

        .card-image {
          img {
            width: 100%;
          }
        }
      }
    }

    @media (min-width: $screen-xs) {
      &.card-profile {
        .card-image-wrapper {
          width: 50px;
          height: 50px;
        }
      }

      &.card-categories-vaccines {
        border-radius: 24px;
        height: 208px;
        width: 40%;
        max-width: 234px;

        dl {
          dt {
            font-size: 16px;
            padding: 24px 24px 0;

            strong {
              font-size: 20px;
            }
          }

          dd {
            text-align: right;

            img {
              width: auto;
              max-width: none;

              &.inverse-image {
                transform: scaleX(1);
              }
            }
          }
        }

        &:nth-child(1) {
          img {
            width: 200px;
            margin-right: 35px;
          }
        }
        &:nth-child(2) {
          img {
            width: 245px;
            margin-left: 45px;
            margin-top: -10px;
          }
        }
        &:nth-child(3) {
          img {
            width: 470px;
            margin-left: -200px;
            margin-top: -15px;
          }
        }
        &:nth-child(4) {
          img {
            width: 520px;
            margin-left: -200px;
            margin-top: 0;
          }
        }
        &:nth-child(5) {
          img {
            width: 172px;
            margin-left: -190px;
            margin-top: -21px;
          }
        }
        &:nth-child(6) {
          img {
            width: 200px;
            margin-left: -15px;
            margin-top: 0;
          }
        }
        &:nth-child(7) {
          img {
            width: 510px;
            margin-left: -250px;
            margin-top: 0;
            transform: scaleX(-1);
          }
        }
        &:nth-child(8) {
          img {
            width: 360px;
            margin-left: -100px;
            margin-top: 0;
          }
        }
      }
    }

    // @media (min-width: 525px) {
    //   &.card-dates {
    //     max-width: 126.5px;
    //   }
    // }

    @media (min-width: $screen-m) {
      &.card-profile {
        .card-image-wrapper {
          width: 60px;
          height: 60px;
        }
      }

      &.card-date {
        margin: 0;
        max-width: none;
        padding: 29px 0px 24px;
        width: 140px !important;
      }

      &.card-categories-vaccines {
        height: 208px;
        width: 40%;
        max-width: 234px;
        border-radius: 24px;

        dl {
          dt {
            font-size: 16px;
            padding: 24px 24px 0;

            strong {
              font-size: 20px;
            }
          }

          dd {
            text-align: right;

            img {
              width: auto;
              max-width: none;

              &.inverse-image {
                transform: scaleX(1);
              }
            }
          }
        }

        &:nth-child(2) {
          img {
            width: 245px;
            margin-left: 75px;
            margin-top: -10px;
          }
        }
        &:nth-child(3) {
          img {
            width: 470px;
            margin-left: -170px;
            margin-top: -15px;
          }
        }
        &:nth-child(4) {
          img {
            width: 520px;
            margin-left: -155px;
            margin-top: -50px;
          }
        }
        &:nth-child(5) {
          img {
            width: 172px;
            margin-left: -190px;
            margin-top: -21px;
          }
        }
        &:nth-child(6) {
          img {
            width: 200px;
            margin-left: -15px;
            margin-top: 0;
          }
        }
        &:nth-child(7) {
          img {
            width: 510px;
            margin-left: -190px;
            margin-top: -10px;
            transform: scaleX(-1);
          }
        }
        &:nth-child(8) {
          img {
            width: 360px;
            margin-left: -20px;
            margin-top: 0;
          }
        }
      }
    }

    @media (min-width: 700px) {
      &.card-profile {
        .card-image-wrapper {
          width: 75px;
          height: 75px;
        }
      }
    }

    @media (min-width: $screen-sm) {
      &.card-info-multiples-patients {
        margin-left: 0;
      }

      &.card-checkout-explanation {
        margin-left: 0;
        margin-right: 0;
      }

      &.unity-card-hero {
        margin: 0;

        &:not(:last-child) {
          margin-right: 15px;
        }

        figure {
          align-items: flex-start;

          figcaption {
            margin-left: 15px;

            h3 {
              font-size: 15px;
            }

            h4,
            .unity-card-caption {
              font-size: 13px;
            }
          }
        }
      }

      &.card-partner-univers {
        .card-image {
          img {
            width: 70%;
          }
        }
      }
    }

    @media (min-width: 992px) {
      &.card-profile {
        .card-image-wrapper {
          border-radius: 15px;
          flex-direction: column;
          align-items: center;
          width: 110px;
          height: 130px;

          span {
            font-size: 14px;
            text-align: left;
            line-height: 18px;
            color: $color-purple;
            font-family: $font-display;
            margin: 15px 0 0;
            width: 100%;
            max-width: 100px;
            display: block;

            strong {
              display: block;
              font-weight: 600;
            }
          }
        }
      }
    }

    @media (min-width: $screen-md) {
      // &.card-dates {
      //   max-width: 26.23%;
      // }

      &.card-security-comfort {
        max-width: 232px;
      }

      &.unity-card-hero {
        figure {
          figcaption {
            h3 {
              font-size: 24px;
            }

            h4,
            .unity-card-caption {
              font-size: 14px;
            }
          }
        }
      }
    }

    @media (min-width: 1094px) {
      &.card-profile {
        .card-image-wrapper {
          border-width: 3px !important;
          width: 125px;
          height: 140px;

          span {
            font-size: 16px;
            max-width: 110px;
          }
        }

        &.selected {
          .card-image-wrapper {
            border-color: #fff !important;
          }
        }
      }
    }

    @media (min-width: 1185px) {
      // &.card-dates {
      //   height: 72px;
      //   margin: 7.5px;
      //   max-width: 150px;
      //   width: 150px;
      // }

      &.card-partner-univers {
        .card-image {
          img {
            width: 100%;
          }
        }
      }
    }

    @media (min-width: 1194px) {
      &.card-profile {
        .card-image-wrapper {
          width: 130px;
          height: 160px;
        }
      }
    }

    @media (min-width: $screen-xlg) {
      &.unity-card-hero {
        margin: 0 0 24px 0 !important;

        figure {
          figcaption {
            margin-left: 30px;
          }
        }
      }
    }
  }
}
