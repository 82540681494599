.logo {
	background-image: url("~Assets/Images/colabi.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
    width: 129px;
    height: 28px;
	font-size: 0;
	display: block;

	a {
		display: inline-block;
		width: 100%;
		height: 100%;
	}

	&._yellow {
		background-image: url("~Assets/Images/colabi.png");
		@media screen and (max-width: 1260px) {
			margin: 0 auto;
		}
	}
}

.mobile-logo {
	background-image: url("~Assets/Images/Logo-Colabi-mobile.png");
	background-position: center;
	background-repeat: no-repeat;
    height: 23px;
	font-size: 0;
	display: block;

	margin: 5px;

	a {
		display: inline-block;
		width: 100%;
		height: 100%;
	}
}
