.input {
  box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
    0 1px 2px -1px rgba(130, 136, 148, 0.48);
  border-radius: 8px;
  background-color: #fff;
  display: block;
  padding: 10px 12px;
  font-size: $font-size-body-md;
  line-height: 22px;
  color: $color-gray-text;
  text-align: left;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    border: 1px solid rgba(187, 225, 250, 1) !important;
  }

  &::placeholder {
    color: rgba(153, 153, 153, 0.5);
  }

  &.textarea {
    height: 200px;
    resize: none;
  }

  &._lgray {
    color: $color-lgray-text;

    &::placeholder {
      color: $color-lgray-text;
    }
  }

  &._transparent {
    background-color: transparent;
  }

  &._invalid {
    border-color: #c23f3b;
  }

  &._checkbox {
    appearance: checkbox;
    box-shadow: none;
  }

  &.-hidden-checkbox {
    opacity: 0;
  }

  &.select {
    padding-right: 22px;
    &:disabled {
      cursor: not-allowed;
    }
  }

  &._border-purple {
    border: 1px solid $color-purple;
  }

  &._circle {
    border-radius: 30px;
  }

  &._sm {
    padding: 5px 145px 7px 20px;

    line-height: 18px;

    font-size: 13px;
  }

  &._number {
    padding: 4px 7px;

    text-align: center;

    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &._pagination {
    padding: 7px;
    min-width: 40px;
    text-align: center;
    line-height: 22px;
    background-color: white;
    border-color: $color-lgray-text;
    color: $color-lgray-text;
    font-size: 14px;

    &:hover {
      border-color: $color-purple;
      color: $color-purple;
    }
  }
}

.suggestion-wrapper {
  position: relative;

  margin-bottom: 20px;

  .suggestions-list {
    background-color: #eee;
    max-height: 200px;
    position: absolute;
    overflow: auto;
    width: 100%;
    z-index: 999;

    .suggestions {
      list-style: none;
      margin: 0;
      padding: 0;

      .suggestion {
        cursor: pointer;
        padding: 12px 10px;
        transition: all 0.3s ease-in-out;
        font-size: 10px;

        &:hover {
          background-color: #ddd;
        }
      }
    }

    .no-suggestions {
      > * {
        display: block;
        padding: 15px;
        text-align: center;
      }
    }
  }
}
