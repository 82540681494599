.table-list-multiple {
    padding-bottom: 24px;

    .table-list {
        margin-bottom: 24px;
        border-collapse: collapse;
        width: 100%;

        th {
            color: $color-purple;
            border-bottom: 1px solid $color-purple;
            font-size: 14px;
            text-transform: uppercase;
            padding: 8px 4px;
            text-align: left;
            font-weight: 500;
            font-family: $font-display;
        }

        td {
            padding: 8px 4px;
            font-size: 14px;
            color: #333;

            &.remove-collumn {
                color: $color-danger;
                font-size: 12px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                height: 33px;
                box-sizing: border-box;

                i {
                    display: block;

                    &.icon-close {
                        margin-left: 8px;
                    }
                }
            }
        }
    }

    .table-list-form-add {
        background-color: #F5F5F5;
        border-radius: 4px;
        padding: 7px 8px 16px;
        margin-bottom: 24px;

        .button-holder {
            text-align: right;
            margin: 16px 0 0;

            .link {
                margin-right: 20px;
            }
        }

        @media screen {
            @media (min-width: $screen-md) {
                .field-holder {
                    margin-bottom: 16px;
                }
            }
        }
    }
}