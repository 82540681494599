.field-holder {
    &.field-holder-button {
        .field-content {
            display: flex;

            > .input {
                border-radius: 8px 0 0 8px;

                border: 1px solid $color-purple;
                border-right: 0;

                box-shadow: none;

                &::placeholder {
                    color: $color-gray-text;
                }
            }

            > .button {
                border-radius: 0 8px 8px 0;

                flex: 0 0 50px;
                
                > i {
                    font-size: 22px;
                }
            }
        }
    }

    input[readonly], 
    textarea[readonly] {
      background-color: #F2F2F2;
    }
}