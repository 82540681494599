// Grid settings
$grid-gutter-width: 16px;
$grid-columns: 12;

$screen-xs: 480px;
$screen-m: 576px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1280px;
$screen-xlg: 1440px;
$screen-sm: 768px;
$screen-md: 1024px;
$screen-lg: 1280px;
$screen-ultra-lg: 1500px;

$grid-container-max-size: 1280px;

// Colors settings
// $color-lgray-text: #999999;
$color-yellow: #f6e71c;
$color-purple: #572580;
$color-purple_opacity: rgba(87, 37, 128, 0.8);
$color-ltgray: #f5f5f5;
$color-gray: #b1b1b1; // Extracted from layout
$color-black: #333333;
$color-green-money: #6bc895;
$color-success: #3ab572;
$color-orange: #f0ac4a;
$color-danger: #c23f3b;
$color-exit-money: #f0ac4a;
$color-use-money: #f4c177;
$color-buy-credits: #572580;
$color-lgray-text: #999;
$color-gray-text: #666;
$color-hgray-text: #333;
$color-semi-gray: #cccccc;
$color-border-schedule: #666666;
$color-bold-gray: #666666;
$color-purple-light: #e8d8f5;
$color-purple-cart: #E4D9E8;
$color-dark-purple: #340c54;
$color-ltgray: #f5f5f5;
$color-gold: #ebbc00;
$color-ltyellow: #f8ed55;
$color-pink-light: #ab92bf;
$color-switch-yellow: #faf38d;
$color-gray-line: #cccccc;
$color-pink: #815ca0;

$font-display: "Saira", sans-serif;
$font-text: "Open Sans", sans-serif;
$font-weight-heading: 400;

$font-color-body: $color-black;

$font-size-h1: 48px;
$font-size-h2: 36px;
$font-size-h3: 32px;
$font-size-h4: 28px;
$font-size-h5: 24px;
$font-size-lead: 20px;
$font-size-body: 16px;
$font-size-sm: 15px;
$font-size-xs: 12px;

$font-size-h1-md: 48px;
$font-size-h2-md: 36px;
$font-size-h3-md: 32px;
$font-size-h4-md: 28px;
$font-size-h5-md: 24px;
$font-size-lead-md: 20px;
$font-size-body-md: 14px;
$font-size-sm-md: 13px;
$font-size-xs-md: 10px;

$line-height-h1: 56px;
$line-height-h2: 44px;
$line-height-h3: 40px;
$line-height-h4: 36px;
$line-height-h5: 32px;

// Separators
$separate-base: 32px;
$separate-title-from-content: $separate-base * 2;
$separate-text-from-content: $separate-base + 16px;

// Icons
$icomoon-font-family: "labiicons" !default;
$icomoon-font-path: "Fonts" !default;
$icon-keyboard_arrow_left: "\e901";
$icon-profile-male: "\e900";
$icon-credit-card: "\e901";
$icon-money: "\e902";
$icon-microscope: "\e903";
$icon-edit: "\e904";
$icon-trash: "\e905";
$icon-faq: "\e906";
$icon-linkedin-fulll: "\e907";
$icon-loupe: "\e908";
$icon-map-pin: "\e909";
$icon-cart: "\e90a";
$icon-view: "\e90b";
$icon-help: "\e90c";
$icon-results: "\e90d";
$icon-account: "\e90e";
$icon-call-doctor: "\e90f";
$icon-discount: "\e910";
$icon-clock: "\e911";
$icon-sangue: "\e912";
$icon-tube: "\e912";
$icon-angle-double-right: "\e913";
$icon-angle-double-left: "\e914";
$icon-bank-slip: "\e915";
$icon-close: "\e916";
$icon-heart: "\e917";
$icon-tests: "\e918";
$icon-checked: "\ea52";
$icon-unchecked: "\ea53";
$icon-fezes: "\e91f";
$icon-urina: "\e920";
$icon-unit: "\e921";
$icon-truck: "\e922";

// Control z-index
$z-index-sidebar: 100;
$z-index-topbar: $z-index-sidebar + 1;
