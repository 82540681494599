.app-auth-panel {
	border-radius: 8px;
	box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
		0 8px 10px -4px rgba(130, 136, 148, 0.28);
	background-color: #fff;
	padding: 32px 24px;
	margin-bottom: 24px;
	position: relative;

	.field-holder {
		margin-bottom: 15px;
	}

	.panel-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 40px;

		h3 {
			font-size: 24px;
			line-height: 36px;
		}

		a {
			line-height: 28px;
		}
	}

	.panel-content {
		margin-bottom: 10px;

		.button-holder {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding-top: 8px;

			a {
				line-height: 16px;
				font-size: 13px;
				flex: 0 0 90px;
			}

			.button {
				padding: 10px 38px;
			}

			&.actions-holder {
				justify-content: space-between;
			}
		}
	}

	@media screen {
		@media (min-width: $screen-sm) {
			max-width: 485px;
			margin: 0 auto 24px;
			padding: 40px;
			box-sizing: border-box;

			.panel-header {
				h3 {
					font-size: 28px;
				}
			}

			.panel-content {
				.button-holder {
					a {
						flex: 1;
					}

					.button {
						padding: 10px 72px;
					}
				}
			}
		}
	}
}
