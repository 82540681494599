.select-item-wrapper {
  border-radius: 0px;
  box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
    0 4px 6px -2px rgba(130, 136, 148, 0.32);
  box-sizing: border-box;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 80px;
  overflow: hidden;
  padding: 0 25px;
  position: relative;
  transition: all 0.3s ease-in-out;
  width: 100%;
  max-width: 100%;

  .description-option {
    font-size: 14px;
    line-height: 22px;
  }

  &:first-child {
    border-radius: 24px 24px 0 0;
  }

  &:last-child {
    border-radius: 0 0 24px 24px;
  }

  &:only-child {
    border-radius: 24px;
  }

  &::before {
    background-color: $color-pink-light;
    content: "";
    display: block;
    height: 100%;
    opacity: 0;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    transition: all 0.3s ease-in-out;
    width: 8px;
  }

  &._show-lines-ever {
    background-color: #f5f5f5;

    &::before {
      opacity: 1;
    }
  }

  &:hover {
    background-color: #f5f5f5;
  }

  &._icon {
    i {
      font-size: 36px;
      width: 40px;
      text-align: center;
      color: $color-purple;
      margin-right: 28px;

      &.icon-new-credit-card {
        font-size: 32px;
      }
    }

    svg {
      margin-right: 28px;
      width: 40px;
    }
  }

  &._new {
    &::after {
      background-color: $color-pink;
      border-radius: 50px;
      color: #fff;
      content: "Novo";
      display: block;
      font-family: $font-display;
      font-size: 11px;
      line-height: 16px;
      padding: 4px 14px;
      position: absolute;
      left: 250px;
      text-transform: uppercase;
    }
  }

  &._radius {
    border-radius: 24px;
  }

  &._gray {
    box-shadow: none;
    background-color: #f5f5f5;
  }

  &._selected {
    cursor: auto;

    .info {
      flex: 1;
      display: flex;
      justify-content: space-between;
    }
  }

  &._bordered {
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 1px 2px -1px rgba(130, 136, 148, 0.48);

    &:hover {
      background-color: #fff;
    }
  }

  @media screen {
    @media (min-width: $screen-sm) {
      padding: 0 37px;
    }
  }
}
