.tooltip-holder {
  position: relative;
  overflow: visible;

  display: none;

  .tooltip-icon {
    border-radius: 50%;

    background-color: #666;

    color: #fff;

    padding: 3px 5px 3px 6px;

    font-size: 12px;

    margin-left: 10px;

    &.icon-i {
      padding: 3px 8px 3px 7px;
    }
  }

  .tooltip-description {
    pointer-events: none;

    background-color: #666;

    color: #fff;

    position: absolute;
    left: -122px;
    top: 36px;

    z-index: 10;

    opacity: 0;

    transition: all 0.3s ease-in-out;

    width: 230px;

    text-align: center;
    text-transform: none;

    font-weight: 300;
    font-size: 10px;

    line-height: 16px;

    white-space: normal;

    padding: 12px;

    border-radius: 10px;

    &.large {
      width: 260px;
    }

    &:before {
      content: "";

      display: block;

      width: 0;

      font-size: 0;

      border-bottom: 9px solid #666;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;

      position: absolute;
      top: -9px;
      left: 0;
      right: 0;

      margin: 0 auto;
    }
  }

  &:hover {
    .tooltip-description {
      opacity: 1;
    }
  }

  @media screen {
    @media (min-width: 768px) {
      display: block;
    }
  }
}
