.schedule-calendar {
  overflow-y: hidden !important;
  .week,
  .day {
    border-top: 1px solid $color-semi-gray;
    border-left: 1px solid $color-semi-gray;
    border-right: 1px solid $color-semi-gray;
    border-collapse: collapse;
  }

  .skeleton {
    width: 100%;
    max-width: 1640px;
  }

  &.schedule-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hidden {
    display: none !important;
  }

  .day {
    background: #f5f5f5;
    .calendar-line {
      background: #ffff;
      width: 225px;
      height: 80px;

      border-left: 1px solid $color-semi-gray;
      border-right: 1px solid $color-semi-gray;

      &.no-line-center {
        border-left: none;
        border-right: none;

        background: #dedede;
      }
    }
  }

  .month {
    border-collapse: collapse;
    th {
      width: 234px;
      padding: 12px 30px;
    }

    .box-month {
      position: relative;
      cursor: pointer;
      width: 234px;
      height: 106px;
      border: 1px solid $color-semi-gray;

      &.today {
        background: #f0f0f0;
      }
    }
  }

  td,
  tr,
  th {
    padding: 0;
    margin: 0;
  }

  th {
    border: 1px solid $color-semi-gray;
    font-family: $font-display;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    color: $color-purple;
    text-transform: uppercase;

    background-color: #f5f5f5;

    min-width: 100px;
    padding: 0;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .calendar-container-box {
    cursor: pointer;
    border-bottom: 1px solid $color-semi-gray;
    border-left: 1px solid $color-semi-gray;
    border-right: 1px solid $color-semi-gray;

    width: 225px;
    height: 80px;

    &.no-border {
      border-top: none;
      border-right: none;
      border-left: none;

      &:nth-child(1) {
        border-right: 1px solid $color-semi-gray;
      }
    }
  }

  .schedule-header {
    border-bottom: 1px solid $color-semi-gray;
  }

  .schedule-label-hour {
    text-align: center;
  }

  .schedule-date {
    text-align: left;
    padding-left: 13px;
    border-right: none;
  }

  .schedule-hour {
    background: #ffffff;
    border: 1px solid $color-semi-gray;
    padding-top: 5px;
    padding-bottom: 53px;
    text-align: center;

    font-family: $font-display;
    font-size: 14px;
    font-weight: 500;
  }
}

.schedule-box-info {
  background: rgb(236, 236, 236);

  &.color {
    background: white;
  }

  width: 225px;
  height: 81px;
  div {
    padding: 12px 0;
    padding-left: 16px;
    padding-right: 7px;
    font-family: $font-display;
    h4 {
      margin: 0;
      padding: 0;
      font-size: 12px;
      font-weight: 600;
      line-height: 20px;
    }

    p {
      margin: 0;
      padding: 0;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      color: #363636;
    }
  }
}

.calendar-lines {
  tr {
    display: block;
    height: 40px;
    width: 225px;
  }

  tr:nth-child(2) {
    border-top: 1px solid $color-semi-gray;
    border-bottom: 1px solid $color-semi-gray;
  }
}

.schedule-box-month {
  strong {
    font-family: $font-display;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    position: absolute;
    top: 6px;
    left: 15px;
    color: $color-bold-gray;
  }

  .info-box-month {
    margin-left: 15px;
    margin-top: 8px;
    width: 234px;
    p {
      color: $color-bold-gray;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      padding: 0;
    }
  }
}

.schedule-week {
  position: relative;

  .calendar-line {
    background: #ffff;
    width: 221px;
    height: 81px;

    border-left: 1px solid $color-semi-gray;
    border-right: 1px solid $color-semi-gray;
    tr {
      width: 221px;
    }

    &.no-line-center {
      border-left: none;
      border-right: none;

      background: #dedede;
    }
  }

  &.schedule-before::before {
    content: "";
    position: absolute;
    background: #f5f5f5;
    overflow: none;
    z-index: -1;
    border-top: 1px solid $color-semi-gray;
    height: 100%;
    width: 4150px;
  }

  &.schedule-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .schedule-week-flex {
    display: flex;

    .calendars-card-table:not(.calendars-card-table:nth-child(2)) {
      margin-left: 0.5rem;
    }
  }
}

.calendar-month-mini {
  &.react-calendar {
    width: auto;
    border: 1px solid $color-purple;
    border-radius: 3px;
    padding: 15px;
    .react-calendar__month-view__weekdays__weekday {
      padding: 0.5rem 0px;
      abbr {
        text-decoration: none;
        color: $color-purple;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        font-family: $font-display;
      }
    }

    .react-calendar__navigation__label__labelText {
      color: $color-purple;
      font-weight: 500;
      font-family: $font-display;
      font-size: 16px;
    }
  }
  .calendar-title-mini-month {
    font-family: $font-display;
    font-size: 13px;
  }
}
