//! Refactorar para mobile first
@media screen and (max-width: 1260px) {
  .nav-topbar {
    position: fixed !important;
  }

  .app-content {
    .content-holder {
      padding-top: 0;
      padding-bottom: 70px;
      height: 100%;
      overflow: hidden;

      .page-heading {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
        background-color: #fff;

        top: 70px;
        left: 0;
        right: 0;

        z-index: 200;

        padding: 15px;

        .form-heading {
          margin-top: 15px;
          width: 100%;
        }

        .search-input {
          margin-top: 15px;
          max-width: 400px;
        }

        .search-button {
          margin-top: 15px;
          padding: 15px 10px 0;
        }
      }

      .page-content {
        padding: 10px 15px;
      }

      .button-details {
        font-size: 12px;
      }
    }

    .pagination-wrapper {
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
      background-color: #fff;
      padding: 10px 0;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }
}

//! Refactorar para mobile first
@media screen and (max-width: 767px) {
  .nav-topbar {
    position: fixed !important;
  }

  .app-content {
    .content-holder {
      .page-heading {
        .search-input {
          flex: 1 !important;
          max-width: 100%;
        }

        .search-button {
          padding: 15px 0 15px 15px;
        }
      }
    }
  }
}

//! Refactorar para mobile first
@media screen and (max-width: 592px) {
  .app-content {
    .content-holder {
      .page-heading {
        .wrapper-header-content {
          flex-wrap: wrap;
        }

        .page-header {
          text-align: center;

          width: 100%;

          .flex-header {
            display: flex;
          }
        }

        .actions-holder {
          margin-top: 15px;
          text-align: center;
          width: 100%;
        }
      }
    }
  }
}

.content-holder {
  display: flex;
  flex-direction: column;
  &.holderPanelActive {
    min-height: 100vh;
    height: 100%;
  }
  .page-heading {
    padding: 20px 20px;
    background-color: #fff;

    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.05);
    box-sizing: border-box;

    .upload-area {
      width: 100%;
      margin: 15px 0 0 0 !important;
      box-shadow: none;
    }

    .search-input {
      padding: 15px 0 0;
      width: 100%;
      max-width: 100%;
    }

    .search-button {
      padding: 14px 10px 0;
    }

    .form-heading {
      margin-top: 0 !important;
      width: 100%;
    }
  }

  .page-content {
    padding: 10px 20px;
    overflow: auto;
  }

  .min-h {
    min-height: 450px;
  }

  &.remove-padding {
    height: calc(100vh - (174px + 10px + 18px));
  }

  &.content-holder-no-filter {
    height: calc(100vh - (174px + 10px + 18px - 62px));

    @media screen {
      @media (min-width: 591px) {
        padding-bottom: 30px;
      }

      @media (min-width: 768px) {
        height: calc(100vh - (174px + 10px + 18px - 102px));
      }

      @media (min-width: 1260px) {
        padding-top: 10px;

        height: calc(100vh - (174px + 10px + 18px - 90px));
      }
    }
  }
}
