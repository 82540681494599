.flexItems{
    display: flex; 
    align-items: center; 
    justify-content: center;

}

.FixWidthSelect {
    width: 148px;
    margin-right: 2rem;
}

@media only screen and (max-width: 600px) {
    .FixWidthSelect {
        margin-top: .5rem;
        width: auto;
        margin-right: auto;
    }
    .flexItems{
        margin-top: .5rem;
    }
}
