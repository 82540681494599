.select-item-plot {
  justify-content: space-between;
  padding: 0 32px 0 32px;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 8px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
  }

  span {
    font-size: 14px;
    display: inline-block;
  }

  .info {
    span {
      margin-right: 16px;

      &.value {
        width: 80px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &._plot-selected {
    background-color: #f5f5f5;

    &:before {
      background-color: #ab92bf;
      opacity: 1;
    }
  }
}
