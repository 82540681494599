.ReactModalPortal * {
    outline: transparent !important;
}


.modal-details-schedule{
    background: white;
    width: 575px;
    min-height: 500px;
    border-radius: 3px;
    height: auto;
    overflow-y: auto;
    max-height: calc(100vh - 5rem);
    margin: 15% auto;

    p {
        padding: 0;
        margin: 0;
        font-family: $font-display;
    }

    .decoration {
        text-decoration: underline;
    }

    .subtitle-modal-details{
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: $color-purple;
        margin-bottom: 13px;

        &.m-2 {
            margin-bottom: 23px;
        }
    }

    .loading-margin-bottom{
        margin-bottom: 1rem;
    }


    .content-modal-schedule{
        padding-top: 40px;
        padding-left: 62px;
        padding-right: 62px;
        
        .header-details-modal{
            margin-bottom: 31px;
            &.m-2 {
                margin-bottom: 23px;
            }

            .title-modal-details{
                color: $color-purple;
                font-weight: 500;
                font-size: 24px;
                line-height: 34px;
            }
            
            .schedule-details-information{
                margin-top: 8px;
                display: flex;
                flex-direction: column;
                p, a {
                    font-size: 13px;
                    line-height: 18px;
                    color: $color-gray-text;
                }

                a {
                    text-decoration: underline; 
                    cursor: pointer;
                    transition: color .2s;

                    &:hover{
                        color: $color-hgray-text;
                    }
                }

            }
        }

        .details-info{
            .margin{
                margin-bottom: 23px;
            }
            
            margin-bottom: 23px;

            label{
                font-family: $font-display;
                font-weight: 500;
                font-size: 13px;
                color: $color-purple !important;
                margin: 8px 0px 8px;
            }

            input, select, .input, .react-select__single-value, .react-select__control{
                border-radius: 3px !important;
                font-family: $font-display;
                color: $color-gray-text;
                font-size: 13px;
                line-height: 20px;
            }

            .modal-details-flex-inputs{
                display: flex;
                justify-content: flex-start;
                gap: 1rem;

                div:nth-child(2) {
                    width: 300px;
                }
            }
        }
    }

    .modal-schedule-buttons{
        border-top: 1px solid $color-purple;
        padding: 20px;
        a {
            color: $color-purple;
            &:hover {
                color: white;
            }
        }

        div {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 12px;
        }

        #history {
            img {
                padding: 0px 35px;
                width: 21px;
            }
        }

        button {
            padding: 9px 15px;
            border-radius: 3px;
        }
    }
}

.modal-change-schedule {
    p{
        margin: 0;
        padding: 0;
        color: $color-gray-text;
        font-family: $font-display;
    }

    .title-change-modal-schedule{
        font-family: 500;
        font-size: 24px;
        line-height: 37px;
        color: $color-purple;
    }

    .description-change-modal-schedule{
        margin-top: 27px;
        margin-bottom: 32px;
        font-size: 13px;
        font-weight: 500;
        line-height: 18px;

        strong {
          color: $color-purple;
        }
    }

    .modal-change-title{
        font-family: $font-display;
        font-weight: 500;
        font-size: 13px;
        color: $color-purple !important;
        margin: 8px 0px 8px;
        text-transform: uppercase;
    }

    .no-hours-modal{
        text-align: center;
    }
}


.modal-cancel-schedule{
    p {
        margin: 0;
        padding: 0;
        font-display: $font-display;
        color: $color-gray-text;
    }

    .description-cancel-schedule{
        .subtitle-description {
            font-size: 14px;
            font-weight: 600;
            color: $color-purple;
            margin-bottom: 2rem;
        }

        p {
            font-weight: 500;
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 1px;
            color: $color-gray-text;

            strong {
                text-decoration: underline;
                color: $color-purple;
            }
        }
    }

    .checklist-modal-cancel{
        margin-top: 37px;
        display: flex;
        flex-direction: column;
        margin-bottom: 31px;

        .container-modal-checklist {
            &.send, &.check {
                .box-checklist-modal  {
                    position: relative;
                    .background {
                        height: 15px;
                        width: 15px;
                        top: 1px;
                        left: 1px;
                        position: absolute;
                        background: #572580;
                    }
                }
            }
        }

        .container-modal-checklist{
            display: flex;
            align-items: center;
            gap: 1rem;
            margin-bottom: 1rem;
            cursor: pointer;
            .box-checklist-modal {
                width: 17px;
                height: 17px;
                border: 1px solid $color-purple;
            }
        }
    }
}


.modal-schedule{
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    height: auto;
}