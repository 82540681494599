.cart-side-holder {
    position: relative;
}

.cart-side {
    > .title-cart {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 20px;
    }

    @media screen {
        @media (min-width: 768px) {
            position: absolute;

            top: 0px;
            bottom: 0;
            left: 0;
            right: 0;
        }

        @media (min-width: 1024px) {
            overflow: auto;

            max-height: 100%;

            top: -50px;
            left: 30px;

            padding: 20px 20px 0;

            border-left: 1px solid #777;
        }
    }
}

.purpletext {
    color: #572580;
}