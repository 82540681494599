.SingleDatePicker_picker {
    z-index: 999 !important;

    .DayPickerNavigation {
        .DayPickerNavigation_button {
            border-radius: 10px;

            outline: none;

            svg {
                fill: $color-purple;
            }
        }
    }

    .CalendarMonth {
        .CalendarMonth_table {
            .CalendarDay {
                transition: all .3s ease-in-out;

                &.CalendarDay__selected {
                    background: $color-purple;
                    color: #FFF;
                    
                    border-color: $color-purple;
                }
            }
        }
    }
}