.card-labi-credits-avaliable {
  background: linear-gradient(0deg, #e8d8f5, #e8d8f5);
  width: 255px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding: 24px 24px 24px 28px;

  &.other-style-card-credits {
    background: white;
    background: #ffffff;
    box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
      0px 1px 2px -1px rgba(130, 136, 148, 0.48);

    .card-labi-credits-image {
      background: #f5f5f5;
    }

    strong {
      font-family: $font-display;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
    p {
      color: #999999;
    }
  }

  .card-labi-credits-image {
    background-color: white;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 16px;
    font-family: $font-display;
    font-weight: 400;
    color: $color-gray-text;

    margin: 0;
    padding: 0;
  }

  strong {
    color: $color-purple;
    font-size: 24px;
    font-weight: 500;
    font-family: $font-display;
  }
}

.cards-flex-labi-credits {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
}

.container-card-labi-credits-extract {
  background: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 1px 2px -1px rgba(130, 136, 148, 0.48);
  margin-bottom: 1rem;

  width: 888px;
  height: 108px;

  &::before {
    content: "";
    height: 108px;
    width: 7px;
    background-color: $color-green-money;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  &.exit {
    .card-money-value {
      color: $color-exit-money;
    }
    &::before {
      background-color: $color-exit-money;
    }
  }

  p {
    margin: 0;
    padding: 0;
  }

  display: flex;
  align-items: center;

  .flex-card-extract {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    .card-money-value {
      color: $color-green-money;
      font-family: $font-text;
      font-size: 1rem;
      line-height: 22px;
      width: 80px;
      font-weight: 700;
    }

    .card-p-formatter {
      color: #999999;
      font-family: $font-text;
      font-weight: 600;
      font-size: 1rem;
      line-height: 22px;
      text-transform: uppercase;
      width: 100px;

      &.font-display {
        font-family: $font-display;
      }
    }

    & > div {
      width: 30%;
    }
  }

  &.due-date {
    &::before {
      background-color: $color-use-money;
    }
    .card-money-value {
      color: $color-use-money;
    }
    .card-p-formatter {
      &.color {
        color: $color-use-money;
      }
    }
  }

  &.buy-with-credits {
    .card-money-value {
      color: $color-buy-credits;
    }
    .card-p-formatter {
      &.color {
        color: $color-buy-credits;
        cursor: pointer;
      }
    }
    &::before {
      background-color: $color-buy-credits;
    }
  }
}

.center-page {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
