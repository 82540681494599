body {
  font-family: $font-text;
  font-size: $font-size-body;
  color: $font-color-body;
  line-height: 1.4;
}

p {
  margin-top: 0;
  color: #666;
  font-size: $font-size-body-md;
  line-height: 22px;
  margin-bottom: 15px;

  &.text-page-description {
    font-size: $font-size-sm-md;
    color: #999;
  }
}

h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  margin-top: 0;
  font-family: $font-display;
  font-weight: $font-weight-heading;
}

h1,
.h1 {
  font-size: $font-size-h1;
  line-height: $line-height-h1;
}
h2,
.h2 {
  font-size: $font-size-h2;
  line-height: $line-height-h2;
}
h3,
.h3 {
  font-size: $font-size-h3;
  line-height: $line-height-h3;
}
h4,
.h4 {
  font-size: $font-size-h4;
  line-height: $line-height-h4;
}
h5,
.h5 {
  font-size: $font-size-h5;
  line-height: $line-height-h5;
}

// Sizes
.lead {
  font-size: $font-size-lead;
}

small,
.small {
  font-size: 12px;
}
.xsmall {
  font-size: $font-size-xs;
}

.margin-2 {
  margin-bottom: 1rem;
}

.uppercase {
  text-transform: uppercase !important;
}
.capitalize {
  text-transform: capitalize !important;
}
.transform-none {
  text-transform: none !important;
}

// Alignment
.text-center {
  text-align: center !important;
}
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}

.text-centered {
  margin: 0 auto;
}

// Weight
.text-light {
  font-weight: 300;
}
.text-semibold {
  font-weight: 600;
}

.text-normal {
  font-weight: 500;
}

.text-bold {
  font-weight: 700;
}

.text-size {
  font-size: 1.5rem !important;
}

.text-saira {
  font-family: $font-display;
}

// Decoration
.text-line-trough {
  text-decoration: line-through;
}
.text-underline {
  text-decoration: underline;
}

// Click
.text-click {
  cursor: pointer;
}

// Color
.text-lgray {
  color: $color-lgray-text !important;
}
.text-hgray {
  color: $color-hgray-text !important;
}
.text-gray {
  color: $color-gray-text !important;
}
.text-white {
  color: white !important;
}
.text-purple {
  color: $color-purple !important;
}
.text-yellow {
  color: $color-yellow !important;
}
.text-danger {
  color: $color-danger !important;
}
.text-success {
  color: $color-success !important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

// Separators
.title-separate-content {
  margin-bottom: $separate-title-from-content;
}

.text-separate-content {
  margin-bottom: $separate-text-from-content;
}

.text-separate-text {
  margin-bottom: $separate-base;
}

.text-no-margin {
  margin-bottom: 0;
}

// Opacity
.text-opacity-75 {
  opacity: 0.75;
}
.text-opacity-50 {
  opacity: 0.5;
}
.text-opacity-25 {
  opacity: 0.25;
}

// Diagram
.text-block {
  display: block;
}

.hide-element {
  display: none;
}

.text-display {
  font-family: $font-display;
}

.font-text {
  font-family: $font-text;
}

@media screen {
  @media (min-width: $screen-sm) {
    .sm-text-inline-block {
      display: inline-block;
    }
  }
}
