.form-address-item {
    .links-actions-holder {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .link {
            padding: 0;
        }
    }

    &._edit {
        background-color: #F5F5F5;
        border-radius: 8px;
        padding: 20px;
        margin-bottom: 0px;
        border-bottom: 0;
    }

    &._no-editable {
        .field-holder {
            .input {
                box-shadow: none;
                background-color: #E7E7E7;
                border-color: #E7E7E7;
                cursor: no-drop;
            }
        }
    }
}