.app-header {
	background: $color-purple;
	position: relative;
	padding: 25px 0;

	.logo {
		float: left;
	}

	&.app-header-logo-only {
		background: $color-purple;
		border-radius: 0 0 48px 48px;
		padding: 47px 0 53px;

		.logo {
			width: 167px;
			height: 36px;
		}
	}

	&.app-header-logo-center {
		.logo {
			float: none;
			margin: 0 auto;
		}
	}

	&.app-header-auth {
		margin-bottom: 24px;
	}

	@media screen {
		@media (min-width: $screen-sm) {
			.logo {
				float: none;
			}

			&.app-header-logo-only {
				border-radius: 0 0 64px 64px;
				padding: 66px 0 98px;
		
				.logo {
					width: 209px;
					height: 46px;
					top: 0;
				}
			}

			&.app-header-auth {
				margin-bottom: -32px;
			}
		}

		@media (min-width: $screen-md) {
			.logo {
				position: relative;
				top: 5px;
			}
		}
	}
}