.table-list-cards {
  width: 700px;
  overflow-x: auto;

  &.table-budgets {
    box-sizing: border-box;
    padding: 2rem 2rem 0;
  }

  > .list-header {
    padding: 0 15px;
    margin: 0 0 10px;

    list-style: none;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > .item {
      background-color: #fff;
      font-family: $font-display;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      color: $color-purple;
      text-transform: uppercase;
      padding: 5px 0;
      text-align: left;

      flex: 1;
    }
  }

  &.--result {
    > .list-header {
      > .item {
        max-width: 220px;
      }
    }
  }

  @media screen {
    @media (min-width: $screen-sm) {
      width: 100%;

      overflow: visible;
    }
  }
}
