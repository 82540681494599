.page-stepper-holder {
  border-bottom: 1px solid #777;

  padding: 15px 0;
}

.page-stepper-list {
  max-width: 810px;

  margin: 0 auto;

  display: flex;

  flex-direction: column;

  align-items: center;

  justify-content: center;

  > .page-stepper-item {
    text-align: center;

    > .icon-holder {
      margin: 0 auto 10px;

      text-align: center;

      height: 45px;
      width: 45px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid #333;
      border-radius: 50%;

      box-shadow: none;

      background-color: #fff;

      transition: all 0.3s ease-in-out;

      > i {
        font-size: 20px;

        color: #333;
      }
    }

    > span {
      display: block;

      font-size: 14px;

      color: #333;

      transition: color 0.3s ease-in-out;
    }

    &.active,
    &:hover {
      > .icon-holder {
        background-color: $color-purple;
        border-color: $color-purple;

        box-shadow: 0 3px 10px 2px rgba(0, 0, 0, 0.4);

        > i {
          color: $color-yellow;
        }
      }

      > span {
        color: $color-purple;
      }
    }
  }

  > i {
    display: inline-block;

    transform: rotate(90deg);

    flex: 0 0 auto;
    max-width: 18px;

    margin: 10px 0;

    color: #777;

    font-size: 28px;
  }

  @media screen {
    @media (min-width: 600px) {
      flex-direction: row;

      justify-content: space-evenly;

      > i {
        margin: 0 5px;

        transform: rotate(0);

        flex: 0 0 auto;
        max-width: none;
      }
    }
  }
}
