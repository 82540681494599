.yellow {
	color: $color-yellow;
}

.generate-margin-1{
	margin-top: 1rem;
}

.padding-2 {
	padding: 2rem;
}

.padding-bottom-2 {
	padding-bottom: 2rem !important;
}

.generate-margin-10 {
	margin-top: 10px;
}

.full-width {
	width: 100%;
}

.mr-10 {
	margin-right: 10px;
}

.flex-buttons {
	display: flex;
}

.sort-buttons {
	margin-left: 2px;
	border: none;
	background-color: transparent;
	font-size: 18px;
	cursor: pointer;
	float: right;
}

.resizeImg {
	img {
		padding: 0px 35px;
		width: 21px;
	}
}

.image-region {
	border-radius: 50%;
	width: 50px;
	height: 50px;
}

.box-fake-image {
	display: flex;
	align-items: center;
	justify-content: center;
	.fake-image {
		opacity: 0.6;
		background-color: #e0d6f5;
		border-radius: 50%;
		width: 50px;
		height: 50px;
	}
}
.padding-8 {
	padding-left: 8px;
}
