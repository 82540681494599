form {
	&.form-contact {
		&.field-holder {
			&:last-child {
				margin-bottom: 32px;
			}
		}
	}

	.label-section-title {
        color: #572580;
        
		font-family: "Saira", sans-serif;
		font-size: 15px;
        font-weight: 500;
        
        margin: 15px 0;
	}
}
