.card-product {
    background-color: #FFF;
    box-sizing: border-box;
    box-shadow: 0 0 0 1px rgba(130,136,148,.16), 0 8px 10px -4px rgba(130,136,148,.28);
    border-radius: 24px;
    padding: 30px 25px;
    margin: 0 0 16px;
    width: 330px;

    > .title {
        min-height: 48px;
        width: 100%;

        overflow: hidden;

        display: -webkit-box;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        color: $color-purple;

        font-family: $font-text;
        font-weight: 700;
        font-size: 19px;

        line-height: 24px;

        margin-bottom: 20px;
    }

    > .info {
        > .description {
            height: 42px;

            color: #b1b1b1;

            font-size: 13px;
            font-family: $font-text;

            margin-bottom: 16px;

            overflow: hidden;

            display: -webkit-box;

            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        > .price-holder {
            display: flex;
            align-items: center;

            > .price,
            > .promo-price {
                margin-bottom: 20px;

                color: $color-purple;

                font-family: $font-display;
                font-size: 20px;

                line-height: 23px;

                &.cancel-price {
                    text-decoration: line-through;
                }
            }

            > .price {
                color: $color-purple;
                font-size: 20px;
                font-family: $font-display;
                font-weight: 600;
                
            }

            > .promo-price {
                margin-left: 10px;

                font-size: 14px;

                color: #A6A6A6;
            }
        }

        > .button {
            > i {
                font-size: 20px;
                font-weight: 600;
                
                float: right;
                
                margin-left: 15px;
            }
        }
    }
}