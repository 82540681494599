.card-table {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px;

  box-shadow: 0 1px 5px 2px rgba(130, 136, 148, 0.28);

  background-color: #fff;

  border-radius: 15px;

  margin-bottom: 20px;

  ._action {
    padding: 3px 7px;

    font-size: 12px;

    border-radius: 4px;

    margin-left: 10px;
  }

  > span,
  > div {
    flex: 1;
  }

  &.--result {
    > span,
    > div {
      max-width: 220px;
    }
  }
}

.card-table-empty {
  margin: 20px 0 10px;

  p {
    margin-bottom: 0;
  }
}

.card-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .courtesy {
    display: block;
  }
  .courtesy a {
    cursor: pointer;
    padding: 0;

    font-family: $font-text;
    color: $color-purple;
    font-weight: 700;
    font-size: 14px;
    text-transform: uppercase;
    line-height: 18px;
    transition: all 0.2s ease-in-out;
    &:hover {
      filter: opacity(0.9);
    }
  }
}
