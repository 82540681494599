.order-resume-item {
    border-bottom: 1px solid #E5E5E5;
    padding-bottom: 24px;

    .title {
        margin-bottom: 32px;
    }

    .data-list {
        list-style: none;

        padding: 0;

        .data-list-item {
            padding: 7px 0;

            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    &:last-child {
        border-bottom: 0;
        padding-bottom: 0;
    }

    &.order-resume-products {
        .data-resume-item {
            border-bottom: 1px dashed #E5E5E5;

            padding-bottom: 24px;

            margin-bottom: 24px;
        }
    }
}