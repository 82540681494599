.page-heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &.with-cart {
    width: calc(100% - 390px) !important;
  }

  &.center-items {
    justify-content: center;
  }

  h2 {
    margin-bottom: 5px;
  }
  .button-min-width{
    min-width: 170px;
  }
  .actions-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;

    .link{
      margin-right: 15px;
    }

    .form-message {
      margin-right: 15px;
    }

    .createbtn {
      border-radius: 30px;
      padding: 8px 36px;
    }
  }

  &.no-shadow {
    box-shadow: none;
  }

  .wrapper-header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    @media screen and (min-width: $screen-md) {
      justify-content: space-between;
      &.order-budget {
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }

  .convenant_head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 27px;

    .convenant_search__input {
      display: flex;
      align-items: center;

      button {
        margin: 0 0 0 14px;
        font-family: $font-display;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 22px;
        cursor: pointer;
        background: none;
        border: none;
        color: $color-purple;
      }
    }

    .convenant_order__button {
      position: relative;

      button {
        background: #d5c8df;
        border-radius: 5px;
        border: none;
        font-family: $font-display;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: $color-purple;
        padding: 3px 10px;
        cursor: pointer;
      }

      img {
        margin-right: 1px;
      }
      img:last-child {
        margin-right: 4px;
        transform: rotate(180deg);
      }

      .filter-options {
        display: flex;
        flex-direction: column;
        position: absolute;
        background: #ffffff;

        border: 0.5px solid #f0f0f0;
        border-radius: 10px;
        padding: 13px 16px;
        width: max-content;
        right: 0;
        margin-top: 9px;
        box-shadow: 3px 7px 9px 0px #66666663;
        opacity: 0.7;

        &.close {
          display: none;
        }

        li {
          list-style: none;

          a {
            font-family: $font-text;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
            cursor: pointer;
            text-decoration: none;

            color: #666666;

            &:hover {
              color: #572580;
              opacity: 0.7;
            }
          }

          &:not(li:last-child) {
            margin-bottom: 26px;
          }
        }
      }
    }
  }

  &.inputs-solid {
    button {
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 3px;
    }

    label {
      font-family: $font-display;
      font-weight: 600;
      font-size: 14px;
      color: $color-purple !important;
      margin: 8px 0px 8px;
    }

    input,
    select,
    .input,
    .react-select__single-value,
    .react-select__control {
      border-radius: 3px !important;
      font-family: $font-display;
      color: $color-gray-text;
      font-size: 13px;
      line-height: 20px;
    }

    .flex-items {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    .select-search-submit-types {
      width: 155px;

      &.width-2 {
        width: 250px;
      }
    }
  }
}

.margin-users {
  margin-top: 1rem;
}
