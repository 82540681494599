.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%; 
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide--rtl {
  direction: rtl;
}


.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1;
}
.glide__arrow:focus {
  outline: none;
}
.glide__arrow:hover {
  border-color: white;
}
.glide__arrow--left {
  left: 2em;
}
.glide__arrow--right {
  right: 2em;
}
.glide__arrow--disabled {
  opacity: 0.33;
}
.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%);
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em;
}
.glide__bullet:focus {
  outline: none;
}
.glide__bullet:hover,
.glide__bullet:focus {
  border: 2px solid white;
  background-color: rgba(255, 255, 255, 0.5);
}
.glide__bullet--active {
  background-color: white;
}
.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}
.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}


.glide-custom {
  max-width: 100%;
  margin: 0 auto;
  padding: 20px 0;

  .glide__track {
    overflow: hidden;
  }

  .glide__slides {
    display: flex;
    justify-content: center; /* Para centralizar os slides */
    overflow: visible; /* Para permitir que a largura extra apareça */
  }

  .each__slide{
    display: grid;
    grid-template-columns: repeat(3, 1fr);  /* 3 elementos por linha */
    grid-template-rows: repeat(2, auto);    /* 2 linhas */
    gap: 15px;
    width: 120%; /* Aqui você aumenta a largura do slide */
    padding: 10px;
    background-color: red; /* Apenas para visualização */
    max-width: 1400px; /* Limite máximo de largura */
    box-sizing: border-box; /* Para garantir que o padding não afete o layout */
  }

  .card {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
    cursor: pointer;
    width: 100%; /* Adicionar largura total à cada card */
    height: 100%; /* Garantir que os elementos cresçam para preencher a altura */
    box-sizing: border-box;
    // background-color: blue;

    &_selected {
      background-color: #e6e6e6;
    }

    .date {
      font-size: 16px;
      color: #572580;
    }
  }

  // .glide__arrows {
  //   -webkit-touch-callout: none;
  //   user-select: none;
  // }

  .glide__arrows{
    position: static;

    .glide__arrow{
      background-color: $color-purple;
      border-radius: 100%;
      border: none;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      width: 24px;
      position: static;
      padding: 0;
      box-shadow: none;
      text-shadow: none;
      transform: initial;

      i {
        font-size: 18px;
      }
    }
  }

  .glide__arrows{
    .glide__arrow{
      &.glide__arrow--prev{
        margin-right: 10px;
      }
      &.glide__arrow--next{
        // margin-left: -80px;
        position: absolute;
      }
    }
  }

  @media (min-width: 1185px) {
    &.slider-dates-home-collect{
      max-width: 552px;

      .glide__arrows{
        .glide__arrow{
          &.glide__arrow--prev{
            margin-right: 5px;
          }
        }
      }
    }
  }

}
