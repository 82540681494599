.installments-list {

    > .installment-item {
        border-radius: 0;

        box-shadow: 0 0 0 1px rgba(130,136,148,.16), 0 4px 6px -2px rgba(130,136,148,.32);
        background-color: #fff;

        overflow: hidden;

        height: 60px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        position: relative;
        
        padding: 0 32px;

        cursor: pointer;

        transition: all .3s ease-in-out;

        span {
            font-size: 14px;

            display: inline-block;
        }

        .info {
            span {
                margin-right: 16px;

                &.value {
                    width: 80px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
        
        &:before {
            content: "";

            display: block;
            width: 8px;

            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;

            background-color: transparent;

            transition: all .3s ease-in-out;
        }

        &.selected {
            background-color: #f5f5f5;

            &:before {
                background-color: #ab92bf;
            }
        }

        &:first-child {
            border-radius: 24px 24px 0 0;
        }

        &:last-child {
            border-radius: 0 0 24px 24px;
        }

        &:only-child {
            border-radius: 24px;
        }
    }
}