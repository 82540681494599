.pagination-wrapper {
  position: relative;
  margin-top: 12px;

  .field-holder {
    margin-bottom: 0;
    position: relative;
    z-index: 10;
  }

  @media screen {
    @media (min-width: $screen-sm) {
      height: 44px;
    }
  }
}

.pagination {
  list-style: none;
  text-align: center;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    margin: 0 5px;

    &.page-text {
      flex: 0 0 100%;

      &:first-child {
        margin-bottom: 24px;
      }

      &:last-child {
        margin-top: 24px;
      }
    }

    &.total-records {
      button,
      a {
        min-width: 72px;
        min-height: 72px;

        font-size: 1.25rem;
        line-height: 1.75rem;
      }
    }

    &.page-current {
      button,
      a {
        font-weight: 600;
        background: #ab92bf;
        border: 1px solid #572580;
        color: #ffffff;
      }
    }
  }
}
