.parking-itens-flex{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 2rem;

    .parking-grid-buttons{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }
}