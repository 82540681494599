.select-schedule {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;

  .select-flex {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 2rem;
  }

  button {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 3px;
  }

  .select-button {
    display: flex;
    gap: 0.2rem;
    font-size: 14px;
    line-height: 22px;
  }

  .select-prev-next {
    button {
      font-size: 18px;
    }
    display: flex;
    gap: 0.2rem;
  }

  .select-search-types {
    width: 143px;

    .react-select {
      &.input {
        border-radius: 3px;
      }

      .react-select__control {
        border-radius: 3px;
      }
    }
  }
}

.select-search-submit {
  display: flex;
  gap: 0.5rem;

  .field-holder {
    .input {
      width: 250px;
      border-radius: 0px;
    }
  }

  .select-search-submit-types {
    .react-select {
      width: 143px;
      &.input {
        border-radius: 3px;
      }

      .react-select__control {
        border-radius: 30px;
      }
    }
  }

  button {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 30px;
  }

  &.persons-search {
    flex-wrap: wrap;
    align-items: flex-end;

    input {
      min-width: 400px;
      border-radius: 3px;
    }

    .suggestion-wrapper {
      margin: 0;
    }
  }

  .action-buttons-search {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
}
