a {
    text-decoration: none;
}

.link {
    cursor: pointer;
    color: rgba(153, 153, 153, 0.5);
    text-transform: uppercase;
    text-align: left;
    line-height: 22px;
    font-size: 14px;
    font-family: $font-display;
    transition: all .3s ease-in-out;
    background: transparent;
    border: none;
    outline: none;

    &:hover {
        color: rgba(153, 153, 153, 1);
    }

    &.link-purple {
        color: $color-purple;
    }

    &.link-topbar {
        font-size: 16px;
        text-transform: capitalize;
        line-height: 25px;
        color: $color-yellow;
    }

    &.link-danger {
        color: $color-danger;
    }

    &.link-blue {
        color: #1E1E98;
    }

    &._feat-button {
        margin-right: 20px;
    }

    &.link-icon {
        i {
            font-size: 24px;
            vertical-align: top;
            display: inline-block;
            margin-right: 8px;
        }
    }

    &.yellow {
        color: $color-yellow;
    }
}