
.schedule-box-header{
    margin-top: 1.1rem;
    width: 100%;
}

.page-schedule{
    overflow-x: auto;
    .title-calendar{
        margin-bottom: 14px;
    }
    
    .schedule-header-title{
        font-family: $font-display;
        display: flex;
        align-items: center;
        gap: 1.2rem;
        h3{
            font-size: 20px;
            color: $color-purple;
            font-weight: 500;
            line-height: 31px;
            margin: 0;
        }
        
        span{
            color: #999999;
            font-size: 12px;
            line-height: 18px;
        }
    }
}


.schedule-search-container{
    min-width: 800px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #572580;
    border-radius: 2px;
    padding-top: 16px;
    padding-left: 20px;
    padding-bottom: 16px;
    padding-right: 20px;
    margin-bottom: 1rem;
    cursor: pointer;
    
    .schedule-search-flex-column{
        &.customer {
            width: 300px;
        }
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .search-date{
        display: flex;
        flex-direction: column;
        align-items: center;
        
        strong{
            font-family: $font-display;
            font-weight: 400;
            font-size: 32px;
            line-height: 30px;
            color:  $color-purple;
        }

        p {
            font-family: $font-display;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            text-transform: uppercase;
        }
    }

    p {
        margin: 0;
        padding: 0;
        font-family: $font-display;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        color: $color-bold-gray;
    }

    strong  {
        margin: 0;
        padding: 0;
        font-family: $font-display;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        color:  $color-purple;
    }

    button {
        font-family: $font-display;
        font-size: 13px;
        line-height: 20px;
        text-transform: uppercase;
        border: none;
        background: none;
        cursor: pointer;
        color: $color-purple;
        font-weight: 500;
        transition: color .2s;

        &:hover{
            color: $color-purple_opacity;
        }
    }
}


.history-schedules{
    margin-bottom: 25px;
    p{
        margin: 0;
        padding: 0;
        font-family: $font-display;
        color: $color-purple;
        font-weight: 500;
        font-size: 13px;
    }

    .history-title-schedule{
        margin-bottom: 6px;
    }

    .history-container{
        border: 1px solid #999;
        border-radius: 2px;
        padding: 7px 0 7px 17px;
        margin-bottom: 13px;
        p{
            color: $color-gray-text;
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;

            strong  {
                color: $color-purple;
            }
        }
    }
}


.schedule-select-hours{
    display: grid;
    gap: 14px;
    grid-template-columns: repeat(3, 1fr);
    cursor: pointer;
    div{
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .5s;
        &:hover {
            background: $color-purple;
            p {
                color: $color-ltgray;
            }
        }

        &.clicked {
            background: $color-purple;
            p {
                color: $color-ltgray;
            }
        }

        p{
            font-family: $font-display;
            font-size: 13px;
            line-height: 20px;
            font-weight: 500;
            color: $color-purple;
        }
        padding: 6px ;
        border: 1px solid $color-purple;
        border-radius: 4px;
    }
}