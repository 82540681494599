.exams-checkup-table {
    margin-bottom: 24px;

    .formulary-to-add {
        position: relative;
        margin-bottom: 32px;

        .search-box {
            background-color: #FFF;
            box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16), 0 1px 2px -1px rgba(130, 136, 148, 0.48);
            box-sizing: border-box;
            width: 100%;
            margin-top: -30px;
            position: relative;

            > .loading {
                width: 45px;
                height: 45px;
                margin: 0 auto;
                display: block;
                padding: 15px 0;
            }

            > p {
                padding: 15px 0;
            }

            .item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #666;
                padding: 6px 10px;
                cursor: pointer;

                &:first-child {
                    padding-top: 12px;
                }

                &:last-child {
                    padding-bottom: 12px;
                }
            }
        }
    }
}