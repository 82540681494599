@import "../variables";

@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url("~Assets/#{$icomoon-font-path}/#{$icomoon-font-family}.eot?m53umi");
  src: url("~Assets/#{$icomoon-font-path}/#{$icomoon-font-family}.eot?m53umi#iefix")
      format("embedded-opentype"),
    url("~Assets/#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?m53umi")
      format("truetype"),
    url("~Assets/#{$icomoon-font-path}/#{$icomoon-font-family}.woff?m53umi")
      format("woff"),
    url("~Assets/#{$icomoon-font-path}/#{$icomoon-font-family}.svg?m53umi##{$icomoon-font-family}")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-profile-male {
  &:before {
    content: $icon-profile-male;
  }
}
.icon-credit-card {
  &:before {
    content: $icon-credit-card;
  }
}
.icon-money {
  &:before {
    content: $icon-money;
  }
}
.icon-microscope {
  &:before {
    content: $icon-microscope;
  }
}
.icon-keyboard_arrow_left {
  &:before {
    content: $icon-keyboard_arrow_left; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit;
  }
}
.icon-trash {
  &:before {
    content: $icon-trash;
  }
}
.icon-faq {
  &:before {
    content: $icon-faq;
  }
}
.icon-linkedin-fulll {
  &:before {
    content: $icon-linkedin-fulll;
  }
}
.icon-loupe {
  &:before {
    content: $icon-loupe;
  }
}
.icon-map-pin {
  &:before {
    content: $icon-map-pin;
  }
}
.icon-cart {
  &:before {
    content: $icon-cart;
  }
}
.icon-view {
  &:before {
    content: $icon-view;
  }
}
.icon-help {
  &:before {
    content: $icon-help;
  }
}
.icon-results {
  &:before {
    content: $icon-results;
  }
}
.icon-account {
  &:before {
    content: $icon-account;
  }
}
.icon-call-doctor {
  &:before {
    content: $icon-call-doctor;
  }
}
.icon-discount {
  &:before {
    content: $icon-discount;
  }
}
.icon-clock {
  &:before {
    content: $icon-clock;
  }
}
.icon-tube {
  &:before {
    content: $icon-tube;
  }
}
.icon-angle-double-right {
  &:before {
    content: $icon-angle-double-right;
  }
}
.icon-angle-double-left {
  &:before {
    content: $icon-angle-double-left;
  }
}
.icon-bank-slip {
  &:before {
    content: $icon-bank-slip;
  }
}
.icon-close {
  &:before {
    content: $icon-close;
  }
}
.icon-heart {
  &:before {
    content: $icon-heart;
  }
}
.icon-tests {
  &:before {
    content: $icon-tests;
  }
}
.icon-checked {
  &:before {
    content: $icon-checked;
  }
}
.icon-unchecked {
  &:before {
    content: $icon-unchecked;
  }
}
