.home-collection-holder {
    margin-bottom: 32px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    > span {
        font-family: $font-display;

        color: $color-purple;

        font-size: 14px;

        margin-bottom: 15px;
    }

    @media screen {
        @media (min-width: 1024px) {
            > span {
                flex: 0 0 120px;
                max-width: 120px;

                margin: 0;
            }
        }
    }
}