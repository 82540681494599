.upload-area {
    background-color: #FFF;

    box-shadow: 0 15px 15px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);

    border-radius: 10px;

    padding: 10px 15px;

    margin: 0 0 15px 0;

    .file-info {
        margin: 0;

        padding: 10px;

        border-bottom: 1px solid rgb(183, 155, 229);

        .file-upload {
            padding: 5px 15px;

            .info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    margin: 0;

                    font-size: 10px;
                    text-align: center;
                    text-transform: uppercase;

                    strong {
                        margin-right: 10px;
                    }

                    &:first-child {
                        margin-right: 15px;

                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }

            .upload-feedback {
                font-size: 11px;
                line-height: 22px;
                text-align: right;
            }

            &:not(:last-child) {
                border-bottom: 1px solid rgba(183, 155, 229, .4);
            }
        }
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 10px 0 0;

        > span {
            font-size: 10px;

            display: inline-block;

            margin-right: 15px;
        }

        .holder-actions {
            span {
                font-size: 14px;
                margin-right: 15px;
            }
        }
    }

    .progress-wrapper {
        margin: 0;
    }
}


.uploadInsurance-area {

    .file-info {
        margin: 0;
        .file-upload {
            padding: 5px 15px;

            .info {
                display: flex;
                align-items: center;
                justify-content: space-between;

                p {
                    margin: 0;

                    font-size: 10px;
                    text-align: center;
                    text-transform: uppercase;

                    strong {
                        margin-right: 10px;
                    }

                    &:first-child {
                        margin-right: 15px;

                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }

            .upload-feedback {
                font-size: 11px;
                line-height: 22px;
                text-align: right;
            }

            &:not(:last-child) {
                border-bottom: 1px solid rgba(183, 155, 229, .4);
            }
        }
    }

    .actions {
        display: flex;
        align-items: center;
        justify-content: space-between;

        

        > span {
            font-size: 10px;

            display: inline-block;

            margin-right: 15px;
        }

        .holder-actions {
            span {
                font-size: 14px;
                margin-right: 15px;
            }
        }
    }

    .progress-wrapper {
        margin: 0;
    }
}