* {
	// -webkit-font-smoothing: antialiased;
	// -moz-osx-font-smoothing: grayscale;
	// -moz-font-feature-settings: "liga","kern";
}

body {
	margin: 0;
	padding: 0;

	&.gray-page {
		background-color: $color-ltgray;
	}

	&.checkout-page {
		.certificate-buy-safe {
			margin-bottom: 95px;
		}

		@media screen and (min-width: $screen-md) {
			.certificate-buy-safe {
				margin-bottom: 41px;
			}
		}
	}

	.ReactModalPortal {
		position: relative;
		z-index: 9998;
	}
}
