.copy-text-cart{
    background: #F55430;
    border-radius: 2rem;
    width: 100%;
    position: relative;
    p{
        font-family: "Saira", sans-serif;
        padding: 6px 25px;
        color: #FFFFFF;
        font-size: 12px;
        line-height: 1.188rem;

    }

    button{
        position: absolute;
        background: none;
        border: none;
        font-size: 2rem;
        transform: rotate(45deg);
        cursor: pointer;
        right: -6px;
        top: 1px;

        &:before{
            position: absolute;
            content: "";
            width: 1.7rem;
            height: 1.7rem;
            top: 10px;
            right: 4px;
            background: #ff978041;
            border-radius: 50%;
        
        }
    }
}

@media screen and (min-width: 30.563rem) and (max-width: 63.75rem) {
    .copy-text-cart{ 
        button{
            top: -7px;
        }
    }
}

@media screen and (min-width: 101.625rem){
    .copy-text-cart{ 
        button{
            top: -7px;
            right: -8px;
        }
    }
}

@media screen and (max-width: 600px) {
    .search-products-badge-flex{
        flex-direction: column; 
    }
}
.row-gaps{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.search-products-badge-flex{
    display: flex;
    flex-direction: column;

    margin-top: 1.7rem;
    margin-bottom: 1.7rem;

    &.content{
        justify-content:center;
        align-items: center;
    }


    .cart-flex-buttons{
        display: flex;
        gap: .5rem;  
    }

    &.flexSelect{
        display: flex;
        flex-direction: row;
        justify-content: space-around
    }

    button{
        background: white;
        color: $color-purple;
        width: 100% ;
        min-width: 50px;  
        max-width: 130px; 
        
        font-size: .8rem;
        padding: 5px 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .badge-text-button{
            margin-left: 20px;
        }

        .badge-text-margin{
            margin-left: 15px;
        }
    
        span{
            background: none;
            i{
                background-color: none;
                color: $color-purple ;
                transform: translateY(25%)
            }
        }

        &:hover{
            background: $color-purple;
            color: white ;
            span{
                background-color: none;
                i{
                    color: white !important;
                }
            }
        }

    }

    .closeButton{
        color: #c23f3b !important;
        border-color: #c23f3b !important;

        &:hover{
            background: #cf4844 !important;
            color: white !important;
            span{
                background-color: none;
                i{
                    color: white !important;
                }
            }
        }
    }

    .activeButtonBadge{
        background: $color-purple !important;
        color: white !important;
        span{
            background-color: none;
            i{
                color: white !important;
            }
        }
    }
}