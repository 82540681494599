.entry-app-content {
  padding-top: 0px;
  position: relative;
  padding-right: 0;
  padding-left: 230px;

  &.--full {
    padding-top: 0;

    .content-holder {
      height: 100vh;
      padding-bottom: 0;
      overflow: visible;
    }

    .page-heading {
      position: sticky;
      top: 0;

      &.with-cart {
        width: calc(100% - 390px) !important;
      }

      margin-bottom: 20px;
    }

    .page-content {
      margin-top: 0;

      overflow: visible;
    }
  }

  @media screen and (max-width: 1260px) {
    padding-left: 60px;
  }
}

.entry-budget {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.entry-full-page {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.page-holder-full {
  height: 100%;
}
