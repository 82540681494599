.badge {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;

  border-radius: 16px;

  padding: 6px 24px;

  background-color: #EBEBEB;

  color: $color-gray-text;

  display: inline-block;

  &.danger {
    background-color: $color-danger;

    color: #FFF;
  }

  &.warning {
    background-color: $color-orange;

    color: #FFF;
  }

  &.success {
    background-color: $color-success;

    color: #FFF;
  }
}