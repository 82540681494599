.list-multiple-addresses {

    &._read {
        padding-bottom: 24px;

        .item-address-read {
            padding: 20px 20px;
            border-radius: 8px;
            background-color: #F5F5F5;
            margin-bottom: 24px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}