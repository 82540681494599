.coupon-cart-holder {
    margin-bottom: 32px;

    .field-validate-coupon {
        position: relative;
        
        .button {
            border-radius: 30px;

            position: absolute;
            top: 2px;
            bottom: 2px;
            right: 2px;

            &:hover {
                background-color: $color-purple;
            }
        }
    }
}