.payment-step-holder {
    .step-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-bottom: 20px;

        .step-title {
            font-size: 16px;
            font-family: $font-display;
    
            display: block;
        }
    }

    .payment-method-holder {
        display: flex;
        align-items: stretch;
        flex-wrap: wrap;

        margin: 0 -10px;

        .payment-method {
            padding: 0 10px;

            flex: 0 0 50%;
            max-width: 50%;

            > .box {
                transition: all .3s ease-in-out;

                cursor: pointer;

                background-color: #FFF;

                border-radius: 10px;
                border: 2px solid $color-purple;

                color: $color-purple;

                font-size: 12px;

                text-align: center;

                padding: 20px 10px;

                > i {
                    font-size: 38px;

                    display: block;

                    margin-bottom: 15px;
                }

                &:hover {
                    background-color: $color-purple;

                    color: #FFF;
                }
            }
        }
    }

    @media screen {
        @media (min-width: 768px) {
            .payment-method-holder {
                max-width: 100%;
            }
        }

        @media (min-width: 1024px) {
            .payment-method-holder {
                flex-wrap: nowrap;

                .payment-method {
                    flex: 0 0 22%;
                    max-width: 22%;
                }
            }
        }
    }
}

.payment-method-money {
    margin-bottom: 20px;
}