.field-holder-date {
    .SingleDatePicker {
        display: block;
        position: relative;

        .SingleDatePickerInput {
            display: block;
            border: none;

            .DateInput {
                width: 100%;

                .DateInput_input {
                    box-sizing: border-box;
                    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16), 0 1px 2px -1px rgba(130, 136, 148, 0.48);

                    border-radius: 8px;

                    background-color: #fff;
                    color: #666;

                    padding: 10px 12px;

                    font-size: 14px;
                    font-weight: 400;

                    line-height: 22px;

                    &::placeholder {
                        color: rgba(153, 153, 153, 0.5);
                    }

                    &.DateInput_input__focused {
                        border-bottom-color: $color-purple;
                    }
                }
            }

            .SingleDatePickerInput_clearDate {
                width: 28px;
                height: 28px;

                padding: 0;

                text-align: center;

                .SingleDatePickerInput_clearDate_svg {
                    width: 10px;
                    height: 9px;
                }
            }

            &.SingleDatePickerInput__showClearDate {
                padding-right: 0;
            }
        }

        .SingleDatePicker_picker {
            .DayPickerNavigation {
                .DayPickerNavigation_button {
                    border-radius: 10px;

                    outline: none;

                    svg {
                        fill: $color-purple;
                    }
                }
            }

            .CalendarMonth {
                .CalendarMonth_table {
                    .CalendarDay {
                        transition: all .3s ease-in-out;

                        &.CalendarDay__selected {
                            background: $color-purple;
                            color: #FFF;

                            border-color: $color-purple;
                        }
                    }
                }
            }
        }
    }

    .DateRangePicker {
        display: block;

        .DateRangePickerInput {
            display: block;
            border: none;

            .DateInput {
                width: 45%;

                .DateInput_input {
                    box-sizing: border-box;
                    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16), 0 1px 2px -1px rgba(130, 136, 148, 0.48);

                    border-radius: 8px;

                    background-color: #fff;
                    color: #666;

                    padding: 10px 12px;

                    font-size: 14px;
                    font-weight: 400;

                    line-height: 22px;

                    &::placeholder {
                        color: rgba(153, 153, 153, 0.5);
                    }

                    &.DateInput_input__focused {
                        border-bottom-color: $color-purple;
                    }
                }
            }

            .DateRangePickerInput_arrow {
                width: 10%;
                text-align: center;

                .DateRangePickerInput_arrow_svg {
                    fill: #666;
                    margin-right: 1px;
                }
            }

            .DateRangePickerInput_clearDates {
                width: 28px;
                height: 28px;

                padding: 0;

                text-align: center;

                .DateRangePickerInput_clearDates_svg {
                    width: 10px;
                    height: 9px;
                }
            }

            &.DateRangePickerInput__showClearDates {
                padding-right: 0;
            }
        }

        .DateRangePicker_picker {
            .DayPickerNavigation {
                .DayPickerNavigation_button {
                    border-radius: 10px;

                    outline: none;

                    svg {
                        fill: $color-purple;
                    }
                }
            }

            .CalendarMonth {
                .CalendarMonth_table {
                    .CalendarDay {
                        transition: all .3s ease-in-out;

                        &.CalendarDay__selected {
                            background: $color-purple;
                            color: #FFF;

                            border-color: $color-purple;
                        }

                        &.CalendarDay__selected_span {
                            background: $color-purple-opacity;
                            color: #FFF;

                            border-color: $color-purple-opacity;
                        }
                    }
                }
            }
        }
    }

    &.field-holder-label-inline {
        display: flex;
        align-items: center;

        label {
            margin: 0 10px 0 0;
        }

        .SingleDatePicker {
            flex: 1;
        }
    }

    .clearDate {
        position: absolute;
        top: 60%;
        right: 12px;
        transform: translateY(-60%);
        cursor: pointer;
    }


    .react-datepicker-wrapper {
        display: block;
        border: none
    }

    .react-datepicker__input-container {
        width: 100%
    }

    .DatePicker {
        box-sizing: border-box;
        box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16), 0 1px 2px -1px rgba(130, 136, 148, 0.48);

        border-radius: 8px;

        background-color: #fff;
        color: #666;

        padding: 10px 12px;

        font-size: 14px;
        font-weight: 400;

        line-height: 22px;
        width: 100%;
        border: 1px solid #fff;


        &::placeholder {
            color: rgba(153, 153, 153, 0.5);
        }

        &.react-datepicker-ignore-onclickoutside {
            border-bottom-color: $color-purple;
        }
    }

}
