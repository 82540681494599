.medical-record {
  padding-bottom: 16px;

  margin-bottom: 16px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 2px solid #E5E5E5;

  p {
    font-size: 14px;
    font-family: $font-display;
    font-weight: 400;

    text-decoration: underline;

    color: $color-gray-text;

    margin-bottom: 0;
  }
}