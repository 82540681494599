@keyframes animationFrames {
	0% {
		opacity: 0;
		transform: translate(-1500px, 0px);
	}
	60% {
		opacity: 1;
		transform: translate(30px, 0px);
	}
	80% {
		transform: translate(-10px, 0px);
	}
	100% {
		opacity: 1;
		transform: translate(0px, 0px);
	}
}

.box-message {
	border: 1px solid;
	border-left: 6px solid;
	display: block;
	padding: 15px;
	transition: all 300ms ease;
	margin: 15px 0;
	width: 100%;
	//Animation
	animation: animationFrames ease 500ms backwards;
	animation-iteration-count: 1;
	transform-origin: 50% 50%;
	animation-fill-mode: forwards;

	&.message-success {
		border-color: $color-success;
	}

	&.message-wait {
		border-color: $color-yellow;
	}

	&.message-error {
		border-color: red;
	}
}