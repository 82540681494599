.table-content {
  width: 100%;
  border-collapse: collapse;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 24px;
  &.no-border-table {
    border-radius: 3px;
  }
  thead {
    tr {
      background: #f5f5f5;
    }
    th {
      font-family: $font-display;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      color: $color-purple;
      text-transform: uppercase;
      padding: 9px 15px;
      text-align: left;
      min-width: 100px;

      &.checkbox {
        min-width: initial;
      }

      &.text-center {
        text-align: center;
      }

      &:first-child {
        border-top-left-radius: 30px;
      }
      &:last-child {
        border-top-right-radius: 30px;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        td {
          background-color: #f5f5f5;
          min-width: 100px;
        }
      }
    }

    td {
      background-color: #fff;
      padding: 20px 15px;
      color: $color-gray-text;
      font-size: 14px;
      line-height: 19px;

      img {
        height: 45px;
        width: 45px;
        margin: 0 auto;
        display: block;
      }

      img.move-dots {
        width: 14px !important;
        height: 24px !important;
        margin: 0 auto !important;
        object-fit: contain;
        cursor: pointer;
      }

      .edit-btn {
        border-radius: 30px;
      }

      &.checkbox {
        min-width: initial !important;
      }

      &.table-budget {
        display: flex;
        justify-content: flex-end;
        align-items: stretch;
        gap: 1rem;

        button,
        a,
        .button {
          padding: 9px 24px;
          border-radius: 30px;
          font-size: 0.875rem;
          line-height: 1.375rem;
        }
      }

      &.table-action {
        display: flex;
        justify-content: flex-end;

        &.regionTable {
          display: table-cell !important;
        }

        ._action {
          padding: 3px 7px;

          font-size: 12px;

          border-radius: 4px;

          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            flex: 0 0 77px;
          }
        }
      }
      &.table-action-order {
        ._action {
          padding: 3px 7px;

          font-size: 12px;

          border-radius: 4px;

          margin-left: 10px;

          &:first-child {
            margin-left: 0;
          }

          &:last-child {
            flex: 0 0 77px;
          }
        }
      }
    }

    .invoice-green {
      border-left: 15px solid #6bc895;
    }
    .invoice-red {
      border-left: 15px solid #d16f6c;
    }
  }

  .action-buttons {
    text-align: right;

    > .button {
      &:last-child {
        margin-left: 15px;
      }
    }
  }

  .invoice-buttons {
    > .button {
      border: 1px solid #999999;
      border-radius: 0px;
      background: #ffffff;
      color: #666666;

      &:hover {
        background: #ab92bf;
        color: #fafafa;
      }

      &:last-child {
        margin-left: 15px;
      }
    }
  }
}
