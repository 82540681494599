.breadcrumbs {
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;

    a {
      font-family: $font-display;
      font-size: 12px;
      color: #ab92bf;
      text-decoration: none;
      -webkit-font-smoothing: antialiased;
      text-transform: uppercase;
      padding: 0;
      position: relative;
      font-weight: 400;
      line-height: 22px;

      &:after {
        content: "/";
        position: relative;
        top: 0;
        font-size: 10px;
        line-height: 10px;
        transform: scaleY(1.4) scaleX(0.8);
        margin: 0 10px;
        display: inline-block;
      }
    }

    &.current {
      a {
        font-weight: 600;
        color: #572580;
        text-decoration: underline;
        text-underline-position: under;
      }
    }

    &:last-child {
      a {
        &:after {
          content: none;
        }
      }
    }
  }
}
