.multiple-list-wrapper {
    .multiple-list-item {
        border-bottom: 1px dashed #E5E5E5;
    
        margin: 0 -20px 24px;
        padding: 0 20px;

        &:last-child {
            border-bottom: 1px solid #CCC;
        }
    }
}