.glide{
  
  &.slider-dates-home-collect{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    transition: opacity 300ms ease-in-out;
    
    &.-hidden{
      opacity: 0.75;

      .card{
        cursor: not-allowed;
      }
    }

    .glide__slides{
      
      .each-slide{
        display: flex;
        flex-wrap: wrap;
        min-height: 154px;
        padding: 0;
        min-width: 700px;
        width: 700px;


        @media (max-width: 1250px){
          width: 100%;
          min-width: 640px;
          max-width: 640px;
          justify-content: center;
        }


        @media (min-width: 1401px) and (max-width: 1600px) {
          justify-content: center;
          width: 100%;
          min-width: 700px;
          max-width: 700px;
        }


        @media (min-width: 1841px) and (max-width: 1920px) {
          justify-content: center;
          width: 100%;
          min-width: 960px;
          max-width: 960px;
        }

        @media (min-width: 1601px) and (max-width: 1840px) {
          justify-content: center;
          width: 100%;
          min-width: 700px;
          max-width: 700px;
        }



        @media (min-width: 1251px) and (max-width: 1400px) {
          justify-content: center;
          width: 100%;
          min-width: 640px;
          max-width: 640px;
        }

        @media (min-width: 1921px) and (max-width: 2100px) { // OK
          justify-content: center;
          width: 100%;
          min-width: 1020px;
          max-width: 1020px;
        }
        
        @media (min-width: 2101px) { // OK
          justify-content: center;
          width: 100%;
          min-width: 1200px;
          max-width: 1200px;
        }

        @media (min-width: 1261px) and  (max-width: 1361px) {
          justify-content: center;
          width: 100%;
          min-width: 510px;
          max-width: 510px;
        }

      }
    }

    .glide__arrows{
      position: static;

      .glide__arrow{
        background-color: $color-purple;
        border-radius: 100%;
        border: none;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        position: static;
        padding: 0;
        box-shadow: none;
        text-shadow: none;
        transform: initial;

        i {
          font-size: 18px;
        }
      }
    }
  }

  &.slider-hour-home-collect{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    transition: opacity 300ms ease-in-out;

    &.-hidden{
      opacity: 0.75;

      .card{
        cursor: not-allowed;
      }
    }

    .glide__slides{
      
      .each-slide{
        display: flex;
        flex-wrap: wrap;
        min-height: 154px;
        min-width: 700px;
        width: 700px;
        padding: 0;


        @media (max-width: 1250px){
          width: 100%;
          min-width: 640px;
          max-width: 640px;
          justify-content: center;
        }


        @media (min-width: 1401px) and (max-width: 1600px) {
          justify-content: center;
          width: 100%;
          min-width: 700px;
          max-width: 700px;
        }

        @media (min-width: 1601px) and (max-width: 1840px) {
          justify-content: center;
          width: 100%;
          min-width: 710px;
          max-width: 710px;
        }

        @media (min-width: 1841px) and (max-width: 1920px) {
          justify-content: center;
          width: 100%;
          min-width: 980px;
          max-width: 980px;
        }

        @media (min-width: 1251px) and (max-width: 1400px) {
          width: 100%;
          min-width: 640px;
          max-width: 640px;
        }

        @media (min-width: 1921px) and (max-width: 2400px) { // OK
          justify-content: center;
        }

        
        @media (min-width: 1261px) and  (max-width: 1361px) {
          justify-content: center;
          width: 100%;
          min-width: 530px;
          max-width: 530px;
        }


      }

      @media (max-width: 1200px) {
        .each-slide{
          min-width: 260px;
        }
      }
    }

    .glide__arrows{
      position: static;

      .glide__arrow{
        background-color: $color-purple;
        border-radius: 100%;
        border: none;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        position: static;
        padding: 0;
        box-shadow: none;
        text-shadow: none;
        transform: initial;

        i {
          font-size: 18px;
        }
      }
    }
  }

  @media screen {
    @media (min-width: 425px) {
      &.slider-dates-home-collect{
        max-width: 345px;
    
        .glide__arrows{
          .glide__arrow{
            &.glide__arrow--prev{
              margin-right: 10px;
            }
            &.glide__arrow--next{
              position: absolute;
            }
          }
        }
      }
      &.slider-hour-home-collect{
        max-width: 345px;
    
        .glide__arrows{
          .glide__arrow{
            &.glide__arrow--prev{
              margin-right: 10px;
            }
            &.glide__arrow--next{
              position: absolute;
            }
          }
        }
      }
    }

    @media (min-width: $screen-xs) {
      &.slider-dates-home-collect{
        max-width: 485px;
      }
      &.slider-hour-home-collect{
        max-width: 485px;
      }
    }

    @media (min-width: $screen-sm) {
      &.slider-dates-home-collect{
        margin: 0;
      }
      &.slider-hour-home-collect{
        margin: 0;
      }
    }

    @media (min-width: 1185px) {
      &.slider-dates-home-collect{
        max-width: 680px;

        .glide__arrows{
          .glide__arrow{
            &.glide__arrow--prev{
              margin-right: 5px;
            }
          }
        }
      }

      &.slider-hour-home-collect{
        max-width: 680px;

        .glide__arrows{
          .glide__arrow{
            &.glide__arrow--prev{
              margin-right: 5px;
            }
          }
        }
      }
    }

    @media (min-width: $screen-lg) {
      &.slider-dates-home-collect{
        margin-left: -30px;
        max-width: 435px;

        .glide__slides{
          .each-slide{
            width: 560px
          }
        }

        .glide__arrows{
          .glide__arrow{
            &.glide__arrow--prev{
              margin-right: 10px;
            }
          }
        }
      }

      &.slider-hour-home-collect{
        margin-left: -30px;
        max-width: 435px;

        .glide__slides{
          .each-slide{
            width: 560px
          }
        }

        .glide__arrows{
          .glide__arrow{
            &.glide__arrow--prev{
              margin-right: 10px;
            }
          }
        }
      }
    }

    @media (min-width: $screen-lg) {
      &.slider-dates-home-collect{
        margin-left: -30px;
        max-width: 100%;
      }

      &.slider-hour-home-collect{
        margin-left: -30px;
        max-width: 100%;
      }
    }

    @media (min-width: 1250px) and (max-width: 1400px) {
      &.slider-dates-home-collect{
        max-width: 680px;
  
        .glide__arrows{
          .glide__arrow{
            &.glide__arrow--prev{
              margin-right: 5px;
            }
          }
        }
      }
  
      &.slider-hour-home-collect{
        max-width: 670px;
  
        .glide__arrows{
          .glide__arrow{
            &.glide__arrow--prev{
              margin-right: 5px;
            }
          }
        }
      }
    }
  }


  &.slider-dates-sm {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 600px;
    width: 100%;
    transition: opacity 300ms ease-in-out;
  
    &.-hidden {
      opacity: 0.75;
  
      .card {
        cursor: not-allowed;
      }
    }
  
    .glide__slides {
      .each-slide {
        display: flex;
        flex-wrap: wrap;
        min-height: 154px;
        padding: 0;
        min-width: 350px;
        width: 350px;
        max-height: 350px;
        justify-content: center;
      }
    }
  
    .glide__arrows {
      position: static;
  
      .glide__arrow {
        background-color: $color-purple;
        border-radius: 100%;
        border: none;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 24px;
        width: 24px;
        position: static;
        padding: 0;
        box-shadow: none;
        text-shadow: none;
        transform: initial;
  
        i {
          font-size: 18px;
        }
      }
    }
  }
  
  @media screen {
    @media (min-width: 425px) {
      &.slider-dates-sm {
        max-width: 345px;
  
        .glide__arrows {
          .glide__arrow {
            &.glide__arrow--prev {
              margin-right: 10px;
            }
            &.glide__arrow--next {
              margin-left: 10px;
            }
          }
        }
      }
    }
  
    @media (min-width: $screen-xs) {
      &.slider-dates-sm {
        max-width: 485px;
      }
    }
  
    @media (min-width: $screen-sm) {
      &.slider-dates-sm {
        margin: 0;
      }
    }
  
    @media (min-width: 1185px) {
      &.slider-dates-sm {
        max-width: 552px;
  
        .glide__arrows {
          .glide__arrow {
            &.glide__arrow--prev {
              margin-right: 5px;
            }
          }
        }
      }
    }
  
    @media (min-width: $screen-lg) {
      &.slider-dates-sm {
        margin-left: -30px;
        max-width: 435px;
  
        .glide__slides {
          .each-slide {
            min-height: 174px;
          }
        }
  
        .glide__arrows {
          .glide__arrow {
            &.glide__arrow--prev {
              margin-right: 10px;
            }
          }
        }
      }
    }
  
    @media (min-width: $screen-lg) {
      &.slider-dates-sm {
        margin-left: -30px;
        max-width: 560px;
      }
    }
  }
  
}

.bullets{
  &.slider-dates-home-collect{
    text-align: center;
    width: 100%;
  }

  &.slider-hour-home-collect{
    text-align: center;
    width: 100%;


    .glide__bullets {
      display: flex;
      flex-wrap: wrap; 
      justify-content: center; 

      .glide__bullet {
        background-color: $color-lgray-text;
        border: none;
        width: 9px;
        height: 9px;
        border-radius: 50%;
        transition: all 300ms ease-in-out;
        cursor: pointer;
        margin: 0 0.25em;
        margin-top: 10px;
      }
    }
  }

  @media screen {
    @media (min-width: $screen-sm){
      max-width: 100%;
    }

    @media (min-width: $screen-lg) {
      margin-left: -30px;
      max-width: 100%
    }
  }
}

.glide__bullets {
  &.calendar-slider{
    position: static;
    transform: none;
    z-index: 2;    

    .glide__bullet{
      background-color: $color-lgray-text;
      border: none;

      &:not(.glide__bullet--active){
        opacity: 0.2;
        &:hover{
          opacity: 0.5;
        }
      }

      &:hover{
        opacity: 0.7;
      }
    }
    
    @media screen {
      @media (min-width: 1185px){
        margin-left: 60px;
      }
  
      @media (min-width: 1280px){
        margin-left: 0;
      }
    }
  }




}