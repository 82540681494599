.order-home-list {
  .order-home-filter {
    margin-top: 1rem;

    .filters-order {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 1rem;
    }

    .select-search-submit-types {
      width: 145px;

      &.width-2 {
        width: 250px;
      }
    }

    .action-send-motion {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      button {
        margin-left: 0.5rem;
      }
    }

    button {
      padding-top: 10px;
      padding-bottom: 10px;
      border-radius: 3px;
    }

    label {
      font-family: $font-display;
      font-weight: 600;
      font-size: 14px;
      color: $color-purple !important;
      margin: 8px 0px 8px;
    }

    input,
    select,
    .input,
    .react-select__single-value,
    .react-select__control {
      border-radius: 3px !important;
      font-family: $font-display;
      color: $color-gray-text;
      font-size: 13px;
      line-height: 20px;
    }
  }
  .orders-tabs {
    a {
      margin-right: 3px;
      background-color: #f0f0f0;
    }
  }
}

.order-list-upload-area {
  width: 400px;
  display: flex;
  .upload-area {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    margin: 0;
    padding: 0;
  }
  .dropzoneInsurance-area {
    height: 42px;
    border-radius: 3px;
    padding: 0;
    padding: 0 10px;
    font-family: $font-display;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    span {
      margin: 0;
    }
    .upload-area-info {
      color: $color-gray-text;
    }
    font-weight: 500;
    img {
      height: 25px;
      width: 25px;
    }
  }

  .actions {
    padding: 0;
    span:first-child {
      display: none;
    }
  }
  .file-info {
    border: none;
    padding: 0;
    margin-top: 0.3rem;
    .file-upload {
      padding: 0;
    }
  }
}

.erros-order-home {
  width: 100%;
  background-color: #572580;
  padding: 10px;
  border-radius: 3px;
  margin: 1rem 0;
  cursor: pointer;
  p {
    margin: 0;
    padding: 0;
    text-align: center;
    font-family: $font-display;
    font-weight: 500;
    color: #f5f5f5;
  }

  strong {
    text-decoration: underline;
    font-family: $font-display;
    color: #f5f5f5;
    cursor: pointer;
  }
}
