.dropzone-area {
  background-color: #fff;
  border: 2px dashed #75459c4d;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 16px;

  padding: 10px;

  cursor: pointer;

  transition: all 0.3s ease-in-out;

  > .icon {
    opacity: 0.3;
    height: 40px;
    width: 40px;
  }

  > .input-file {
    display: none;
  }

  > span {
    display: block;

    font-size: 12px;

    text-transform: uppercase;

    margin: 5px 0 0 20px;

    color: #333;
  }

  &.--disabled {
    cursor: not-allowed;
  }

  &.--highlight,
  &:hover {
    background-color: #75459c4d;
    border-style: solid;
  }

  &.--invalid {
    background-color: #c23f3b;

    border-style: solid;
    border-color: transparent;

    > span {
      color: #fff;
    }
  }
}

.dropzoneInsurance-area {
  background-color: #fff;
  border: 2px dashed #75459c4d;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 16px;

  padding: 53px;

  cursor: pointer;

  transition: all 0.3s ease-in-out;

  > .icon {
    opacity: 0.3;
    height: 40px;
    width: 40px;
  }

  > .input-file {
    display: none;
  }

  > span {
    display: block;

    font-size: 12px;

    text-transform: uppercase;

    margin: 5px 0 0 20px;

    color: #333;
  }

  &.--disabled {
    cursor: not-allowed;
  }

  &.--highlight,
  &:hover {
    background-color: #75459c4d;
    border-style: solid;
  }

  &.--invalid {
    background-color: #c23f3b;

    border-style: solid;
    border-color: transparent;

    > span {
      color: #fff;
    }
  }

  &.border-none {
    border: none;
    background: transparent;
    padding: 2px;
  }
}

.fixWidth {
  margin: auto;
  margin-top: 0.7rem;
}
