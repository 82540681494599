.content-results-details {
  padding-top: 30px;

  .results-infos {
    margin-bottom: 32px;

    .pacient {
      margin-bottom: 0;
    }

    .unity {
      margin-bottom: 24px;
    }
  }

  .results-list {
    overflow: auto;

    margin-bottom: 32px;

    table {
      border-collapse: collapse;

      width: 100%;

      min-width: 687px;

      thead {
        th {
          padding: 10px 0;

          color: $color-purple;

          text-align: left;

          font-family: $font-display;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
        }
      }

      tbody {
        td {
          font-size: 14px;
          line-height: 18px;

          border-bottom: 1px solid rgba(153, 153, 153, 0.5);

          padding: 12px 0;

          color: $color-lgray-text;

          &.subitem {
            padding-left: 20px;
          }
        }

        tr {
          &:last-child {
            td {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .results-actions {
    text-align: center;
  }
}