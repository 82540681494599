.progress-wrapper {
    > i {
        opacity: 0;
        
        transition: opacity .3s ease-in-out;
    }

    &.--success {
        > i {
            opacity: 0.5;
        }
    }
}

.progress-bar {
    width: 100%;

    height: 8px;

    background-color: rgb(183, 155, 229);

    .progress {
        background-color: rgba(103, 58, 183, 1);

        height: 100%;

        margin: 0;

        transition: width .6s ease-in-out;
    }
}