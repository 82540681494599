.react-select {
  padding: 0;

  .react-select__control {
    border: none;
    padding: 4px 0;

    .react-select__value-container {
      .react-select__multi-value {
        background-color: #75459c4d;

        .react-select__multi-value__label {
          color: #333;
        }

        .react-select__multi-value__remove {
          cursor: pointer;
          background-color: transparent !important;
        }
      }
    }
  }

  .react-select__indicators {
    .clear-indicator {
      font-size: 10px;
    }

    .react-select__dropdown-indicator {
      svg {
        width: 10px;
        height: 10px;
      }
    }
  }
}

.Select-menu-outer {
  z-index: 999 !important;
}
