.button {
  color: white;
  background: $color-purple;
  border-radius: 8px;
  padding: 6px 25px;
  display: inline-block;
  text-transform: uppercase;
  text-decoration: none;
  font-family: $font-display;
  border: 1px solid $color-purple;
  line-height: 22px;
  box-sizing: border-box;
  font-size: 14px;
  text-align: center;
  transition: all 0.33s ease-in-out;
  cursor: pointer;
  outline: none;

  &._transparentButton {
    font-weight: 600;
    background: transparent !important;
    border: none !important;

    &:hover {
      color: $color-purple !important;
      opacity: 0.8;
    }

    &._alt {
      color: $color-purple !important;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  &._transparentBorderButton {
    font-weight: 600;
    background: transparent !important;
    border: 1px solid $color-purple;

    &:hover {
      color: white !important;
      background-color: $color-purple !important;
    }
  }

  &.transparent-btn {
    font-weight: 500;
    background: white !important;
    border: 1px solid $color-purple !important;
    border-radius: 30px !important;
    color: $color-purple !important;

    &:hover {
      background: $color-purple !important;
      color: white !important;
      opacity: 0.8;
    }
  }

  &.noBold {
    font-weight: normal !important;
  }

  &._loading {
    position: relative;
    padding: 0;
    width: auto;
    pointer-events: none;
    opacity: 0.6;

    > .gif {
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0;
      left: 0;
      margin: auto;
      display: block;
      width: 36px;
      height: 36px;
    }
  }

  &.button-loading {
    height: 46px;
    position: relative;
    padding: 0;
    pointer-events: none;
    opacity: 0.6;
    width: 203px;

    > .gif {
      position: absolute;
      top: 0px;
      bottom: 0px;
      right: 0;
      left: 0;
      margin: auto;
      display: block;
      width: 36px;
      height: 36px;
    }
  }

  &:hover {
    background: white;
    color: $color-purple;
  }

  &._block {
    width: 100%;
  }

  &._xsmall {
    padding: 4px 8px;
    border-radius: 4px;

    font-size: 12px;
  }

  &._xsm {
    padding: 4px 40px;

    line-height: 16px;

    font-size: 12px;
  }

  &._large {
    padding: 10px 30px;
  }

  &._round {
    border-radius: 50px;
  }

  &._only-icon {
    padding: 2px 6px;
    border-radius: 4px;

    i {
      font-size: 14px;
    }
  }

  &._action {
    padding: 11px 25px;
    &._table {
      padding: 3px 7px;
      font-size: 12px;
      border-radius: 4px;
    }
  }

  &._alt {
    background: $color-purple;
    color: $color-yellow;
    border-color: $color-purple;

    &:hover {
      background: $color-yellow;
      color: $color-purple;
    }
  }

  &._yellow {
    background: $color-yellow;
    color: $color-purple;
    border-color: $color-yellow;

    &:hover {
      background: $color-purple;
      border-color: $color-purple;
      color: $color-yellow;
    }
  }

  &._danger {
    color: white;
    border-color: $color-danger;
    background: $color-danger;

    &:hover {
      background: white;
      color: $color-danger;
    }
  }

  &._white {
    background-color: white;
    color: $color-purple;

    &:hover {
      background-color: $color-purple;
      color: white;
    }
  }

  &._purple {
    background-color: $color-purple;
    color: white;

    &:hover {
      background-color: $color-purple;
      color: white;
    }
  }

  &._blue {
    background-color: #21009a;
    color: white;
    border: 1px solid #21009a;

    &:hover {
      background-color: white;
      color: #21009a;
    }
  }

  &._gray {
    border: 1px solid transparent;
    background-color: $color-ltgray;
    color: #999;

    &:hover {
      color: white !important;
      background-color: $color-lgray-text;
    }
  }

  &._buttonDisabled {
    background-color: #25c150;
    border: transparent;
    color: white;
  }

  &._button-font-text {
    font-family: $font-text;
  }

  &._disabled,
  &[disabled=""] {
    background-color: #ab92bf;
    color: white;
    border: transparent;
  }

  &._disabled-purple,
  &[disabled=""] {
    color: white;
    cursor: not-allowed;

    i {
      color: $color-purple;
    }
  }

  &._pagination {
    padding: 7px;

    min-width: 48px;
    min-height: 48px;
    border-radius: 50%;

    background-color: white;
    border-color: $color-purple;
    color: $color-purple;

    font-size: 1.25rem;
    text-align: center;
    line-height: 1.75rem;

    &:hover {
      border-color: $color-purple;
      color: $color-purple;
    }
  }

  &._transparent {
    background-color: transparent !important;
  }

  &._icon-add {
    .represent-icon-holder {
      border: 1px solid #572580;
      color: #572580;
      font-size: 16px;
      text-align: center;
      width: 18px;
      height: 18px;
      display: inline-block;
      line-height: 1em;
      border-radius: 50%;
      margin-right: 10px;
      position: relative;
      top: -1px;
    }
    &:hover {
      .represent-icon-holder {
        background-color: #fff;
      }
    }
  }

  &.pre-register {
    width: 222px;
    height: 45px;
    border-radius: 30px;
    margin-top: 22px;
  }

  &.radiusButton {
    border-radius: 30px;
    padding: 13px 36px;
  }

  &.radiusButtonBorder {
    border-radius: 30px !important;
  }

  &.marginTop-7 {
    margin-top: 7px;
  }
}

.status-button {
  align-items: center;
  justify-content: center;
  display: inline-flex;

  p {
    font-size: 15px;
    color: $color-purple;
    margin-right: 20px;
    margin-bottom: 0;
  }
  img {
    width: 40px;
  }
}
