.panel {
  box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
    0 8px 10px -4px rgba(130, 136, 148, 0.48);
  border-radius: 16px;
  margin-bottom: 32px;
  overflow: hidden;

  .panel {
    box-shadow: 0px 1px 7px rgba(0, 0, 0, 0.2);
  }

  .panel-heading {
    background-color: #f5f5f5;
    padding: 14px 20px;
    transition: all 0.3s ease-in-out;
    display: flex;
    align-items: center;

    position: relative;

    h5 {
      font-size: 14px;
      color: $color-purple;
      line-height: 22px;
      font-weight: 500;
      text-transform: uppercase;
      margin: 0;
      transition: all 0.3s ease-in-out;
    }

    .arrow-down {
      opacity: 1;

      transition: opacity 0.3s linear, transform 0.3s ease-in-out;

      border: solid $color-purple;
      border-width: 0 2px 2px 0;

      display: inline-block;

      height: 0px;

      padding: 5px;
      margin: auto;

      transform: rotate(45deg);

      position: absolute;
      right: 20px;
      top: 0;
      bottom: 2px;
    }
  }

  .panel-body {
    padding: 24px 20px 5px;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    .field-holder {
      margin-bottom: 20px;
    }

    &.open {
      display: block;
    }

    &.close {
      display: none;
    }
  }

  &.panel-opened {
    .panel-heading {
      .arrow-down {
        transform: rotate(-135deg);
      }
    }
  }

  &.panel-loading {
    pointer-events: none;

    .panel-heading {
      position: relative;

      &:after {
        content: "";
        width: 30px;
        height: 30px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("~Assets/Images/loading-purple.gif");
        display: inline-block;
        position: absolute;
        top: 10px;
        right: 20px;
      }

      .arrow-down {
        opacity: 0;
      }
    }

    .panel-body {
      opacity: 0.5;
    }
  }

  &.panel-error {
    box-shadow: 0 0 0 1px rgba(209, 111, 108, 0.16),
      0 8px 10px -4px rgba(209, 111, 108, 0.48);

    .panel-heading {
      justify-content: space-between;
      background-color: #d16f6c;

      h5 {
        color: #fff;
      }
    }
  }

  &.panel-visible {
    overflow: visible;
  }
}
