.modal-courtesy {
  background: white;
  padding: 48px 48px 32px;
  border-radius: 24px;

  form {
    h5 {
      font-family: "Saira", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 40px;
      /* identical to box height, or 143% */

      text-align: center;

      color: #572580;
    }

    p {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #979797;
    }

    label {
      font-size: 12px !important;
      color: #979797;
    }

    .courtesy-reason {
      margin-top: 32px;
    }

    .actions-buttons-courtesy {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      gap: 16px;

      button {
        border-radius: 30px;
        padding: 13px 38px;
        font-family: "Saira", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  .no-courtesy-products {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      margin-bottom: 32px;
      width: 68px;
      height: 62px;
    }
    p {
      margin-bottom: 32px;
    }

    button {
      border-radius: 30px;
      padding: 13px 38px;
      font-family: "Saira", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 22px;
      cursor: pointer;
    }
  }
}
