.a-rnds{
    text-decoration: inherit;
    font-weight: bold;
    cursor: pointer;
    color: $color-purple;
}

.text-content{
    p{
        line-height: 1.8;
        font-size: 1rem !important;
    }
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.center-text{
    line-height: inherit  !important;
}