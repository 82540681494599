.exams-list-included {
    
    .tags-holder {
        overflow: auto;

        display: flex;
        align-items: center;
        flex-wrap: wrap;

        list-style: none;

        padding: 0;

        li {
            background-color: #75459c4d;
            
            border-radius: 2px;

            margin: 2px;

            min-width: 0;

            color: #555;

            display: flex;
            align-items: center;

            padding: 3px 0;

            > .tag-content {
                color: #333;

                font-size: 12px;

                overflow: hidden;

                padding: 3px;
                padding-left: 6px;

                text-overflow: ellipsis;
                white-space: nowrap;
            }

            > .icon-close {
                font-size: 8px;

                cursor: pointer;

                padding: 8px 10px 8px 4px;
            }
        }
    }
}