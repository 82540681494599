.panel-switch {
    .panel-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .field-holder {
            margin-bottom: 0;
        }

        .switch-holder {
            height: 25px;
        }
    }
}