.field-holder {
  position: relative;

  .error-message {
    font-size: 10px;
    line-height: 14px;
    text-align: right;
    color: $color-danger;
  }

  label {
    min-height: 22px;

    display: flex;
    align-items: center;
  }

  .checkbox-holder {
    height: 44px;
    font-size: 10px;
  }

  .select-field-holder {
    position: relative;

    .arrow-icon {
      transform: scaleY(0.65) scaleX(1.3) rotate(90deg);
      transition: transform 0.3s ease-in-out;
      display: inline-block;
      font-size: 10px;
      color: #666;
      margin-left: 13px;
      position: absolute;
      top: 16px;
      right: 10px;
      pointer-events: none;
    }
  }

  // Modifiers
  &.field-holder-invalid {
    &.checkbox-field {
      .error-message {
        position: relative;
        right: 0;
      }
    }

    .error-message {
      position: absolute;
      right: 8px;

      &.error-without-label {
        bottom: 49px;
      }
    }

    .input {
      border-color: $color-danger;
      background-color: rgba(224, 159, 157, 0.25);

      .react-select__control {
        background-color: rgba(224, 159, 157, 0.25);

        .react-select__indicator-separator {
          background-color: #fff;
        }

        .react-select__indicator {
          svg {
            fill: #fff;
          }
        }
      }
    }

    .DatePicker {
      border-color: $color-danger;
      background-color: rgba(224, 159, 157, 0.25);
    }
  }

  &.field-checkbox-holder {
    .checkbox-holder {
      display: flex;
      align-items: center;
      position: relative;
      padding-left: 30px;
      white-space: normal;
      overflow: visible;
      text-overflow: unset;

      line-height: 16px;

      .box-check {
        display: inline-block;
        width: 18px;
        height: 18px;
        border: 1px solid $color-purple;
        position: absolute;
        left: 0;

        &:before {
          content: "";
          display: inline-block;
          width: 14px;
          height: 14px;
          background-color: $color-purple;
          position: relative;
          top: 2px;
          left: 2px;
          transform: scale(0);
          opacity: 0;
          transition: all 0.3s ease-in-out;
        }
      }

      input {
        display: none !important;

        &:checked+.box-check {
          &:before {
            transform: scale(1);
            opacity: 1;
          }
        }
      }
    }

    &.field-holder-block {
      >.text-gray {
        display: none;
      }
    }
  }

  &.field-holder-small {
    .checkbox-holder {
      height: 38px;
    }

    label {
      font-size: 10px;
      margin-bottom: 0;
    }

    input,
    select {
      font-size: 12px;
      line-height: 16px;
    }

    .switch-holder {
      height: 38px;
    }

    .select-field-holder {
      .arrow-icon {
        top: 12px;
      }
    }
  }

  &.field-holder-read {
    display: flex;
    flex-direction: column;

    .label-field {
      margin-bottom: 5px;
    }
  }

  &.field-holder-inline {
    flex-direction: row;
    align-items: center;

    .label-field {
      margin-bottom: 0px;
    }

    .value-field {
      margin-left: 15px;
    }
  }
}

.react-select__menu {
  display: block !important;
  position: absolute !important;
  z-index: 99999999999999999999 !important;
}

.checkbox-new-design {
  .checkbox-holder {
    font-family: $font-display;

    font-weight: 500;
    font-size: 12px !important;
    color: $color-purple !important;
  }
}

.select-error {
  border-color: $color-danger;
  background-color: yellow;
}