.insurance-flex-Inputs {
  display: flex;
  margin: 1rem;
  gap: 0.5rem;
}

.insurance-flex {
  display: grid;
  gap: 0.7rem;
  grid-template-columns: repeat(2, 1fr);
}

.buttonInsurance {
  margin-bottom: 5rem;
}

.editImg {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0.8rem;
  height: 142px;
  box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
    0px 4px 6px -2px rgba(130, 136, 148, 0.32);
  border-radius: 24px;

  img {
    max-width: 192px;
    max-height: 96px;
  }

  button {
    margin-bottom: 0px !important;
  }
}

.imgResize {
  td {
    img {
      height: 51px !important;
      width: 59px !important;
      margin: 0 !important;
      object-fit: cover;
    }
  }
}

.tag {
  margin-top: 10px;
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 10px;

  @media screen and (max-width: $screen-sm) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media screen and (max-width: $screen-md) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: $screen-lg) {
    grid-template-columns: repeat(3, 1fr);
  }
}
.tag-item {
  width: 90%;
  background: #75459c4d;
  margin-right: 10px;

  height: 27px;
  border-radius: 2px;

  padding-left: 10px;

  align-items: center;
  display: flex;
  
  p {
    width: 100%;
    margin: 0;

    height: 27px;
    border-radius: 2px;



    align-items: center;
    display: flex;
    p {
      width: 90%;
      color: var(--color-hgray-text);
      font-size: 12px;
      margin: 0;
    }
  }

  .max-wigth {
    max-width: 100%;
  }

  .close-item {
    text-align: center;
    align-items: center;
    display: flex;

    justify-content: center;
    width: 15px;
    height: 15px;
    color: var(--color-purple);
    font-size: 10px;
    border-radius: 10px;

    img {
      padding-right: 10px;
      cursor: pointer;
    }
  }

  .input-tag {
    border: none;
  }

  .input-tag::placeholder {
    color: hsl(0, 0%, 50%);
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
  }

  .input-tag:focus {
    outline: none;
  }
}
