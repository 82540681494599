.service-form-header {
    background-color: $color-purple;

    padding: 10px 15px;

    display: flex;

    flex-wrap: wrap;

    > .left-actions,
    > .right-actions {
        flex: 0 0 100%;
        max-width: 100%;

        text-align: center;
    }

    > .logo-holder {
        margin: 10px 0;

        flex: 0 0 100%;
        max-width: 100%;

        > .logo {
            margin: 0 auto;
        }
    }

    @media screen {
        @media (min-width: 768px) {
            padding: 20px 30px;

            flex-direction: row;
            flex-wrap: nowrap;

            justify-content: space-between;

            position: relative;

            > .left-actions,
            > .right-actions,
            > .logo-holder {
                flex: 1;
                max-width: none;

                text-align: left;
            }

            > .right-actions {
                text-align: right;
            }

            > .logo-holder {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;

                width: 174px;
                height: 30px;

                margin: auto;
            }
        }
    }
}