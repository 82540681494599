.rdw-editor-wrapper{
    border: 1px solid rgba(130, 136, 148, 0.16) ;
    border-radius: 2px;
    padding: 3px 3px 1px;
}

.flexContainerInput{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
}


.rdw-editor-main {
    height: 280px !important;
}

.grid-imgMap{  
    display: flex;
    justify-content: center;
    span {
        img{
            border-radius: .5rem;
            width: 250px;
            height: 150px;
        }
        
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.grid-img{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    span{
        position: relative;
        width: 60px;
        height: 60px;
        
        display: flex;
        justify-content: center;
        align-items: center;
        
        cursor: pointer;
        &::before{
            position: absolute;
            font-family: Arial, sans-serif;
            content: "+";
            transform: rotate(45deg);
            font-weight: 300;
            color: white;
            font-size: 28px;
            z-index: 9999;
            opacity: 0;
        }

        &::after{
            content: "";
            border-radius: 100%;
            width: 60px;
            height: 60px;
            background-color: rgba(0, 0, 0, 0.3);
            z-index: 3;
            position: absolute;
            opacity: 0;
        }

        img{
            border-radius: 50%;
            width: 60px;
            height: 60px;
            object-fit: cover;
        }

        &.delete {
            &::before{
                opacity: 1;
            }
            
            &::after{
                opacity: 1;
            }
        }
    }
    span:hover{
        &::before{
            opacity: 1;
        }
        
        &::after{
            opacity: 1;
        }
    }
}