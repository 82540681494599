.checkup-exams-list {
    border: 1px solid #F5F5F5;
    background-color: #FFF;
    padding: 10px;

    .item-selected {
        padding: 10px;
        border-bottom: 1px solid #CCC;
        display: flex;
        align-items: center;
        justify-content: space-between;

        i {
            font-size: 12px;
            color: $color-danger;
            cursor: pointer;
        }

        &:last-child {
            border-bottom: 0;
        }
    }
}