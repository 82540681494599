.badge-item {
  color: $color-purple;
  border-radius: 30px;
  font-size: $font-size-xs;
  padding: 5px 12px;
  display: inline-block;
  text-transform: uppercase;
  background-color: $color-ltgray;
  font-family: $font-display;
  line-height: 18px;
  margin-right: 10px;
  margin-bottom: 10px;
  
  &.flex-item-badge{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .2rem;
  }
  i {
      float: left;
      display: inline-block;
      color: $color-purple;
      font-size: 18px;
      margin-right: 8px;

      &.exam-icon {
          &:before {
              content: $icon-sangue;
          }
      }

      &.icon-fezes {
          &:before {
              content: $icon-fezes;
          }  
      }
      &.icon-urina {
          &:before {
              content: $icon-urina;
          }
      }
      &.icon-unit {
          &:before {
              content: $icon-unit;
          }
          margin-right: 0px;
      }
      &.icon-truck {
          &:before {
              content: $icon-truck;
          }
          margin-right: 0px;
      }
  }
}

.badges-product {
    display: flex;
    flex-flow: wrap;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 5px;
    min-height: 78px;
}