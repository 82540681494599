.token-area {
  h5 {
    font-size: 20px;
    font-weight: 400;

    margin-bottom: 16px;
    padding: 4px 8px;

    border-bottom: 1px solid #e7e7e7;

    background-color: #e6e6e6;
  }

  .info-personal {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    margin-bottom: 16px;
  }

  .list-item {
    margin-bottom: 15px;
    max-width: 100%;
    flex: 0 0 100%;

    dt {
      margin-bottom: 2px;
      font-family: var(--font-text);
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }

    dd {
      margin-left: 0;
      padding-bottom: 5px;
      margin-bottom: 10px;
      font-family: var(--font-text);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #666666;
    }
  }

  .multiple-list {
    display: flex;
    flex-wrap: wrap;

    width: 100%;

    margin-block-start: 0;
    margin-block-end: 0;

    margin-bottom: 24px;

    &:not(:last-child) {
      border-bottom: 1px solid #eee;
    }

    .list-item {
      width: 33.333333%;
    }
  }

  .multiple-list-covenant {
    display: flex;
    flex-wrap: wrap;

    width: 100%;

    margin-block-start: 0;
    margin-block-end: 0;

    &:last-child {
      border-bottom: 1px solid #eee;
    }

    &:first-child {
      margin-top: 30px;
    }

    .list-item {
      width: 33.333333%;
    }
    .item-documents {
      display: flex;
      flex-direction: row;
      @media (max-width: 1024px) {
        flex-direction: column;
      }
      :first-child {
        margin-right: 24px;
        @media (max-width: 1024px) {
          margin-right: 0;
        }
      }
    }
    &.covenant {
      margin-top: 30px;
    }
  }

  @media screen {
    @media (min-width: 1024px) {
      .list-item {
        max-width: 25%;
        flex: 0 0 25%;
      }
    }
  }
}

.token-person-dashboard {
  .person-dashboard-header {
    display: flex;
    justify-content: space-between;

    margin-top: 1rem;
    margin-bottom: 2rem;

    .person-dashboard-header-buttons {
      .button {
        border-radius: 21px;
      }

      .button:last-child {
        margin-left: 1rem;
      }
    }

    .activity-label-dashboard {
      display: flex;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 200px;
        height: 30px;

        margin-left: 2rem;
        border-radius: 14px;

        background: #f5f5f5;

        color: #572580;
        font-weight: 600;
      }
    }
  }

  .dashboard-main-content {
    display: flex;

    .apexcharts-tooltip {
      color: #fff !important;

      * {
        color: #fff !important;
      }
    }

    .dashboard-sub-title {
      font-family: "Saira", sans-serif;
      font-size: 1.125rem;
    }

    .dashboard-person-data {
      display: flex;
      flex-direction: column;
      width: 50%;

      > span {
        margin-bottom: 5px;
        color: #572580;
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        margin-bottom: 40px;

        border-top: 1px solid #e5e5e5;

        &.general-data-div {
          align-items: flex-start;

          > div {
            align-items: flex-start;
          }
        }

        > div {
          flex: 0 0 60%;
          max-width: 60%;
          margin-top: 1rem;

          &:first-child {
            display: flex;
            align-items: center;

            flex: 0 0 40%;
            max-width: 40%;
          }
        }

        .wrapper-icon {
          font-size: 45px;

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 50%;
          border: 1px solid rgb(51, 51, 51);

          color: rgb(51, 51, 51);

          width: 75px;
          height: 75px;

          margin-right: 20px;
        }

        p {
          margin-bottom: 8px;
          margin-top: 24px;

          font-size: 15px;
          font-weight: 500;

          > strong {
            margin-right: 4px;
          }
        }

        @media screen and (max-width: 936px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }

    .dashboard-labi-credits {
      display: flex;
      flex-direction: column;
      width: 50%;
      border-left: 1px solid #e5e5e5;

      > span {
        padding: 0 0 5px 15px;
        margin-bottom: 5px;
        color: #572580;
        border-bottom: 1px solid #e5e5e5;
      }

      > div.empty {
        display: flex;
        flex-direction: column;

        justify-content: center;
        align-items: center;

        color: #666666;

        img {
          width: 170px;
          height: 156px;
          margin-top: 1rem;
          margin-bottom: 10px;
        }
      }

      .dashboard-credits-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .dashboard-credits-infos {
          display: flex;
          align-items: center;
          justify-content: space-around;
          border-radius: 5px;
          background: #faf38d;

          width: 90%;
          padding: 0.75rem;
          margin: 1rem auto;

          > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            span {
              font-size: 0.75rem;
              font-family: "Saira", sans-serif;
              color: #666;
            }

            p {
              margin: 0.5rem 0 0;
              font-size: 1.125rem;
              font-weight: 600;
              font-family: "Saira", sans-serif;
              color: #815ca0;
            }
          }

          @media screen and (max-width: 656px) {
            flex-direction: column;
            gap: 0.75rem;
            align-items: flex-start;
          }
        }

        .dashboard-credits-togo {
          display: flex;
          align-items: center;
          justify-content: space-between;

          width: 90%;
          padding: 0.75rem;
          margin: 1rem auto;

          > div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            span {
              font-size: 0.75rem;
              font-family: "Saira", sans-serif;
              color: #666;
            }

            p {
              margin: 0.25rem 0 0;
              font-size: 1rem;
              font-family: "Saira", sans-serif;
              color: #815ca0;
            }
          }

          @media screen and (max-width: 656px) {
            flex-direction: column;
            gap: 0.75rem;
            align-items: flex-start;
          }
        }
      }
    }

    .dashboard-interaction-history {
      display: flex;
      align-items: center;
      flex-direction: column;

      width: 50%;
      height: 100%;
      border: 1px solid #e5e5e5;
      border-radius: 14px;

      margin-right: 10px;
      margin-top: 4rem;
      padding-bottom: 1rem;

      span {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #572580;
      }

      table {
        width: 95%;

        border-top: 1px solid #e5e5e5;

        border-collapse: collapse;
        border-radius: 8px;

        thead {
          border-bottom: 1px solid #e5e5e5;

          th {
            font-family: $font-display;
            color: #666666;
            font-weight: 600;
            text-transform: uppercase;
            text-align: left;
            padding: 5px 5px;
          }
        }

        tbody {
          tr {
            &:nth-child(even) {
              td {
                background-color: #e5e5e5;
              }
            }
          }

          td {
            background-color: #fff;
            padding: 20px 5px;
            color: $color-gray-text;
            font-size: 14px;
            line-height: 5px;
          }
        }
      }
    }

    .dashboard-general-history {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      width: 50%;
      border: 1px solid #e5e5e5;
      border-radius: 14px;
      min-width: 555px;

      margin-top: 4rem;
      margin-left: 10px;

      span {
        margin-top: 10px;
        margin-bottom: 10px;
        color: #572580;
      }

      .dashboard-general-history-legends {
        display: flex;

        border-top: 1px solid #e5e5e5;
        padding-top: 1rem;
        width: 95%;

        .chart-legends {
          display: flex;
          flex-direction: column;
          justify-content: center;

          p {
            font-size: 12px;
            margin-top: 0px;
          }

          > div p {
            margin-bottom: 4px;
          }

          span {
            font-weight: 700;
            color: #815ca0;
          }

          > div:first-child {
            margin-bottom: 1rem;
          }

          > div:nth-child(2) {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid #e5e5e5;
            padding-bottom: 22px;

            div {
              border-radius: 3px;
              margin-right: 25px;

              @media screen and (max-width: 768px) {
                margin-right: 16px;
              }
            }

            div:nth-child(1) {
              border-top: 5px solid #ab92bf;
            }

            div:nth-child(2) {
              border-top: 5px solid #815ca0;
            }

            div:nth-child(3) {
              border-top: 5px solid #572580;
            }

            div:nth-child(4) {
              border-top: 5px solid #faf38d;
            }
          }

          .chart-legends-tickets {
            display: flex;
            margin-top: 30px;
            margin-bottom: 1rem;

            div:first-child {
              margin-right: 15px;
            }
          }
        }

        @media screen and (max-width: 656px) {
          flex-direction: column;
          align-items: center;
        }
      }

      .dashboard-customer-since {
        border-top: 2px solid #e5e5e5;
        margin: 0rem auto 0.5rem;
        padding-top: 0.5rem;
        width: 95%;

        display: flex;
        justify-content: center;

        span {
          margin: 0;
          font-size: 13px;
          font-family: "Saira", sans-serif;
          color: #572580;
        }
      }

      @media screen and (max-width: 656px) {
        min-width: 0;
      }
    }

    @media (max-width: 800px) {
      flex-direction: column;

      .dashboard-person-data {
        width: 100%;
      }

      .dashboard-interaction-history {
        width: 100%;
        margin-right: 0;
      }

      .dashboard-general-history {
        width: 100%;
        margin-left: 0;
      }

      .dashboard-labi-credits {
        width: 100%;
        border-left: none;

        > span {
          margin-left: 0px;
        }
      }
    }
  }
}
