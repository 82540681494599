.tabs-holder {
  .tabs-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    padding: 0;
    margin: 0;

    > li {
      width: 100%;
      a {
        cursor: pointer;

        background-color: #f5f5f5;
        color: #999999;

        border-bottom: 3px solid #e7e7e7;

        padding: 13px 0;
        border-radius: 15px 15px 0px 0px;
        width: 100%;

        @media screen and (max-width: 768px) {
          border-radius: 0;
        }

        display: inline-block;
        text-transform: uppercase;

        font-family: "Saira", $font-display;

        position: relative;
        text-align: center;

        transition: all 0.3s ease-in-out;

        &:before {
          content: "";

          width: 100%;
          height: 3px;

          background-color: $color-purple;

          border-radius: 5px;

          opacity: 0;

          position: absolute;
          bottom: -3px;
          left: 0;
          right: 0;

          transition: opacity 0.3s ease-in-out;
        }

        &.tabs-item-link-active {
          color: #ffffff;
          font-weight: 600;
          background-color: #ab92bf;

          &:before {
            opacity: 1;
          }
        }
      }

      &:first-child {
        a {
          padding-left: 0;
          margin-left: 0;

          &:before {
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }
  }

  .tabs-content {
    padding: 20px 0;
  }
}
