.calendar-card-hours {
  background: white;
  .header {
    background-color: #f5f5f5;
    width: 97px;
    padding-top: 12px;
    padding-bottom: 12px;

    border: 1px solid $color-semi-gray;
    text-align: center;

    p {
      padding: 0;
      margin: 0;
      font-family: $font-display;
      text-transform: uppercase;
      font-size: 14px;
      line-height: 22px;
      font-weight: 600;
      color: $color-purple;
    }
  }

  .box-hours {
    height: 80px;
    width: 97px;
    text-align: center;
    border: 1px solid $color-semi-gray;
    border-top: none;

    p {
      font-family: $font-display;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      padding-top: 5px;
    }
  }
}

.calendars-card-table {
  min-width: 675px;
  width: 100%;
  cursor: grab;
  .header {
    font-family: $font-display;
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    color: $color-purple;
    text-transform: uppercase;

    background-color: #f5f5f5;

    padding: 0;
    margin: 0;

    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 13.4px;

    border: 1px solid $color-semi-gray;

    &.no-border {
      border-left: none;
    }

    &.border-right {
      border-right: 1px solid $color-semi-gray;
    }
  }

  .calendar-container-box {
    cursor: pointer !important;
    border: 1px solid $color-border-schedule;
    border-left: none;
    border-top: none;

    min-width: 220px;
    &.no-border {
      border-top: none;
      border-left: none;
    }
  }

  .schedule-week-main {
    .week-flex {
      display: flex;
    }
  }

  .schedule-week-box-info {
    height: 80px;
    max-width: 220px;
    min-width: 220px;
    div {
      padding: 12px 0;
      padding-left: 16px;
      padding-right: 7px;
      font-family: $font-display;
      h4 {
        margin: 0;
        padding: 0;
        font-size: 12px;
        font-weight: 600;
        line-height: 20px;
      }

      p {
        margin: 0;
        padding: 0;
        font-size: 12px;
        font-weight: 500;
        line-height: 20px;
        color: #363636;
      }
    }
  }
}

.schedule-week {
  &.i-2,
  &.i-1 {
    &.schedule-before {
      &::before {
        content: "";
        width: 1460px;
      }
    }
  }

  &.i-1 {
    .calendars-card-table {
      width: 220px;
      min-width: 220px;
      cursor: pointer;
    }
  }

  &.i-2 {
    .calendars-card-table {
      width: 235px;
      min-width: 235px;
    }
  }

  &.width-1 {
    &.schedule-before {
      &::before {
        content: "";
        width: 775px;
      }
    }
  }

  &.width-2 {
    &.schedule-before {
      &::before {
        content: "";
        width: 1450px;
      }
    }
  }

  &.width-3 {
    &.schedule-before {
      &::before {
        content: "";
        width: 2125px;
      }
    }
  }

  &.width-4 {
    &.schedule-before {
      &::before {
        content: "";
        width: 2800px;
      }
    }
  }

  &.width-5 {
    &.schedule-before {
      &::before {
        content: "";
        width: 3475px;
      }
    }
  }

  &.width-6 {
    &.schedule-before {
      &::before {
        content: "";
        width: 4150px;
      }
    }
  }
}
