.data-holder-order,
.home-collect-holder-order,
.payer-data-holder-order,
.products-data-holder-order,
.patients-data-holder-order {
  display: flex;
  flex-wrap: wrap;
}

.data-holder-order {
  .list-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.payer-data-holder-order {
  .list-item {
    flex: 0 0 50%;
    max-width: 50%;
  }
}

.cart-data-holder-order {
  .data-resume-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      color: $color-purple;

      font-size: 14px;
      font-weight: 600;

      margin-bottom: 15px;

      display: inline-block;
    }

    > div {
      &:last-child {
        flex: 0 0 50%;
        max-width: 50%;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        span {
          flex: 0 0 20%;
          max-width: 20%;
          text-align: center;

          padding: 0 10px;

          &:nth-last-child(2),
          &:last-child {
            text-align: right;
          }
        }
      }
    }
  }

  .data-resume-item {
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 25px;
    padding-bottom: 15px;
  }
}

.info-data-holder-schedule {
  display: grid;
  grid-template-columns: repeat(4, 150px);
  div {
    display: flex;
    flex-direction: column;
    h6 {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      color: $color-gray-text;
    }
    span {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 24px;
      margin-top: 4px;
    }
  }
}

.patients-data-holder-order {
  .list-item {
    &:nth-child(-n + 6) {
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    &:nth-child(7) {
      flex: 0 0 50%;
      max-width: 50%;
    }

    &:nth-child(8),
    &:nth-child(9) {
      flex: 0 0 25%;
      max-width: 25%;
    }

    &:nth-child(n + 9) {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.products-data-holder-order {
  .list-item {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
}
