.page-budget {
  .input-search-cart {
    .field-holder {
      max-width: 300px;
      width: 100%;
    }

    .input {
      font-family: $font-display;
      border-radius: 25px;
      width: 100%;
    }

    @media screen and (min-width: $screen-lg) {
      .field-holder {
        max-width: 650px;
      }
    }
  }

  .box-search-client {
    font-family: $font-display;
    padding: 40px;
    background: #ffffff;
    width: 900px;
    border: 1px solid $color-buy-credits;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    label {
      color: $color-purple !important;
      margin: 8px 0;
    }

    .input-search-budget {
      .input {
        border-radius: 3px;
        width: 400px;
      }
    }

    .input-create-client {
      display: flex;
      gap: 0.5rem;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 2rem;
      .input {
        border-radius: 3px;
        width: 160px;
      }
    }
  }

  .title-page-view {
    font-family: $font-display;
    font-size: 1.5rem;
    line-height: 38px;
    margin: 0 0 1rem;
    padding: 0;

    color: $color-purple;
  }

  .box-pre-create {
    button {
      width: 300px;
    }
  }

  .id-order-budget {
    font-family: $font-display;
    color: #999;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin: 0;
    padding: 0;
  }

  .register-form-sub-title {
    font-family: $font-display;
    color: #815ca0;
    font-size: 20px;
    line-height: 28px;
    font-weight: normal;
    margin-top: 10px;
    margin-bottom: -10px;
    padding: 0;
  }

  .title-search-products-budget {
    font-family: $font-display;
    text-align: center;
    font-weight: normal;
    font-size: 1.5rem;
    line-height: 38px;
    color: $color-purple;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;

    @media screen and (min-width: $screen-md) {
      text-align: initial;
    }
  }

  .card-product-button {
    border-radius: 30px;
  }
}

.page-content-budget {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;

  .or-budget-page {
    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 20px;
      margin: 0;
      padding: 0;
      color: $color-gray-text;
    }
  }
}

.container-order-list {
  .form-holder {
    max-width: 600px;

    margin: 0 auto 0;

    &.form-holder-full {
      max-width: 100%;
    }

    .button-holder {
      margin-top: 25px;
    }
  }

  .title-service-form-page {
    font-size: 20px;
    font-weight: 400;
  }
}

.budget-header-user {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2px;

  @media screen and (min-width: $screen-md) {
    align-items: flex-end;
  }

  * {
    margin: 0;
    padding: 0;
  }

  h5 {
    font-family: $font-display;
    font-size: 15px;
    font-weight: 500;
    color: $color-gray-text;
    line-height: 22px;
  }

  p {
    font-family: $font-display;
    color: $color-purple;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
  }

  button {
    border: none;
    background: none;
    font-family: $font-display;
    color: $color-purple;
    text-transform: uppercase;
    font-weight: 600;
    text-decoration: underline;
    font-size: 13px;
    cursor: pointer;
    line-height: 19px;
  }
}

.card-of-service {
  width: calc(100% - 417px) !important;
  margin-left: 10px;
}

.cart-order-budget {
  padding: 0 20px 30px;
  display: block;
  height: 100%;

  @media screen and (min-width: $screen-md) {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }

  .card-search-information {
    flex: 0 0 60%;

    margin-right: 400px;
  }

  .cart-information {
    flex: 0 0 30%;
  }
}

.card-search-information {
  padding-top: 30px;
  .select-search-submit {
    display: block;
  }

  .input-search-cart {
    margin-top: 1rem;
    align-items: center;
    display: flex;
    padding: 0 20px 0 0;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;

    @media screen and (min-width: $screen-md) {
      justify-content: space-between;
    }
  }

  .cart-flex-products {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
  }
}

@media screen and (min-width: $screen-md) {
  .cart-flex-products {
    display: flex;
    justify-content: initial;
    .card-product {
      width: 310px;
    }
  }
}

.cart-information {
  border-top: 1px solid #c4c4c4;

  z-index: 999;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 350px;

  @media screen and (min-width: $screen-md) {
    border-top: none;
    padding: 0 20px;
    border-left: 1px solid #c4c4c4;
  }

  & > div {
    padding-top: 30px;
  }

  h5 {
    margin: 0;
    padding: 0;
    font-size: 1.5rem;
    line-height: 38px;
  }

  .cart-home-or-unit-title {
    font-family: $font-display;
    font-size: 14px;
    line-height: 22px;
    text-transform: uppercase;
    color: $color-gray-text;
    margin: 1rem 0 0;
  }

  .actions-cart-budget {
    a .button-order {
      background: $color-purple !important;
      border-radius: 30px;
      font-weight: 500;
      &:hover {
        color: $color-purple !important;
        background: white !important;
      }
    }

    padding-bottom: 1rem;
  }

  .flex-email-budget {
    margin-bottom: 48px;
    .email-validate {
      background: #ffffff !important ;
      border-radius: 161px !important;
      border: 1px solid #d8d8d8;

      .no-border {
        box-shadow: none;
        border: none !important;
      }
    }

    .field-validate-coupon {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.1rem;
    }

    .separator {
      height: 30px;
      width: 1px;
      background: #d8d8d8;
    }

    button {
      padding: 11px 15px;
      border-radius: 4px;
      color: $color-purple !important;
      font-family: $font-display;
      &:hover {
        color: $color-purple !important;
        background: none !important;
      }
    }

    input {
      flex: 1 0 0;
      border-radius: 4px;
      padding: 10px 10px 10px 30px;
    }
  }

  .time-of-delivery-title {
    font-family: $font-display;
    font-size: 14px;
    line-height: 22px;
    margin-top: 2rem;
    margin-bottom: 3rem;
  }

  .cart-details-container {
    display: flex;
    flex-direction: column;
    position: relative;

    .cart-details-container-content {
      flex: 1;
    }

    .cart-details-header {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        height: 14px;
        width: 11px;
        margin-right: 7px;
        text-decoration: underline;
        text-underline-position: under;
      }

      button {
        background: none;
        border: none;
        font-family: "Saira", sans-serif;
        font-weight: 500;
        color: #572580;
        font-size: 13px;
        cursor: pointer;
        border-bottom: 1px solid $color-purple;

        &.button-position {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &.button-cancel {
          border-bottom: unset;
          font-size: 15px;
          display: flex;
          align-items: center;

          img {
            margin-left: 15px;
          }
        }

        &.button-mb {
          margin-bottom: 16px;
        }
      }
    }

    > h4 {
      color: $color-purple;
      font-size: 22px;
      margin-top: 2rem;

      span {
        font-weight: 600;
      }
    }
  }

  .actions-cart-holder {
    display: grid;
    .container-partner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      align-items: flex-end;

      &.partners {
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }
    }

    button {
      position: relative;
      font-family: $font-display;
      background: #ffffff;
      border: 1px solid #f5f5f5;
      box-shadow: 0px 0px 0px 1px rgba(130, 136, 148, 0.16),
        0px 4px 6px -2px rgba(130, 136, 148, 0.32);
      border-radius: 8px;
      color: $color-bold-gray;
      padding: 13px 17px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      text-transform: uppercase;
      width: 100%;
      text-align: start;
      margin-bottom: 16px;
      cursor: pointer;
      span {
        text-transform: lowercase;
        margin-left: 30px;
        &.coupon {
          font-weight: bold;
          text-transform: uppercase;
        }
      }
      img {
        position: absolute;
        right: 13px;
      }
      &.active {
        background: #f1fff7;
        border: 1px solid #44b878;
      }
    }
  }
}

.container-buttons-filter {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: 0 8px;

  :last-child {
    margin-left: 10px;
  }
}
