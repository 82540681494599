.table-dashboard {
  box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
    0 8px 3px -4px rgba(130, 136, 148, 0.48);
  border-radius: 16px;
  border: none;

  overflow: hidden;

  &.scroll-purple {
    &::-webkit-scrollbar {
      background-color: transparent;
      border-radius: 32px;
      border: 1px solid transparent;
      transition: all 300ms ease-in-out;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(87, 37, 128, 0.25);
      border-radius: 32px;
    }
  }

  .header-table {
    background-color: $color-purple;

    color: #fff;

    padding: 10px 15px;

    font-size: 18px;
    font-weight: 300;
    font-family: $font-display;
  }

  table {
    width: 100%;

    border-collapse: collapse;
  }

  th {
    color: #555;
    background-color: #f5f5f5;

    font-family: $font-display;
    font-size: 16px;
    font-weight: 400;

    text-align: left;

    line-height: 24px;

    padding: 8px 15px;
  }

  td {
    padding: 10px 15px;
  }
}

.modal-overflow {
  overflow: auto;

  &.scroll-purple {
    &::-webkit-scrollbar {
      background-color: transparent;
      border-radius: 32px;
      border: 1px solid transparent;
      transition: all 300ms ease-in-out;
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(87, 37, 128, 0.25);
      border-radius: 32px;
    }
  }
}
