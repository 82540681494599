.panel-content {
	.panel-heading {
		background-color: #f5f5f5;
	}

	.-hidden {
		height: 0;
		overflow: hidden;
    }
}
