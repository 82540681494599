.sidebar-nav {
	position: fixed;
	border-radius: 0px 29px 29px 0px;
	width: 230px;
	left: 0;
	top: 0;
	bottom: 0;
	z-index: 200;
	min-height: 100%;
	margin: 0 auto;
	background-color: $color-purple;
	box-sizing: border-box;
	transition: width 300ms ease;
	padding-top: 70px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@media screen and (max-width: 1260px) {
		width: 65px;
		overflow: hidden;
		border-radius: 0;

		&.-active {

			width: 230px;
			.desktop-navbar {
				display: block;
			}
			.mobile-navbar {
				display: none;
			}

		}

		.desktop-navbar {
			display: none;
		}
	}

	@media screen and (min-width: 1260px) {
		.mobile-navbar {
			display: none;
		}
	}

}

    

	.navbar-logo {
		display: block;
		position: relative;
		margin-top: -40px;
		margin-left: 20px;
		margin-bottom: 40px;

	}

	.navbar-logo-mobile {
		display: flex;
		position: relative;
		justify-content: space-between;
		margin-bottom: 25px;
		margin-top: -40px;
		margin-left: 10px;
		margin-right: 10px;

		img {
			cursor: pointer;
		}

	}

	.navbar-logo-desktop {
		display: flex;
		position: relative;
		justify-content: space-between;
		margin-bottom: 25px;
		margin-top: -40px;
		margin-left: 15px;
		margin-right: 15px;

		img:first-child {
			width: 129px;
    		height: 28px;
			cursor: pointer;
		}

		img:last-child {
			cursor: pointer;

			transform: rotate(180deg);
			
			@media (min-width: 1260px) {
				display: none
			}
		}

		

	}

	.sidebar-header {
		display: flex;
	}
 
	.menu-right {

		margin-bottom: 30px;
		margin-left: 15px;

		@media (max-width: 1260px) {
			display: none
		}

		&._active {
			display: block;
		}

	}