.switch-holder {
  display: inline-flex;
  align-items: center;
  height: 44px;
  cursor: pointer;
  width: auto;
  border: 0;
  background-color: transparent;

  &:disabled {
    cursor: no-drop;
  }

  .switch-item {
    background-color: #cacaca;
    border-radius: 30px;
    display: inline-block;
    height: 16px;
    position: relative;
    vertical-align: middle;
    width: 48px;
    user-select: none;
    box-sizing: content-box;
    background-clip: content-box;
    box-shadow: #cacaca 0px 0px 0px 0px inset;
    transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s;

    small {
      background: #999;
      border-radius: 100%;
      height: 25px;
      width: 25px;
      position: absolute;
      top: -5px;
      left: 0px;
      transition: background-color 0.4s ease 0s, left 0.2s ease 0s;
    }
  }

  .switch-text {
    font-size: 14px;
    color: $color-gray-text;
    line-height: 19px;
    margin-left: 10px;
  }

  &._active {
    .switch-item {
      box-shadow: rgb(171, 146, 191) 0px 0px 0px 10px inset;
      transition: border 0.4s ease 0s, box-shadow 0.4s ease 0s,
        background-color 1.2s ease 0s;

      small {
        left: 23px;
        transition: background-color 0.4s ease 0s, left 0.2s ease 0s;
        background-color: $color-purple;
      }
    }
  }

  &.with-icons {
    .switch-item {
      width: 69px;
      height: 23px;

      small {
        height: 35px;
        width: 35px;

        top: -7px;

        background-color: #e6e6e6;

        text-align: center;

        line-height: 45px;

        img {
          width: 22px;
          height: auto;
        }
      }
    }

    &._active {
      .switch-item {
        box-shadow: rgb(171, 146, 191) 0px 0px 0px 20px inset;

        small {
          left: 34px;

          background-color: #e6e6e6;
        }
      }
    }
  }

  &.switch-ltf {
    flex-direction: row-reverse;

    .switch-text {
      margin-left: 0;
      margin-right: 10px;
    }
  }
}

.switch-button {
  background-color: transparent;
  border: 0;
  cursor: pointer;

  &:disabled {
    cursor: no-drop;
  }
}
