.schedule-unity {
  margin-bottom: 1rem;

  strong,
  p,
  span {
    color: #999999 !important;

    margin: 0;
    padding: 0;

    font-size: 12px !important;
    line-height: 16px !important;
  }

  strong {
    font-family: $font-text;
    font-weight: 700;
  }

  p,
  span {
    font-family: $font-text;
    font-weight: normal;
  }

  .schedule-hours-unity {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;

    p,
    strong,
    span {
      text-align: center !important;
    }

    @media screen and (min-width: 720px) {
      align-items: flex-start !important;

      p,
      strong,
      span {
        text-align: left !important;
      }
    }
  }
}

.checkout-step-content {
  margin-bottom: 48px;

  &.payment-way {
    .button-bar-holder {
      max-width: 484px;
      gap: 30px;
    }

    @media screen {
      @media (min-width: $screen-sm) {
        .button-bar-holder {
          max-width: 484px;

          &.reverse-container {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }

  &.collect {
    max-width: 490px;

    .collect-cards-wrapper {
      margin-bottom: 24px;

      .tooltip {
        div:first-child {
          min-width: 250px;
        }
      }
    }

    &.select-dates {
      max-width: 564px;

      .checkout-step-title {
        margin-bottom: 12px;
      }

      .loading {
        margin-top: 32px;
        margin-bottom: 32px;
      }

      section {
        padding: 0;
        margin-bottom: 32px;
      }
    }
  }

  .wrapper-labi-credits {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text-credit {
      margin-left: auto;
      margin-right: auto;
      max-width: 328px;
    }

    .button-component {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 14px;
      font-weight: 500;
      white-space: nowrap;
    }

    .container-info-credits {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 25px;

      img {
        width: 128px;
        height: 119px;
        margin: 6px 0 25px 0;
      }

      p {
        color: #666;
        font-family: $font-display;
        font-size: 14px;
        font-weight: 400;
        max-width: 400px;
        padding: 0 15px;
        text-align: center;

        .bold {
          font-weight: 600;
        }
      }
    }

    .button-bar-holder {
      display: flex;
      flex-direction: column-reverse;
    }

    @media screen {
      @media (min-width: $screen-sm) {
        align-items: flex-start;

        .text-credit {
          margin-left: 0;
          margin-right: 0;
          max-width: 450px;
        }

        .container-info-credits {
          display: flex;
          flex-direction: row;

          width: 100%;
          max-width: 514px;

          img {
            width: 150px;
            height: 138px;
            margin-right: 43px;
          }

          p {
            text-align: left;
          }
        }

        .button-bar-holder {
          flex-direction: row;
          width: 100%;
          max-width: 550px;
        }
      }

      @media (min-width: $screen-md) {
        .button-component {
          width: 240px;
        }
      }
    }
  }

  &.budget-identification {
    max-width: 484px;

    .button-bar-holder {
      .links {
        margin-bottom: 0;
      }
    }
  }

  .form-wrapper-make-order {
    // max-width: 484px;
    padding: 16px 15px !important;

    .form-wrapper {
      margin-bottom: 48px !important;
    }

    .form-wrapper,
    .button-bar-holder {
      margin-left: -15px !important;
      margin-right: -15px !important;
    }
  }

  &.identification {
    .form-wrapper {
      margin: 32px 0;
      padding: 16px 0;

      .checkbox-form {
        display: flex;
        margin-bottom: 10px;

        .checkbox-form-label {
          cursor: pointer;
          margin-left: 10px;
          font-weight: 600;
        }
      }

      .form-title {
        font-size: 16px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 18px;
        text-transform: uppercase;
        padding: 12px 8px 0;
      }

      .head-name-label {
        color: $color-gray-text;
        font-family: $font-text;
        font-weight: 600;
        font-size: 14px;
        line-height: 22px;
        margin-top: -12px;
        margin-left: 10px;
        margin-bottom: 0;
      }
    }

    .save-profile-patient-checkbox {
      display: flex;
      gap: 12px;
      margin-top: 28px;

      span {
        text-decoration: underline;
        color: #572580;
        cursor: pointer;
      }
    }

    .save-profile-patient {
      display: flex;
      align-items: center;

      margin-left: 16px;
      margin-bottom: 32px;

      cursor: pointer;
      gap: 7px;

      .field-holder {
        margin-bottom: 0px;
      }

      &.active-save-profile {
        .tooltip {
          color: #572580;
        }
      }

      .tooltip {
        div {
          font-size: 11px;
          width: 278px;
        }
      }

      @media screen and (min-width: $screen-sm) {
        margin-left: 8px;
      }
    }

    .button-bar-holder {
      margin-top: 16px;
    }
  }

  &.payment-plots {
    .button-bar-holder {
      display: flex;
      align-items: center;
      flex-direction: column-reverse;
      max-width: 484px;
    }

    .paid-order-wrapper {
      padding-left: 30px;
      padding-right: 30px;
      text-align: center;
      max-width: 484px;
      margin: 0 auto;

      .paid-order-image {
        width: 100%;
        max-width: 128px;
        margin-bottom: 24px;
      }

      p {
        color: #666666;
        font-family: $font-display;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        margin-bottom: 48px;
      }

      .button-bar-holder {
        button {
          padding: 13px 36px;
        }

        .links {
          margin-bottom: 0;
        }
      }
    }
  }

  &.checkout-step-voucher {
    p.separate-base {
      text-align: center;
    }

    .checkout-step-title {
      img {
        max-width: 35px;
        margin: 0 auto 12px;
      }
    }

    .card-schedule-voucher {
      display: flex;
      gap: 25px;
      justify-content: center;

      .card-date {
        margin: 0;
      }
    }

    .schedule-wrapper {
      background-color: $color-ltgray;
      border-radius: 24px;
      box-sizing: border-box;
      margin: 0 auto 32px;
      padding: 24px;
      max-width: 582px;

      @media print {
        display: none !important;
        max-width: none !important;
      }

      .schedule-description {
        text-align: center;

        h3 {
          color: $color-purple;
          font-family: $font-display;
          font-size: 16px;
          letter-spacing: 0;
          margin-bottom: 15px;
          line-height: 16px;
        }

        p {
          color: $color-lgray-text;
          font-size: 12px;
          letter-spacing: 0;
          line-height: 16px;
        }
      }
    }
  }

  .checkout-step-title {
    font-size: 24px;
    line-height: 32px;
    margin: 0 32px 32px;
    text-align: center;
    color: $color-purple;
    display: flex;
    flex-direction: column;

    &.inline-text {
      display: inline-block;
    }

    img {
      margin-bottom: 12px;
    }

    &.select-regions {
      font-size: 20px;
    }
  }

  .voucher-content-section {
    padding-left: 0;

    >h4 {
      text-align: left;
    }
  }

  .actions-holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .button-holder {
    text-align: center;
  }

  .form-wrapper {
    border-radius: 24px;
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 4px 6px -2px rgba(130, 136, 148, 0.32);
    padding: 32px 0;
  }

  @media screen {
    @media (min-width: $screen-m) {
      &.payment-plots {
        .paid-order-wrapper {
          .paid-order-image {
            max-width: 256px;
          }
        }
      }
    }

    @media (min-width: $screen-sm) {
      &.checkout-step-voucher {
        p.separate-base {
          text-align: left;
        }

        .checkout-step-title {
          img {
            max-width: 28px;
            margin: 0 12px 12px 0;
          }
        }
      }

      .checkout-step-title {
        font-size: 24px;
        line-height: 32px;
        text-align: left;
        flex-direction: row;
        margin-left: 0;

        img {
          margin-right: 16px;
          margin-bottom: 0;
        }
      }

      .voucher-ticket {
        margin: 0 auto 48px -2px;
      }

      .button-holder {
        text-align: left;

        &.align-right {
          text-align: right;
        }
      }

      &.identification {

        .form-wrapper,
        .button-bar-holder {
          max-width: 484px;
        }

        .form-wrapper {
          padding: 16px 15px;
        }

        .button-bar-holder {
          display: flex;
          flex-direction: row-reverse;
        }
      }

      &.payment-plots {
        .button-bar-holder {
          align-items: center;
          justify-content: space-between;
          flex-direction: row;
        }

        .paid-order-wrapper {
          margin: 0;
          padding: 0;
          text-align: left;
          max-width: 500px;

          p {
            text-align: inherit;
            font-size: 16px;
          }
        }
      }

      .button-bar-holder {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .button-holder {
          margin-bottom: 0;
        }

        .links {
          .link {
            display: inline-block;
            margin-bottom: 0;
            margin-right: 28px;

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }
    }

    &.select-dates {
      max-width: 564px;
    }
  }

  @media (min-width: $screen-m) {
    &.checkout-step-voucher {
      .schedule-wrapper {
        display: flex;
        align-items: center;

        .schedule-description {
          text-align: left;
          flex: 1;

          p {
            margin: 0;
          }
        }
      }

      .card-schedule-voucher {
        margin-left: 30px;
      }
    }
  }

  @media (min-width: $screen-sm) {
    &.checkout-step-voucher {
      .box-pdf-button {
        margin-bottom: 22px;
      }

      .content-pack-vaccines {
        grid-template-columns: none !important;

        .schedule-description {
          text-align: center !important;
        }
      }

      .schedule-wrapper {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 15px;
        max-width: 550px;
        margin: 0 0 32px;

        .schedule-description {
          width: auto;
          flex: none;
        }

        .card-schedule-voucher {
          display: flex;
          gap: 10px;
          justify-content: center;
          margin-left: 0;

          .card-date {
            width: auto !important;
            padding: 29px 7px 24px;
          }
        }

        &.normal-width {
          max-width: 500px;
        }

        &.attendment-width {
          max-width: 514px;
          margin-left: -15px;
        }
      }
    }

    &.select-dates {
      .wrapper-loading {
        margin-left: -75px;
      }
    }
  }

  @media (min-width: $screen-md) {
    &.select-dates {
      .wrapper-loading {
        margin-left: 0;
        max-width: 485px;
      }
    }
  }

  @media (min-width: 1185px) {
    &.select-dates {
      .wrapper-loading {
        max-width: 552px;
      }
    }
  }

  @media (min-width: $screen-lg) {
    &.select-dates {
      section {
        &>.container {
          padding-left: 0;
          padding-right: 0;
        }
      }

      .wrapper-loading {
        max-width: 552px;
        margin-left: -30px;
      }
    }
  }
}

.save-profile-patient-border {
  width: 100%;
  border-top: 1px dashed rgba(153, 153, 153, 0.5);
  margin-bottom: 15px;
}