.modal {
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(87, 37, 128, 0.7);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 500ms ease-in-out;
  width: 100vw;
  height: 100vh;

  &.after-open {
    opacity: 1;
  }

  &.before-close {
    opacity: 0;
  }
}

.modal-heading {
  position: relative;

  img {
    margin-bottom: 22px;
  }

  .close-button {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height: 20px;
    position: absolute;
    top: -28px;
    right: -30px;
  }
}

.modal-body {
  border-radius: 0 0 12px 12px;
}
.modal-button {
  display: flex;
  justify-content: flex-end;
}

.modal-content {
  &.modal-salesforce {
    p {
      font-family: $font-display;
      font-size: 13px;
      line-height: 20px;
      color: #666666;
    }
    .page-header {
      display: block !important;
      text-align: center;
      margin-bottom: 1rem !important;

      h2 {
        color: $color-purple !important;
        font-weight: 500;
      }
    }
  }

  &.modal-rnds {
    max-width: 600px;
  }

  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
    0 10px 16px -4px rgba(130, 136, 148, 0.48);
  padding: 30px 40px !important;
  max-width: 991px;
  width: auto;
  outline: none;
  margin: 15% auto;
  max-height: calc(100vh - 5rem);
  overflow-y: auto;
  position: relative;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.modal-product {
    width: 1200px;
  }

  &.modal-content-information {
    padding: 48px !important;

    min-width: initial;
    max-width: 547px;
    width: 100%;
    border-radius: 24px;
  }

  &.modal-content-responsible {
    padding: 48px !important;

    min-width: initial;
    max-width: 760px;
    width: 100%;
    border-radius: 24px;
  }

  &.fix-width-modal {
    max-width: 1005px;
    width: 100%;
  }
  &.modal-fixed-header {
    padding: 0 40px 30px !important;

    .loading-persons {
      margin-top: 30px;

      display: inline-block;
    }

    .loading-centered {
      display: flex;

      margin-top: 0;
    }

    .modal-wrapper-header {
      position: sticky;
      -webkit-position: sticky;
      top: 0;
      right: 0;
      left: 0;

      width: 100%;

      background-color: #fff;

      .page-header {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 30px 40px 15px;
      }

      &.margin-bottom-20 {
        margin-bottom: 20px;
      }

      &.h5-center {
        max-width: 500px;
        text-align: center;
      }
    }

    @media screen and (max-width: 768px) {
      padding: 0 10px 6px !important;
      margin: 0 8px;
    }
  }

  &.modal-reschedule-header {
    padding: 0 90px 30px !important;
  }

  &.modal-covenant {
    width: 70%;
    max-width: none !important;
  }

  .modal-wrapper-header {
    .page-header {
      padding: 0 40px 15px;
      margin: 0 -40px;

      box-shadow: 0 5px 5px -5px rgba(51, 51, 51, 0.3);

      display: flex;
      align-items: center;
      justify-content: space-between;
      h2 {
        margin-bottom: 0;
      }
    }
  }

  .headerModal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 24px;

    .checkout-step-title {
      color: $color-purple;
    }

    .btn-close {
      padding: 10px;
      line-height: 10px;
      font-size: 40px;
      cursor: pointer;
      transform: rotate(45deg);
      color: #572580;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }

  .voucher-ticket {
    background-size: contain;
    width: 100%;
    margin: 0;

    .info-voucher {
      width: 205px;
    }
  }

  &.modal-content-open {
    border-color: transparent;
  }

  ._voucher {
    h4 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .description-list {
    height: 100%;
    overflow-y: auto;
    padding: 15px 15px 0;

    .modal-header-navigation {
      .main-tabs {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 15px;
        width: 100%;

        > div {
          cursor: pointer;
          box-sizing: border-box;
        }

        .navigation-items {
          box-sizing: border-box;
          color: $color-purple;
          width: 50%;
          text-align: center;
          padding: 7px;

          transition: all 0.3s ease-in-out;

          &:not(.active) {
            border: 1px solid $color-purple;
          }

          &.active {
            cursor: initial;
            border: 1px solid transparent;
            background-color: $color-purple;
            color: $color-yellow;
          }

          &.left-tab {
            border-radius: 8px 0 0 8px;
          }

          &.right-tab {
            border-radius: 0 8px 8px 0;
          }

          &:only-child {
            border-radius: 8px;

            width: 100%;
          }
        }
      }

      .sub-tabs {
        display: flex;

        box-sizing: border-box;

        margin: 0 -7px 15px;

        .subtab-holder {
          padding: 0 7px;

          flex: 1;
        }

        .tabs-items {
          cursor: pointer;

          color: $color-purple;

          padding: 5px;

          text-align: center;

          border: 1px solid $color-purple;
          border-radius: 8px;

          transition: all 0.3s ease-in-out;

          &:hover {
            background-color: $color-purple;

            color: #fff;
          }
        }
      }
    }

    .panel-content {
      .panel-body {
        display: flex;
        flex-wrap: wrap;

        .multiple-list {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          margin-block-start: 0;
          margin-block-end: 0;

          &:not(:first-child) {
            border-top: 1px solid #ccc;
            padding-top: 30px;
          }

          .list-item {
            width: 33.333%;
          }
        }

        .list-item {
          margin-bottom: 15px;
          width: 33.333%;

          dt {
            font-weight: 800;
            margin-bottom: 2px;
          }

          dd {
            margin-left: 0;
            padding-bottom: 5px;
            margin-bottom: 10px;
          }
        }
      }
    }
  }

  @media screen {
    @media (min-width: $screen-sm) {
      padding: 48px;
      box-sizing: border-box;
      height: auto;

      min-width: 600px;

      .voucher-ticket {
        width: 100%;
        height: 230px;
        background-size: contain;
        display: flex;
        align-items: center;
        padding: 24px 65px 24px 45px;
        margin: 0 auto 24px 0;

        .qr-code-holder {
          margin-right: 50px;
        }

        .info-voucher {
          width: 100%;
        }
      }
    }

    @media (max-width: $screen-sm) {
      top: 0;
      max-height: calc(100vh - 10rem);

      .button-holder {
        margin: 0;

        .button._horizontal-xlg {
          margin: 0 auto;
          display: table;
        }
      }

      #image-voucher {
        > p {
          padding: 0 24px;
        }
      }

      .links {
        display: none;
      }

      h4 {
        text-align: center;
      }

      .voucher-home-collection {
        display: none;
      }
    }
  }
}

.modal-content-open {
  overflow: hidden;
  border-color: transparent;
}

@media screen and (max-width: 1100px) {
  .modal-content {
    max-width: 767px;

    .description-list {
      .list-item {
        width: 50% !important;
      }
    }
  }
}

@media screen and (max-width: 810px) {
  .modal-content {
    max-width: 592px;
  }
}

@media screen and (max-width: 767px) {
  .modal-content {
    max-width: 492px !important;
    padding: 15px !important;

    .modal-wrapper-header {
      padding: 15px;
    }

    .description-list {
      .list-item {
        width: 50% !important;
      }
    }
  }
}

@media screen and (max-width: 592px) {
  .modal-content {
    .description-list {
      .list-item {
        width: 100% !important;
      }
    }
  }
}

body.react-confirm-alert-body-element {
  overflow: hidden;
}

.react-confirm-alert-blur {
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}

.react-confirm-alert-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  background-color: rgba(0, 0, 0, 0.8);
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: center;
  -ms-align-items: center;
  align-items: center;
  opacity: 0;
  -webkit-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -moz-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  -o-animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
  animation: react-confirm-alert-fadeIn 0.5s 0.2s forwards;
}

.react-confirm-alert-body {
  font-family: Arial, Helvetica, sans-serif;
  width: 400px;
  padding: 30px;
  text-align: left;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 20px 75px rgba(0, 0, 0, 0.13);
  color: #666;
}

.react-confirm-alert-svg {
  position: absolute;
  top: 0;
  left: 0;
}

.react-confirm-alert-body > h1 {
  margin-top: 0;
}

.react-confirm-alert-body > h3 {
  margin: 0;
  font-size: 16px;
}

.react-confirm-alert-button-group {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

.react-confirm-alert-button-group > button {
  outline: none;
  background: #333;
  border: none;
  display: inline-block;
  padding: 6px 18px;
  color: #eee;
  margin-right: 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}

@-webkit-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-o-keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes react-confirm-alert-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.react-confirm-alert .custom-ui {
  padding: 30px;
  padding-bottom: 25px;
  background-color: #f0f0f0;
  border-radius: 8px;
  max-width: 500px;

  h5 {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.3);
  }

  p {
    color: #222;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .wrapper-buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    width: 100%;

    button {
      margin: 0 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.modal-content-cart {
  padding: 0 !important;

  .modal-wrapper-header {
    padding: 0;

    .page-header {
      padding: 20px 30px;
      margin: 0;

      border-bottom: 1px solid rgba(51, 51, 51, 0.3);
      box-shadow: none;

      display: flex;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin-bottom: 0;
      }
    }

    .marginTopTitle {
      margin-top: 48px;
    }
  }

  .modal-wrapper-body {
    padding: 20px 30px;
  }
  .modal-button {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .modal-wrapper-action {
    padding: 10px 30px;

    display: flex;
    align-items: center;

    justify-content: flex-end;

    border-top: 1px solid rgba(51, 51, 51, 0.3);

    > .link {
      margin-right: 15px;
    }
  }
}

.modal-flex-items-checkbox {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.modal-labi-credits {
  .page-content-list {
    table {
      border-radius: 3px;

      tbody {
        td {
          font-weight: 500;
          color: $color-purple;
          font-family: $font-display;
        }
      }
    }
  }

  .modal-details-labi-credits {
    position: relative;
    .modal-buttons-position {
      background: white;
      position: absolute;
      display: flex;
      gap: 0.5rem;
      justify-content: center;
      align-items: center;
      bottom: 0;
      width: 93%;
    }

    .modal-details-position {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      .modal-schedule-buttons {
        display: flex;
        gap: 1rem;
        justify-content: center;
        padding: 15px 0px;
      }
    }
  }
}

.modal-wrapper-header-annex {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 48px;

  h5 {
    color: #572580;
    margin-bottom: 32px;
  }
  p {
    color: #333;
  }
}

.container-modal-information {
  .box-image-modal-information {
    text-align: center;
  }

  h2,
  p {
    margin: 0;
    padding: 0;
  }

  h2 {
    font-family: $font-display;
    font-style: normal;
    font-weight: normal;
    font-size: 1.75rem;
    line-height: 2.5rem;

    text-align: center;

    color: $color-purple;

    margin: 1.563rem 0;
  }

  p {
    font-family: $font-text;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    text-align: center;

    color: #979797;
    margin: 1.563rem 0;
    margin-bottom: 2rem;
  }

  .flex-buttons-modal-information {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .container-button-information {
    text-align: center;

    button {
      border-radius: 30px;
      padding: 13px 36px;
    }
  }
}

.container-modal-responsible {
  .box-image-modal-information {
    text-align: center;
  }

  h2,
  p {
    margin: 0;
    padding: 0;
  }

  h2 {
    font-family: $font-display;
    font-style: normal;
    font-weight: 400;
    font-size: 1.75rem;
    line-height: 2.5rem;

    text-align: center;

    color: $color-purple;

    margin: 1.563rem 0;
  }

  p {
    font-family: $font-text;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;

    text-align: center;

    color: #333333;
    margin: 1.563rem 0;
    margin-bottom: 2rem;
  }

  strong {
    font-size: 1rem;
  }

  .flex-buttons-modal-information {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  .container-button-information {
    text-align: center;

    button {
      border-radius: 30px;
      padding: 13px 36px;

      &:disabled {
        background-color: #d5c8df;
        color: #fff;
      }
    }
  }
}

.modal-coupom-cart {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.upload-modal {
  width: 500px;

  > div {
    padding: 48px;
    border-radius: 24px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #fff;

    > img {
      width: 69px;
      height: 69px;
      margin: auto;
    }

    h1 {
      display: block;
      font-size: 28px;
      color: #572580;
    }

    .select-unit {
      width: 300px;
      margin: auto;

      select {
        width: 100% !important;
      }
    }

    > button {
      margin: auto;
      margin-top: 32px;
      width: 250px;
      height: 45px;
      border-radius: 24px;
    }
  }
}
