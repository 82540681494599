.flex-container-responsible {
  p {
    margin: 0;
    padding: 0;
  }

  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;

  .responsible-information {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    .title-responsible {
      font-family: $font-display;
      font-style: normal;
      font-weight: 600;
      font-size: 0.875rem;
      line-height: 1.375rem;

      color: $color-bold-gray;
    }

    strong,
    p {
      font-family: $font-display;
      font-style: normal;
    }

    p {
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 1.188rem;
      /* identical to box height */

      color: #999999;
    }
  }
}

.container-checkbox-responsible {
    margin: 1rem 0 0;
    label {
      min-height: initial;
    }
    .checkbox-holder {
      height: initial;
      min-height: initial;
    }
  }