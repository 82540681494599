.cart-itens-holder {
  margin-bottom: 16px;

  padding-bottom: 16px;

  border-bottom: 2px solid #e5e5e5;

  &.new-layout-cart-itens-holder {
    border-bottom: 1px solid #e5e5e5;
  }
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  margin-bottom: 10px;

  .label {
    font-size: 13px;
    width: 100%;
    max-width: 150px;

    @media (max-width: 1400px) {
      max-width: 130px;
    }

    @media (max-width: 1300px) {
      max-width: 100px;
    }
  }

  .courtesy-tag {
    background: #f1fff7;
    border: 1px solid #44b878;
    padding: 0 10px;
    border-radius: 5px;
    display: block;
  }

  .courtesy {
    display: block;

    font-size: 10px;

    button {
      cursor: pointer;

      padding: 0;

      text-decoration: underline;

      background-color: transparent;

      border: none;

      outline: none;

      &.remove {
        color: $color-danger;

        margin-left: 10px;
      }
    }

    > span {
      font-size: 14px;
    }
  }

  .courtesy-display {
    > span {
      font-size: 11px;
    }
  }

  .price {
    white-space: nowrap;

    font-size: 18px;

    color: $color-bold-gray;

    font-family: $font-display;

    text-align: right;

    max-width: 102px;
  }

  > .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (min-width: 1600px) {
      max-width: 40%;
    }
  }

  .amount {
    display: flex;
    align-items: center;

    input {
      padding: 2px 5px;

      font-size: 13px;

      &:read-only,
      &-moz-read-only {
        border: none;
        box-shadow: none;
        background-color: transparent;
        font-size: 14px;
      }

      &:hover,
      &:focus {
        border: none !important;
        box-shadow: none;
        background-color: transparent;
      }
    }

    button {
      outline: none;

      cursor: pointer;

      background-color: transparent;

      color: $color-purple;

      font-size: 20px;

      border: none;
    }
  }

  p {
    margin-bottom: 0;
  }

  .coupon-code {
    text-transform: uppercase;

    font-size: 16px;
  }

  &.cart-item-entry {
    > .action {
      flex: 0 0 50%;
      max-width: 50%;

      .amount {
        justify-content: center;
      }

      .amount,
      .price,
      .unit {
        text-align: right;

        padding: 0 10px;

        flex: 0 0 20%;
        max-width: 20%;

        margin-right: 0;
      }
    }
  }

  &.resume {
    .label {
      font-size: 15px;
    }
  }

  &.cart-item-editable {
    .action {
      flex: 0 0 180px;
      max-width: 180px;
    }

    .button-actions {
      margin-left: 8px;

      display: flex;
      align-items: center;

      > .button {
        &:nth-child(2) {
          margin-left: 5px;
        }

        &:only-child {
          margin: 0;
        }
      }
    }

    input {
      padding: 4px 12px;

      transition: all 0.3s ease-in-out;

      font-size: 18px;

      text-align: right;

      &:read-only {
        box-shadow: none;

        white-space: nowrap;
        color: #572580;
        font-family: "Saira", sans-serif;
        flex: 0 0 102px;
        text-align: right;
        max-width: 102px;
      }
    }
  }
}

.cart-item-order {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  // margin-bottom: 10px;

  .label {
    font-size: 15px;
    width: 100%;
    max-width: 150px;

    @media (max-width: 1400px) {
      max-width: 130px;
    }

    @media (max-width: 1300px) {
      max-width: 100px;
    }
  }

  /* Ajustar a quantidade para centralizar */
  .value {
    margin-bottom: 0;
    font-size: 14px; /* Aumenta o tamanho da fonte se necessário */
    text-align: center; /* Alinhar o texto ao centro */
    flex: 0 0 50px; /* Garante um tamanho fixo e proporcional ao restante */
  }

  .price {
    white-space: nowrap;
    font-size: 18px;
    color: $color-bold-gray;
    font-family: $font-display;
    text-align: right;
    max-width: 120px;
    width: 120px;
  }
}

.new-layout-cart-itens-holder {
  margin-top: 1.9rem;
  .courtesy {
    display: none;
  }

  .action {
    .amount {
      display: flex;
      align-items: center;

      input,
      button {
        font-family: $font-display;
        font-size: 15px;
      }

      button {
        padding: 5px 10px;
      }

      .input {
        padding: 0;
        background-color: transparent;
        border-radius: 4px;
        font-size: 15px;
        color: $color-gray-text;
        box-shadow: none;
        width: 100%;
        max-width: 50px;
      }

      .quantity_button {
        color: $color-purple;
        padding: 6px 10px;
        cursor: pointer;
      }
    }

    .price {
      font-family: $font-display;
      font-size: 15px;
      line-height: 22px;
    }

    .check-out-price {
      color: $color-bold-gray;
    }
  }

  .cart-item {
    .label {
      font-family: $font-display;
      color: $color-gray-text;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

.total-cart-holder-new-layout {
  .cart-item {
    p {
      font-family: $font-display;
    }

    .label {
      font-size: 14px;
      color: $color-gray-text;
      line-height: 22px;
    }

    .lineThrough {
      text-decoration: line-through;
    }

    .price {
      font-size: 15px;
    }
  }
}
