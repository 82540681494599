.service-form-page {
  padding: 30px 0;
  overflow: auto;

  .container {
    max-width: 1300px;

    .sub-container {
      max-height: 550px;
      overflow-y: auto;
      border: 1px solid #c4c4c4;
      border-radius: 25px;
      padding: 2rem 1rem 0rem 1rem;

      &:last-child {
        margin-top: 4rem;
      }
    }
  }

  .form-holder {
    max-width: 800px;

    margin: 0 auto 0;

    &.form-holder-full {
      max-width: 100%;
    }

    .button-holder {
      margin-top: 25px;
    }
  }

  .box-email-or-phone {
    position: relative;
    margin: 0 0.5rem;
    p {
      margin: 25px 0 0;
      padding: 0;

      font-family: $font-display;
      font-style: normal;
      font-weight: 500;
      font-size: 0.813rem;
    }

    &::before,
    &::after {
      top: 78%;
      right: 20px;
      position: absolute;
      content: "";
      width: 25px;
      height: 1px;
      background-color: #c4c4c4;
    }

    &::after {
      left: 20px;
    }

    @media screen and (min-width: $screen-md) {
      &::before,
      &::after {
        top: 10px;
        right: 50%;
        position: absolute;
        content: "";
        width: 1px;
        height: 12px;
        background-color: #c4c4c4;
      }
  
      &::after {
        left: initial;
        top: initial;
        bottom: -15px;
      }
    }
  }

  .title-service-form-page {
    font-family: "Saira", sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0em;
    color: #572580;
    margin-bottom: 0.75rem !important;

    &.pre-register {
      text-align: center;

      @media (max-width: 1280px) {
        margin-left: 0;
        text-align: center;
      }
    }
  }

  div {
    &.pre-register {
      margin-bottom: 2rem;
    }
  }

  &.service-form-resume {
    .container {
      max-width: 800px;
    }

    @media (min-width: $screen-md) {
      height: #{calc(100vh - 100px)};
    }
  }

  @media screen {
    @media (min-width: $screen-sm) {
      .container {
        .row {
          > .sm-12 {
            &:last-child {
              margin-top: 16px;
            }

            &:only-child {
              margin-top: 0;
            }
          }
        }
      }
    }

    @media (min-width: $screen-md) {
      height: #{calc(100vh - 200px)};
      box-sizing: border-box;

      padding-bottom: 0;

      .container,
      .row,
      .sm-12 {
        height: 100%;
      }

      .title-service-form-page {
        &:last-child {
          text-align: center;
        }
      }

      .container {
        .row {
          > .sm-12 {
            &:last-child {
              margin-top: 16px;
            }

            &:only-child {
              margin-top: 0;
            }
          }
        }
      }

      .search-products-holder {
        max-height: 100%;

        overflow: auto;

        padding-right: 16px;

        max-width: 60%;
        flex: 0 0 60%;
      }

      .cart-side-holder {
        max-width: 40%;
        flex: 0 0 40%;
      }

      &.service-form-resume {
        .row {
          height: auto;
        }
      }
    }
  }
}

.container-search-pacient {
  .box-search-flex-patient {
    display: flex;
    gap: 1rem;

    .field-holder {
      width: 100%;
      margin: 0;
      padding: 0;
    }

    .pre-register {
      margin-top: 0;
    }
  }
}