.modal-close-badge-flex{
    display: flex;
    flex-direction: column;
    
    
    margin-top: 3.7rem;
   
    
    
    .cart-flex-buttons{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: .6rem;
        button{

            width: 100% ;
            min-width: 50px;  
            max-width: 120px; 

            font-size: .8rem;
            padding: 6px 3px;
            

            display: flex;
            align-items: center;
            justify-content: center;
            
            .badge-text-button{
                margin-left: 20px;
            }

            .badge-text-margin{
                margin-left: 15px;
            }
        
            &:hover{
                background: white;
                color: $color-purple ;
            }

        }
    }

    .closeButton{
        color: #c23f3b !important;
        border-color: #c23f3b !important;
        background: none !important;

        &:hover{
            background: #cf4844 !important;
            color: white !important;
        }
    }

}